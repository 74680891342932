const colors = {
  primary: '#258EA6', // Dark Turquoise
  secondary: '#549F93', // Slightly lighter Turquoise
  accent: '#153243', // Very dark Turquoise
  background: '#FFFFFF', // White
  textPrimary: '#153243', // Very dark Turquoise
  textSecondary: '#549F93', // Slightly lighter Turquoise
  border: '#DCDCDC', // Gainsboro
  hover: '#4169E1', // Royal Blue
  active: '#FF4500', // Orange Red
  error: '#FF0000', // Red
  success: '#32CD32', // Lime Green
  warning: '#FFD700', // Gold
  highlight: '#FFFF00', // Yellow
  info: '#17A2B8', // Cyan
  muted: '#6C757D', // Grey
  lightBackground: '#EAEAEA', // Light Grey
  darkBackground: '#343A40', // Dark Grey
};

const typography = {
  fontFamily: 'Helvetica, Arial, sans-serif',
  modernFontFamily: 'Roboto, sans-serif', // Modern Font
  fontSize: {
    h1: '32px',
    h2: '28px',
    h3: '24px',
    body: '16px',
    caption: '14px',
    small: '12px',
  },
  fontWeight: {
    normal: 400,
    bold: 700,
  },
  lineHeight: {
    normal: 1.5,
    heading: 1.25,
  },
  letterSpacing: {
    normal: '0.5px',
    wide: '1px',
  },
};

const spacing = {
  margin: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
  padding: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
  gutter: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
};

const buttons = {
  button: {
    backgroundColor: colors.primary,
    color: colors.background,
    padding: spacing.padding.medium,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    width: '100%',
    border: 'none',
    cursor: 'pointer',
    boxShadow: `0 2px 4px rgba(0, 0, 0, 0.1)`,
    transition: 'background-color 0.3s ease',
    fontSize: typography.fontSize.body, // Add this line to set the text size
    '&:hover': {
      backgroundColor: colors.hover,
    },
  },
};

const forms = {
  input: {
    margin: `${spacing.margin.small} 0`,
    padding: spacing.padding.small, // Ensure padding is applied to inputs
  },
};

const containers = {
  loginContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: colors.background, // Changed from lightBackground to background to remove grey
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.background,
    padding: '1rem', // Reduced padding to decrease white space
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'center',
  },
  linkContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    width: '250px', // Increased the size of the logo
    height: 'auto',
    objectFit: 'contain',
    marginBottom: spacing.margin.small, // Reduced margin-bottom to decrease white space
  },
  primaryHeader: {
    color: colors.accent,
    fontFamily: typography.modernFontFamily,
    marginBottom: spacing.margin.small, // Added margin-bottom to decrease white space
  },
  link: {
    color: colors.hover,
    fontSize: '14px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      color: colors.hover,
    },
  },
  sidebarBackground: '#153243',
  sidebarLogo: {
    width: '150px',
    margin: '16px auto',
    display: 'block',
  },
  sidebarLink: {
    color: colors.sidebarText,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#258EA6',
      color: '#258EA6',
    },
  },
  sidebarIcon: {
    color: 'inherit',
  },
};

export const styles = {
  ...colors,
  ...typography,
  ...spacing,
  ...buttons,
  ...forms,
  ...containers,
};
