// src/screens/TemplateManagement.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import TemplatePreviewModal from '../components/templateBuilder/TemplatePreviewModal';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

// Import icons directly
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import PreviewIcon from '@mui/icons-material/Visibility';

const TemplateManagement = () => {
  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth();
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('description');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [templateToPreview, setTemplateToPreview] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');
  const hasAddPermission = permissions.includes('add_template-management');
  const hasEditPermission = permissions.includes('edit_template-management');
  const hasDeletePermission = permissions.includes('delete_template-management');

  const fetchTemplates = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careplantemplates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTemplates(response.data);
      setFilteredTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch templates.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    if (!authLoading && hasViewPermission) {
      fetchTemplates();
    } else if (!authLoading && !hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/home');
    }
  }, [authLoading, hasViewPermission, fetchTemplates, navigate]);

  useEffect(() => {
    applyFilters();
  }, [searchTerm, statusFilter, typeFilter, templates]);

  const applyFilters = () => {
    let filtered = templates;

    if (searchTerm) {
      filtered = filtered.filter((template) =>
        template.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (statusFilter) {
      filtered = filtered.filter((template) => {
        if (statusFilter === 'Draft') return template.isDraft;
        if (statusFilter === 'Active') return !template.isDraft && !template.isArchive;
        if (statusFilter === 'Archived') return template.isArchive && !template.isDraft;
        return true;
      });
    }

    if (typeFilter) {
      filtered = filtered.filter((template) => {
        if (typeFilter === 'Static') return template.isStatic;
        if (typeFilter === 'Dynamic') return !template.isStatic;
        return true;
      });
    }

    setFilteredTemplates(filtered);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
      const orderComp = comparator(a[0], b[0]);
      if (orderComp !== 0) return orderComp;
      return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (!a[orderBy] || !b[orderBy]) return 0;
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewDetails = (template) => {
    setSelectedTemplateDetails(template);
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    setSelectedTemplateDetails(null);
  };

  const handleCreateNewTemplate = () => {
    if (!hasAddPermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to create templates.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    localStorage.removeItem('carePlanDraft'); // Clear the draft
    navigate('/care-plan-template-builder');
  };

  const handleEditTemplate = (template, action) => {
    if (!hasEditPermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to edit templates.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    localStorage.setItem('carePlanDraft', JSON.stringify({ ...template, action })); // Save the template to local storage
    navigate('/care-plan-template-builder'); // Navigate to the builder
  };

  const handleArchiveTemplate = async (templateID) => {
    if (!hasDeletePermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to archive templates.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    try {
      const token = localStorage.getItem('userToken');
      await axios.put(
        `${API_BASE_URL}/api/careplantemplates/archive/${templateID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      fetchTemplates();
      Swal.fire({
        title: 'Success',
        text: 'Template archived successfully.',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      console.error('Error archiving template:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to archive template.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  const handlePreviewTemplate = (template) => {
    setTemplateToPreview(template);
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
    setTemplateToPreview(null);
  };

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <>
      <NavigationBar />
      <Box display="flex">
        <Sidebar />
        <Box flexGrow={1}>
          <Box sx={{ padding: '24px', marginTop: '64px' }}>
            <Typography component="h1" variant="h5" sx={{ marginBottom: '24px' }}>
              Template Management
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
              <TextField
                label="Search Templates"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="Search Templates Button">
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateNewTemplate}
                sx={{ marginLeft: '16px' }}
                disabled={!hasAddPermission}
              >
                Create New Template
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: '16px' }}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={statusFilter}
                  label="Status"
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="Draft">Draft</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Archived">Archived</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel>Type</InputLabel>
                <Select
                  value={typeFilter}
                  label="Type"
                  onChange={(e) => setTypeFilter(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="Static">Static</MenuItem>
                  <MenuItem value="Dynamic">Dynamic</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="template table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'description'}
                        direction={orderBy === 'description' ? order : 'asc'}
                        onClick={() => handleRequestSort('description')}
                      >
                        Description
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === '_id'}
                        direction={orderBy === '_id' ? order : 'asc'}
                        onClick={() => handleRequestSort('_id')}
                      >
                        ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'type'}
                        direction={orderBy === 'type' ? order : 'asc'}
                        onClick={() => handleRequestSort('type')}
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'activePeriod'}
                        direction={orderBy === 'activePeriod' ? order : 'asc'}
                        onClick={() => handleRequestSort('activePeriod')}
                      >
                        Active Period
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(filteredTemplates, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((template) => (
                      <TableRow key={template._id} hover>
                        <TableCell>{template.description}</TableCell>
                        <TableCell>{template._id}</TableCell>
                        <TableCell>{template.isStatic ? 'Static' : 'Dynamic'}</TableCell>
                        <TableCell>
                          {template.activePeriod === 'temporary' ? 'Temporary' : 'Permanent'}
                        </TableCell>
                        <TableCell>
                          {template.isDraft && <Chip label="Draft" color="warning" />}
                          {!template.isDraft && !template.isArchive && (
                            <Chip label="Active" color="success" />
                          )}
                          {template.isArchive && !template.isDraft && (
                            <Chip label="Archived" color="error" />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton color="primary" onClick={() => handleViewDetails(template)}>
                            <InfoIcon />
                          </IconButton>
                          <IconButton color="primary" onClick={() => handlePreviewTemplate(template)}>
                            <PreviewIcon />
                          </IconButton>
                          {!template.isArchive && (
                            <>
                              <IconButton
                                color="secondary"
                                onClick={() => handleEditTemplate(template, 'edit')}
                                disabled={!hasEditPermission}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => handleArchiveTemplate(template._id)}
                                disabled={!hasDeletePermission}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredTemplates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
            />
          </Box>
        </Box>
      </Box>

      {/* Template Details Dialog */}
      <Dialog open={openDetails} onClose={handleCloseDetails} maxWidth="md" fullWidth>
        <DialogTitle>Template Details</DialogTitle>
        <DialogContent dividers>
          {selectedTemplateDetails && (
            <Box sx={{ padding: 2 }}>
              <Typography variant="h6" gutterBottom>
                {selectedTemplateDetails.description}
              </Typography>
              <Typography variant="body1">
                <strong>ID:</strong> {selectedTemplateDetails._id}
              </Typography>
              <Typography variant="body1">
                <strong>Type:</strong> {selectedTemplateDetails.isStatic ? 'Static' : 'Dynamic'}
              </Typography>
              <Typography variant="body1">
                <strong>Active Period:</strong>{' '}
                {selectedTemplateDetails.activePeriod === 'temporary' ? 'Temporary' : 'Permanent'}
              </Typography>
              <Typography variant="body1">
                <strong>Status:</strong>{' '}
                {selectedTemplateDetails.isDraft
                  ? 'Draft'
                  : selectedTemplateDetails.isArchive
                  ? 'Archived'
                  : 'Active'}
              </Typography>
              <Typography variant="body1">
                <strong>Assigned To:</strong>{' '}
                {selectedTemplateDetails.assignedClients?.length || 0} Clients,{' '}
                {selectedTemplateDetails.assignedUsers?.length || 0} Users,{' '}
                {selectedTemplateDetails.assignedCareEntities?.length || 0} Care Entities
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Roles with Access:</strong>{' '}
                {Object.keys(selectedTemplateDetails.permissionsForRoles || {}).length}
              </Typography>
              <Typography variant="body1">
                <strong>Fields:</strong>
              </Typography>
              <Box component="ul" sx={{ pl: 2 }}>
                {selectedTemplateDetails.fields.map((field, index) => (
                  <li key={index}>
                    {field.label} ({field.type})
                  </li>
                ))}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
          {!selectedTemplateDetails?.isArchive && (
            <>
              <Button
                color="primary"
                onClick={() => handleEditTemplate(selectedTemplateDetails, 'edit')}
                disabled={!hasEditPermission}
              >
                Edit
              </Button>
              <Button
                color="error"
                onClick={() => handleArchiveTemplate(selectedTemplateDetails._id)}
                disabled={!hasDeletePermission}
              >
                Archive
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* Template Preview Dialog */}
      <Dialog open={previewOpen} onClose={handleClosePreview} maxWidth="md" fullWidth>
        <DialogTitle>Template Preview</DialogTitle>
        <DialogContent dividers>
          {templateToPreview && <TemplatePreviewModal fields={templateToPreview.fields} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedTemplateManagement = () => (
  <ErrorBoundary>
    <TemplateManagement />
  </ErrorBoundary>
);

export default WrappedTemplateManagement;