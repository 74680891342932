import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button, TextField, Typography, Container, Box, CircularProgress } from '@mui/material';
import logo from '../shared/logo.png';
import { useAuth } from '../context/AuthContext';
import { styles } from '../styles/styles';
import './SweetAlertCustom.css';  // Add this import for custom styles

export default function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const handleLogin = async () => {
    const normalizedEmail = email.trim().toLowerCase();

    if (!normalizedEmail) {
      Swal.fire({
        title: 'Error',
        text: 'Email field cannot be blank.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'custom-confirm-button'
        },
        backdrop: `
          rgba(0,0,0,0.4)
          url("/images/nyan-cat.gif")
          left top
          no-repeat
        `,
        background: 'linear-gradient(135deg, #258EA6, #549F93)',
        color: '#FFFFFF',
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(normalizedEmail)) {
      Swal.fire({
        title: 'Error',
        text: 'Please enter a valid email address.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'custom-confirm-button'
        },
        backdrop: `
          rgba(0,0,0,0.4)
          url("/images/nyan-cat.gif")
          left top
          no-repeat
        `,
        background: 'linear-gradient(135deg, #258EA6, #549F93)',
        color: '#FFFFFF',
      });
      return;
    }

    if (!password) {
      Swal.fire({
        title: 'Error',
        text: 'Password field cannot be blank.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'custom-confirm-button'
        },
        backdrop: `
          rgba(0,0,0,0.4)
          url("/images/nyan-cat.gif")
          left top
          no-repeat
        `,
        background: 'linear-gradient(135deg, #258EA6, #549F93)',
        color: '#FFFFFF',
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/users/signin`, { email: normalizedEmail, password });
      setLoading(false);
      if (response.data.token) {
        localStorage.setItem('userToken', response.data.token);
        localStorage.setItem('userId', response.data.userId);  // Ensure userId is stored correctly
        login(response.data.token);
        Swal.fire({
          title: 'Success',
          text: 'You have successfully logged in.',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'custom-confirm-button'
          },
          backdrop: `
            rgba(0,0,0,0.4)
            url("/images/nyan-cat.gif")
            left top
            no-repeat
          `,
          background: 'linear-gradient(135deg, #258EA6, #549F93)',
          color: '#FFFFFF',
        });
        navigate('/home');
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Invalid credentials.',
          icon: 'error',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'custom-confirm-button'
          },
          backdrop: `
            rgba(0,0,0,0.4)
            url("/images/nyan-cat.gif")
            left top
            no-repeat
          `,
          background: 'linear-gradient(135deg, #258EA6, #549F93)',
          color: '#FFFFFF',
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred during login.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'custom-confirm-button'
        },
        backdrop: `
          rgba(0,0,0,0.4)
          url("/images/nyan-cat.gif")
          left top
          no-repeat
        `,
        background: 'linear-gradient(135deg, #258EA6, #549F93)',
        color: '#FFFFFF',
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={styles.loginContainer}>
      <Box sx={styles.formContainer}>
        <img src={logo} style={styles.logo} alt="Logo" aria-label="Application Logo" />
        <Typography component="h1" variant="h5" aria-label="Welcome Back Header" sx={styles.primaryHeader}>Welcome Back!</Typography>
        <Typography component="h2" variant="subtitle1" aria-label="Login Instruction">Please login to continue</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          aria-label="Email Input"
          sx={styles.input}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          aria-label="Password Input"
          sx={styles.input}
          onKeyDown={handleKeyDown}
        />
        <Box sx={styles.buttonContainer}>
          {loading ? (
            <CircularProgress aria-label="Loading Indicator" />
          ) : (
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={styles.button}
              onClick={handleLogin}
              aria-label="Login Button"
            >
              Login
            </Button>
          )}
        </Box>
        <Box sx={styles.linkContainer}>
          <Button
            type="button" 
            onClick={() => navigate('/choose-organization')}
            aria-label="Sign Up Link"
            sx={styles.link}
          >
            Sign Up
          </Button>
          <Button
            type="button"
            onClick={() => navigate('/forgot-password')}
            aria-label="Forgot Password Link"
            sx={styles.link}
          >
            Forgot Password?
          </Button>
        </Box>
      </Box>
    </Container>
  );
}