// src/screens/CreateScreen.js

import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, Button } from '@mui/material';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';

const CreateScreen = ({ templateDetails, setTemplateDetails, handleNext }) => {
  const [descriptionError, setDescriptionError] = useState('');
  const [charCount, setCharCount] = useState(0);
  const { permissions = [], loading: authLoading } = useAuth();

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  useEffect(() => {
    if (!authLoading && !hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } else if (!authLoading) {
      const savedDraft = JSON.parse(localStorage.getItem('carePlanDraft'));
      if (savedDraft) {
        setTemplateDetails(savedDraft || {});
      }
    }
  }, [authLoading, hasViewPermission, setTemplateDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'description' && value.length <= 100) {
      setCharCount(value.length);
      setTemplateDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleNextClick = () => {
    if (!templateDetails.description) {
      setDescriptionError('A description must be provided to proceed in the template builder.');
      Swal.fire({
        icon: 'error',
        title: 'Description Missing',
        text: 'Please provide a description to proceed.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    setDescriptionError('');
    handleNext();
  };

  if (authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '24px' }} aria-label="Create CarePlan Template Screen">
      <Typography variant="h4" gutterBottom aria-label="Create CarePlan Template Title">
        Create CarePlan Template
      </Typography>
      <Typography variant="body1" gutterBottom aria-label="Template Description Instruction">
        The description is used to identify CarePlan templates. Try to be descriptive so users will
        recognize it easily.
      </Typography>
      <TextField
        label="Description"
        name="description"
        value={templateDetails.description || ''}
        onChange={handleChange}
        fullWidth
        error={!!descriptionError}
        helperText={descriptionError || `${charCount}/100 characters`}
        margin="normal"
        aria-label="Template Description Input"
        inputProps={{ maxLength: 100 }}
      />
      <Typography
        variant="body1"
        color="textSecondary"
        sx={{ marginTop: '8px' }}
        aria-label="Template Version Info"
      >
        The version is automatically generated when you create your template. In the future, it will
        update every time you hit "Finish" and activate the template. If you aren't quite ready to
        update your version, just click "Save and Close" to save a draft.
      </Typography>
      <TextField
        label="Version"
        name="version"
        value={templateDetails.version || '1.0'}
        onChange={handleChange}
        fullWidth
        disabled
        margin="normal"
        aria-label="Template Version Input"
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextClick}
          sx={{ marginRight: '8px' }}
          aria-label="Next Button"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

// Wrap the component with ErrorBoundary
const WrappedCreateScreen = (props) => (
  <ErrorBoundary>
    <CreateScreen {...props} />
  </ErrorBoundary>
);

export default WrappedCreateScreen;