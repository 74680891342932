// src/components/ClientCarePlans.js

import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Checkbox,
  TablePagination,
} from '@mui/material';
import { ExpandLess, ExpandMore, OpenInNew } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Import useAuth
import ErrorBoundary from './ErrorBoundary'; // Import ErrorBoundary

const ClientCarePlans = ({ clientID }) => {
  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth();
  const [client, setClient] = useState({});
  const [careEntities, setCareEntities] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [filterOption, setFilterOption] = useState('Active');
  const [processLabels, setProcessLabels] = useState([]);
  const [selectedTab, setSelectedTab] = useState('all');

  const [pageDirect, setPageDirect] = useState(0);
  const [rowsPerPageDirect, setRowsPerPageDirect] = useState(20);

  const [pageEntity, setPageEntity] = useState(0);
  const [rowsPerPageEntity, setRowsPerPageEntity] = useState(20);

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Fetch client data and care entities
  const fetchClientData = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');

      // Fetch client data
      const populatedClient = await axios.get(`${API_BASE_URL}/api/clients/${clientID}/populate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setClient(populatedClient.data);

      // Fetch care entities assigned to the client
      const careEntitiesResponse = await axios.get(`${API_BASE_URL}/api/careentities/populate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const assignedEntities = careEntitiesResponse.data.filter((entity) =>
        entity.assignedClients.some((client) => client.clientID === clientID)
      );

      setCareEntities(assignedEntities);
    } catch (error) {
      console.error('Error fetching client or care entities:', error);
    }
  }, [clientID, API_BASE_URL]);

  // Fetch process labels
  useEffect(() => {
    const fetchProcessLabels = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get(`${API_BASE_URL}/api/processlabels`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProcessLabels(response.data);
      } catch (error) {
        console.error('Error fetching process labels:', error);
      }
    };

    fetchProcessLabels();
  }, [API_BASE_URL]);

  useEffect(() => {
    fetchClientData();
  }, [fetchClientData]);

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleOpenCarePlan = (templateID, isStatic) => {
    if (isStatic) {
      navigate(`/static-care-plan/${templateID}/${clientID}`);
    } else {
      navigate(`/dynamic-care-plan/${templateID}/${clientID}`);
    }
  };

  const hasPermissionToView = (resourceID) => {
    return permissions.includes(`view_${resourceID}`);
  };

  const isCarePlanActivePeriod = (carePlan) => {
    if (carePlan.activePeriod === 'permanent') {
      return true;
    }

    if (carePlan.activePeriod === 'temporary') {
      const currentDate = new Date();
      const startDate = new Date(carePlan.startDate);
      const endDate = new Date(carePlan.endDate);

      return currentDate >= startDate && currentDate <= endDate;
    }

    return false;
  };

  const isCarePlanVisible = (carePlan) => {
    if (carePlan.isDraft) {
      return false;
    }

    const matchesFilter = (() => {
      if (filterOption === 'Active') {
        return carePlan.isActive && !carePlan.isArchive && isCarePlanActivePeriod(carePlan);
      } else if (filterOption === 'Archived') {
        return carePlan.isArchive;
      } else if (filterOption === 'All') {
        return true;
      }
      return false;
    })();

    if (!matchesFilter) {
      return false;
    }

    // Check process label filtering based on selectedTab
    const assignedProcessLabels = carePlan.assignedProcessLabels || [];
    const processLabelIds = assignedProcessLabels.map((label) =>
      typeof label === 'object' ? label._id : label
    );

    const matchesTab = (() => {
      if (selectedTab === 'all') {
        return true;
      } else if (selectedTab === 'other') {
        return processLabelIds.length === 0;
      } else {
        return processLabelIds.includes(selectedTab);
      }
    })();

    if (!matchesTab) {
      return false;
    }

    // Search term filtering
    if (searchTerm) {
      const description = carePlan.description || '';
      if (!description.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
    }

    // Check permissions
    return hasPermissionToView(carePlan._id);
  };

  if (authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Typography variant="body1">Loading...</Typography>
      </Box>
    );
  }

  // Prepare tabs
  const tabs = [
    { label: 'All', value: 'all' },
    ...processLabels.map((label) => ({ label: label.name, value: label._id })),
    { label: 'Other', value: 'other' },
  ];

  // Filter care plans
  const directCarePlans = client.assignedCarePlanTemplates || [];
  const visibleDirectCarePlans = directCarePlans.filter(isCarePlanVisible);

  const paginatedDirectCarePlans = visibleDirectCarePlans.slice(
    pageDirect * rowsPerPageDirect,
    pageDirect * rowsPerPageDirect + rowsPerPageDirect
  );

  const careEntityCarePlans = [];
  careEntities.forEach((careEntity) => {
    (careEntity.assignedCarePlanTemplates || []).forEach((carePlan) => {
      if (isCarePlanVisible(carePlan)) {
        careEntityCarePlans.push({
          careEntityName: careEntity.name,
          carePlan,
        });
      }
    });
  });

  const paginatedCareEntityCarePlans = careEntityCarePlans.slice(
    pageEntity * rowsPerPageEntity,
    pageEntity * rowsPerPageEntity + rowsPerPageEntity
  );

  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        {/* Search Bar and Filter */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <TextField
            label="Search Care Plans"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginRight: 2 }}
          />
          <FormControl variant="outlined" sx={{ marginRight: 2 }}>
            <InputLabel id="filter-label">Filter</InputLabel>
            <Select
              labelId="filter-label"
              value={filterOption}
              onChange={(e) => setFilterOption(e.target.value)}
              label="Filter"
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Archived">Archived</MenuItem>
              <MenuItem value="All">All</MenuItem>
            </Select>
          </FormControl>

          {/* Process Tabs */}
          {tabs.length > 2 && (
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}
              aria-label="Process Tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{ flexGrow: 1 }}
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          )}
        </Box>

        {/* Direct Care Plans Section */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">Direct Care Plans</Typography>
          <IconButton onClick={handleMinimize} sx={{ ml: 1 }}>
            {isMinimized ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </Box>
        {!isMinimized && (
          <>
            <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>Description</TableCell>
                    <TableCell sx={{ py: 1 }}>Type</TableCell>
                    <TableCell sx={{ py: 1 }}>Update Freq.</TableCell>
                    <TableCell sx={{ py: 1 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedDirectCarePlans.length > 0 ? (
                    paginatedDirectCarePlans.map((carePlan, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ py: 1 }}>{carePlan.description}</TableCell>
                        <TableCell sx={{ py: 1 }}>
                          {carePlan.isStatic ? 'Static' : 'Dynamic'}
                        </TableCell>
                        <TableCell sx={{ py: 1 }}>{carePlan.updateFrequency || 'N/A'}</TableCell>
                        <TableCell sx={{ py: 1 }}>
                          <IconButton
                            onClick={() => handleOpenCarePlan(carePlan._id, carePlan.isStatic)}
                          >
                            <OpenInNew />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>No direct care plans assigned</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={visibleDirectCarePlans.length}
              rowsPerPage={rowsPerPageDirect}
              page={pageDirect}
              onPageChange={(event, newPage) => setPageDirect(newPage)}
              onRowsPerPageChange={(event) => {
                setRowsPerPageDirect(parseInt(event.target.value, 10));
                setPageDirect(0);
              }}
            />
          </>
        )}

        {/* Care Entity Care Plans Section */}
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          Care Entity Care Plans
        </Typography>
        <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: 1 }}>Care Entity Name</TableCell>
                <TableCell sx={{ py: 1 }}>Description</TableCell>
                <TableCell sx={{ py: 1 }}>Type</TableCell>
                <TableCell sx={{ py: 1 }}>Update Freq.</TableCell>
                <TableCell sx={{ py: 1 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCareEntityCarePlans.length > 0 ? (
                paginatedCareEntityCarePlans.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ py: 1 }}>{item.careEntityName}</TableCell>
                    <TableCell sx={{ py: 1 }}>{item.carePlan.description}</TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {item.carePlan.isStatic ? 'Static' : 'Dynamic'}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>{item.carePlan.updateFrequency || 'N/A'}</TableCell>
                    <TableCell sx={{ py: 1 }}>
                      <IconButton
                        onClick={() =>
                          handleOpenCarePlan(item.carePlan._id, item.carePlan.isStatic)
                        }
                      >
                        <OpenInNew />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>No care plans assigned through care entities</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={careEntityCarePlans.length}
          rowsPerPage={rowsPerPageEntity}
          page={pageEntity}
          onPageChange={(event, newPage) => setPageEntity(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPageEntity(parseInt(event.target.value, 10));
            setPageEntity(0);
          }}
        />
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedClientCarePlans = (props) => (
  <ErrorBoundary>
    <ClientCarePlans {...props} />
  </ErrorBoundary>
);

export default WrappedClientCarePlans;