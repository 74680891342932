// src/screens/PermissionsScreen.jsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Button, TextField, Tabs, Tab, Dialog, DialogTitle,
  DialogContent, DialogActions, Checkbox, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import customAlertStyles from '../styles/CustomAlertStyles';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const PermissionsScreen = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();

  const { permissions: userPermissions = [], loading: authLoading } = useAuth();

  const hasViewPermission = userPermissions.includes('view_permissions');
  const hasEditPermission = userPermissions.includes('edit_permissions');

  const [role, setRole] = useState(null);
  const [roleName, setRoleName] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [availableScreens, setAvailableScreens] = useState([]);
  const [availableCarePlans, setAvailableCarePlans] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [confirmLeaveOpen, setConfirmLeaveOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResources, setFilteredResources] = useState([]);

  const actions = ['view', 'add', 'edit', 'delete'];

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (roleId === 'new') {
      setRoleName('');
      setPermissions([]);
      setLoading(false);
    } else {
      fetchRole();
    }
    fetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId]);

  useEffect(() => {
    filterResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, activeTab, availableScreens, availableCarePlans]);

  const fetchRole = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/roles/${roleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRole(response.data);
      setRoleName(response.data.name);
      setPermissions(response.data.permissions || []);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch role details. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  }, [roleId]);

  const fetchResources = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const [screensResponse, carePlansResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/api/screens`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${API_BASE_URL}/api/careplantemplates`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      setAvailableScreens(screensResponse.data.screens);
      setAvailableCarePlans(carePlansResponse.data);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch resources. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, []);

  // Adjusting isDirty logic to handle both new and existing roles, considering roleName changes
  useEffect(() => {
    if (roleId === 'new') {
      // For new roles, if there's any input in roleName or any permissions selected, mark as dirty
      const nameChanged = roleName.trim() !== '';
      const permissionsChanged = permissions.length > 0;
      setIsDirty(nameChanged || permissionsChanged);
    } else {
      if (role) {
        const nameChanged = roleName.trim() !== role.name.trim();
        const permissionsChanged = JSON.stringify([...permissions].sort()) !== JSON.stringify((role.permissions || []).sort());
        setIsDirty(nameChanged || permissionsChanged);
      }
    }
  }, [permissions, role, roleId, roleName]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const handleCancel = () => {
    if (isDirty) {
      setConfirmLeaveOpen(true);
    } else {
      navigate('/role-manager');
    }
  };

  const handleConfirmLeave = () => {
    setConfirmLeaveOpen(false);
    navigate('/role-manager');
  };

  const handlePermissionChange = (resource, action) => {
    const permissionName = `${action}_${resource}`;
    const updatedPermissions = permissions.includes(permissionName)
      ? permissions.filter((perm) => perm !== permissionName)
      : [...permissions, permissionName];
    setPermissions(updatedPermissions);
  };

  const handleSelectAllPermissionsForAction = (action, isSelected) => {
    const resources = filteredResources.map((res) => res.resource);
    let updatedPermissions = [...permissions];

    resources.forEach((resource) => {
      const permissionName = `${action}_${resource}`;
      if (isSelected && !permissions.includes(permissionName)) {
        updatedPermissions.push(permissionName);
      } else if (!isSelected && permissions.includes(permissionName)) {
        updatedPermissions = updatedPermissions.filter((perm) => perm !== permissionName);
      }
    });
    setPermissions(updatedPermissions);
  };

  const handleSelectAllPermissionsForResource = (resource, isSelected) => {
    let updatedPermissions = [...permissions];

    actions.forEach((action) => {
      const permissionName = `${action}_${resource}`;
      if (isSelected && !permissions.includes(permissionName)) {
        updatedPermissions.push(permissionName);
      } else if (!isSelected && permissions.includes(permissionName)) {
        updatedPermissions = updatedPermissions.filter((perm) => perm !== permissionName);
      }
    });
    setPermissions(updatedPermissions);
  };

  const handleSave = async () => {
    if (!hasEditPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit permissions.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!roleName.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Role name is required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      const payload = {
        name: roleName,
        permissions,
      };

      if (roleId === 'new') {
        await axios.post(`${API_BASE_URL}/api/roles`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        Swal.fire({
          title: 'Success',
          text: 'Role created successfully!',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        await axios.put(`${API_BASE_URL}/api/roles/${roleId}/permissions`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        Swal.fire({
          title: 'Success',
          text: 'Permissions updated successfully!',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      }
      navigate('/role-manager');
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to save role and permissions. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchTerm('');
  };

  const filterResources = useCallback(() => {
    let resources = activeTab === 0 ? availableScreens : availableCarePlans;
    if (searchTerm) {
      resources = resources.filter((res) =>
        (res.name || res.description).toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredResources(
      resources.map((res) => ({
        id: res._id || res.id,
        name: res.name || res.description,
        resource: res.resource || res._id,
      }))
    );
  }, [activeTab, availableScreens, availableCarePlans, searchTerm]);

  const isAllSelectedForAction = (action) => {
    return filteredResources.every((res) => permissions.includes(`${action}_${res.resource}`));
  };

  const isIndeterminateForAction = (action) => {
    const total = filteredResources.length;
    const selected = filteredResources.filter((res) => permissions.includes(`${action}_${res.resource}`)).length;
    return selected > 0 && selected < total;
  };

  const isAllSelectedForResource = (resource) => {
    return actions.every((action) => permissions.includes(`${action}_${resource}`));
  };

  const isIndeterminateForResource = (resource) => {
    const total = actions.length;
    const selected = actions.filter((action) => permissions.includes(`${action}_${resource}`)).length;
    return selected > 0 && selected < total;
  };

  if (authLoading || loading) {
    return (
      <Typography aria-label="Loading message" sx={{ textAlign: 'center', marginTop: '20px' }}>
        Loading...
      </Typography>
    );
  }

  if (!hasViewPermission) {
    return (
      <Typography aria-label="Access Denied" sx={{ textAlign: 'center', marginTop: '20px' }}>
        You do not have permission to view this page.
      </Typography>
    );
  }

  return (
    <ErrorBoundary>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1}>
          <Box sx={{ padding: '24px', marginTop: '64px' }}>
            <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
              {roleId === 'new' ? 'Create New Role' : `Manage Permissions for ${roleName}`}
            </Typography>

            {/* Role Name Input Field */}
            <TextField
              label="Role Name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              fullWidth
              margin="normal"
              aria-label="Role Name"
              disabled={!hasEditPermission}
            />

            {/* Tabs for Screens and Care Plan Templates */}
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="Permission Tabs">
              <Tab label="Screens" />
              <Tab label="Care Plan Templates" />
            </Tabs>

            {/* Search Field */}
            <TextField
              label="Search Resources"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              margin="normal"
              aria-label="Search Resources"
            />

            {/* Permissions Table */}
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table stickyHeader aria-label="permissions table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Resource
                    </TableCell>
                    {actions.map((action) => (
                      <TableCell key={action} align="center">
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Checkbox
                            checked={isAllSelectedForAction(action)}
                            indeterminate={isIndeterminateForAction(action)}
                            onChange={(e) => handleSelectAllPermissionsForAction(action, e.target.checked)}
                            inputProps={{ 'aria-label': `Select all ${action} permissions` }}
                            disabled={!hasEditPermission}
                          />
                          <Typography variant="body2">
                            {action.charAt(0).toUpperCase() + action.slice(1)}
                          </Typography>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredResources.map((res) => (
                    <TableRow key={res.id}>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            checked={isAllSelectedForResource(res.resource)}
                            indeterminate={isIndeterminateForResource(res.resource)}
                            onChange={(e) => handleSelectAllPermissionsForResource(res.resource, e.target.checked)}
                            inputProps={{ 'aria-label': `Select all permissions for ${res.name}` }}
                            disabled={!hasEditPermission}
                          />
                          {res.name}
                        </Box>
                      </TableCell>
                      {actions.map((action) => (
                        <TableCell key={action} align="center">
                          <Checkbox
                            checked={permissions.includes(`${action}_${res.resource}`)}
                            onChange={() => handlePermissionChange(res.resource, action)}
                            color="primary"
                            inputProps={{
                              'aria-label': `Permission ${action} on ${res.name}`,
                            }}
                            disabled={!hasEditPermission}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Action Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
              <Button variant="contained" color="secondary" onClick={handleCancel} aria-label="Cancel">
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                aria-label="Save Permissions"
                disabled={!isDirty || saving || !hasEditPermission}
              >
                {saving ? 'Saving...' : 'Save Permissions'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Confirm Leave Dialog */}
      <Dialog open={confirmLeaveOpen} onClose={() => setConfirmLeaveOpen(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes. Are you sure you want to leave without saving?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmLeaveOpen(false)} color="primary">
            Stay
          </Button>
          <Button onClick={handleConfirmLeave} color="secondary">
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </ErrorBoundary>
  );
};

export default PermissionsScreen;