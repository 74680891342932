// src/screens/RoleManagerScreen.js

import React, { useEffect, useState, useCallback } from 'react';
import {
  TextField,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableSortLabel,
  TablePagination,
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { styles } from '../styles/styles';
import customAlertStyles from '../styles/CustomAlertStyles';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Import AuthContext for permission checks
import ErrorBoundary from '../components/ErrorBoundary';

const RoleManagerScreen = () => {
  const [roles, setRoles] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth(); // Fetch permissions from AuthContext

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Permission flags
  const hasViewPermission = permissions.includes('view_role-manager');
  const hasAddPermission = permissions.includes('add_role-manager');
  const hasEditPermission = permissions.includes('edit_role-manager');
  const hasDeletePermission = permissions.includes('delete_role-manager');

  const fetchRoles = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Fetched Roles:', response.data); // Log the fetched roles for debugging
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch roles. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    if (!authLoading && hasViewPermission) {
      fetchRoles();
    } else if (!authLoading && !hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/home');
    }
  }, [authLoading, hasViewPermission, fetchRoles, navigate]);

  const handleDeleteRole = async () => {
    if (!hasDeletePermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to delete roles.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      await axios.delete(`${API_BASE_URL}/api/roles/${roleToDelete._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRoles(roles.filter((role) => role._id !== roleToDelete._id));
      setOpenDeleteDialog(false);
      Swal.fire({
        title: 'Success',
        text: `${roleToDelete.name} has been deleted successfully.`,
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      console.error('Error deleting role:', error);
      Swal.fire({
        title: 'Error',
        text: `Failed to delete role. Please try again later.`,
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  const handleOpenDeleteDialog = (role) => {
    setRoleToDelete(role);
    setOpenDeleteDialog(true);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRoles = roles.filter((role) =>
    role.name.toLowerCase().includes(search.toLowerCase())
  );

  const sortedRoles = filteredRoles.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy].localeCompare(b[orderBy]);
    }
    return b[orderBy].localeCompare(a[orderBy]);
  });

  const paginatedRoles = sortedRoles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1}>
          <Box sx={{ padding: '24px', marginTop: '64px' }}>
            <Typography component="h1" variant="h5" sx={styles.primaryHeader} aria-label="Role Manager">
              Role Manager
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
              <TextField
                variant="outlined"
                placeholder="Search..."
                value={search}
                onChange={handleSearchChange}
                sx={{ marginRight: '16px', flex: 1 }}
                aria-label="Search Roles"
              />
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <Button
                  variant="contained"
                  sx={{
                    ...styles.button,
                    width: '150px',
                    height: '40px',
                    backgroundColor: hasAddPermission ? undefined : '#ccc',
                    cursor: hasAddPermission ? 'pointer' : 'not-allowed',
                  }}
                  onClick={hasAddPermission ? () => navigate(`/permissions/new`) : null}
                  aria-label="Create Role"
                  disabled={!hasAddPermission}
                >
                  Create Role
                </Button>
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="role table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={() => handleSortRequest('name')}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Users Assigned</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedRoles.map((role) => (
                    <TableRow key={role._id}>
                      <TableCell>{role.name}</TableCell>
                      <TableCell>{role.userCount}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', gap: '8px' }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={hasEditPermission ? () => navigate(`/permissions/${role._id}`) : null}
                            aria-label={`Edit permissions for role ${role.name}`}
                            disabled={!hasEditPermission}
                            sx={{
                              backgroundColor: hasEditPermission ? undefined : '#ccc',
                              cursor: hasEditPermission ? 'pointer' : 'not-allowed',
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={hasDeletePermission ? () => handleOpenDeleteDialog(role) : null}
                            aria-label={`Delete role ${role.name}`}
                            disabled={!hasDeletePermission}
                            sx={{
                              backgroundColor: hasDeletePermission ? undefined : '#ccc',
                              cursor: hasDeletePermission ? 'pointer' : 'not-allowed',
                            }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={sortedRoles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                aria-label="Role Table Pagination"
              />
            </TableContainer>

            {/* Delete Confirmation Dialog */}
            <Dialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
              aria-labelledby="delete-role-dialog-title"
            >
              <DialogTitle id="delete-role-dialog-title">Confirm Deletion</DialogTitle>
              <DialogContent>
                Are you sure you want to delete the role "{roleToDelete?.name}"?
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDeleteDialog(false)} aria-label="Cancel Deletion">
                  Cancel
                </Button>
                <Button onClick={handleDeleteRole} aria-label="Confirm Deletion">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedRoleManagerScreen = () => (
  <ErrorBoundary>
    <RoleManagerScreen />
  </ErrorBoundary>
);

export default WrappedRoleManagerScreen;