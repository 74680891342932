// src/screens/CareVisualizerScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Tooltip,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import * as d3 from 'd3';
import html2canvas from 'html2canvas';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const CareVisualizerScreen = () => {
  const { permissions = [] } = useAuth();

  // Function to check permissions
  const hasPermission = useCallback(
    (action) => {
      return permissions.includes(action);
    },
    [permissions]
  );

  // State hooks
  const [staff, setStaff] = useState([]);
  const [clients, setClients] = useState([]);
  const [careEntities, setCareEntities] = useState([]);
  const [staffSearch, setStaffSearch] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const [careEntitySearch, setCareEntitySearch] = useState('');
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const [loadingStaff, setLoadingStaff] = useState(true);
  const [loadingClients, setLoadingClients] = useState(true);
  const [loadingCareEntities, setLoadingCareEntities] = useState(true);
  const [loadingVisualization, setLoadingVisualization] = useState(false);
  const [error, setError] = useState(null);

  // Fetch Staff
  const fetchStaff = useCallback(async () => {
    if (!hasPermission('view_care-visualizer')) {
      setStaff([]);
      setLoadingStaff(false);
      return;
    }
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/users?role=staff`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStaff(response.data);
      console.log('Fetched staff:', response.data);
    } catch (error) {
      console.error('Error fetching staff:', error);
      setError('Failed to fetch staff.');
    } finally {
      setLoadingStaff(false);
    }
  }, [hasPermission]);

  // Fetch Clients
  const fetchClients = useCallback(async () => {
    if (!hasPermission('view_care-visualizer')) {
      setClients([]);
      setLoadingClients(false);
      return;
    }
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClients(response.data);
      console.log('Fetched clients:', response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Failed to fetch clients.');
    } finally {
      setLoadingClients(false);
    }
  }, [hasPermission]);

  // Fetch Care Entities
  const fetchCareEntities = useCallback(async () => {
    if (!hasPermission('view_care-visualizer')) {
      setCareEntities([]);
      setLoadingCareEntities(false);
      return;
    }
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careentities`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCareEntities(response.data);
      console.log('Fetched care entities:', response.data);
    } catch (error) {
      console.error('Error fetching care entities:', error);
      setError('Failed to fetch care entities.');
    } finally {
      setLoadingCareEntities(false);
    }
  }, [hasPermission]);

  // Initial Data Fetch
  useEffect(() => {
    fetchStaff();
    fetchClients();
    fetchCareEntities();
  }, [fetchStaff, fetchClients, fetchCareEntities]);

  // Handle Drag Start
  const handleDragStart = (event, person, type) => {
    event.dataTransfer.setData('person', JSON.stringify({ person, type }));
  };

  // Handle Drop
  const handleDrop = async (event) => {
    event.preventDefault();
    const data = JSON.parse(event.dataTransfer.getData('person'));
    const svgElement = document.getElementById('visualization-area');
    const rect = svgElement.getBoundingClientRect();
    const x = Math.max(50, Math.min(rect.width - 50, event.clientX - rect.left));
    const y = Math.max(50, Math.min(rect.height - 50, event.clientY - rect.top));

    const newNode = {
      id: `${data.type}-${data.person._id}`,
      data: {
        label:
          data.type === 'careEntity'
            ? data.person.name
            : `${data.person.firstName} ${data.person.lastName}`,
      },
      x,
      y,
      fx: x,
      fy: y,
      type: data.type,
    };

    setLoadingVisualization(true);

    try {
      const token = localStorage.getItem('userToken');
      let response;

      if (data.type === 'careEntity') {
        response = await axios.get(
          `${API_BASE_URL}/api/careentities/${data.person._id}/assigned-records`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const careEntity = response.data;

        const staffNodes = careEntity.assignedStaff.map((staff, index) => ({
          id: `staff-${staff._id}`,
          data: { label: `${staff.firstName} ${staff.lastName}` },
          x: x + 100 * (index + 1),
          y: y + 50 * (index + 1),
          type: 'staff',
        }));

        const clientNodes = careEntity.assignedClients.map((client, index) => ({
          id: `client-${client._id}`,
          data: { label: `${client.firstName} ${client.lastName}` },
          x: x + 100 * (index + 1),
          y: y - 50 * (index + 1),
          type: 'client',
        }));

        setNodes((prevNodes) => [...prevNodes, newNode, ...staffNodes, ...clientNodes]);
        setLinks((prevLinks) => [
          ...prevLinks,
          ...staffNodes.map((staffNode) => ({ source: newNode.id, target: staffNode.id })),
          ...clientNodes.map((clientNode) => ({ source: newNode.id, target: clientNode.id })),
        ]);
      } else if (data.type === 'client') {
        response = await axios.get(
          `${API_BASE_URL}/api/clients/${data.person._id}/assigned-careentities`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const careEntities = response.data;

        const careEntityNodes = careEntities.map((careEntity, index) => ({
          id: `careEntity-${careEntity._id}`,
          data: { label: careEntity.name },
          x: x + 100 * (index + 1),
          y: y - 50 * (index + 1),
          type: 'careEntity',
        }));

        setNodes((prevNodes) => [...prevNodes, newNode, ...careEntityNodes]);
        setLinks((prevLinks) =>
          prevLinks.concat(
            careEntityNodes.map((node) => ({ source: newNode.id, target: node.id }))
          )
        );
      } else if (data.type === 'staff') {
        response = await axios.get(
          `${API_BASE_URL}/api/users/${data.person._id}/assigned-careentities`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const careEntities = response.data;

        const careEntityNodes = careEntities.map((careEntity, index) => ({
          id: `careEntity-${careEntity._id}`,
          data: { label: careEntity.name },
          x: x + 100 * (index + 1),
          y: y - 50 * (index + 1),
          type: 'careEntity',
        }));

        setNodes((prevNodes) => [...prevNodes, newNode, ...careEntityNodes]);
        setLinks((prevLinks) =>
          prevLinks.concat(
            careEntityNodes.map((node) => ({ source: newNode.id, target: node.id }))
          )
        );
      }
    } catch (error) {
      console.error('Error fetching assigned records:', error);
      setError('Failed to fetch assigned records.');
    } finally {
      setLoadingVisualization(false);
    }
  };

  // Clear Visualization
  const clearVisualization = () => {
    setNodes([]);
    setLinks([]);
  };

  // Download Visualization
  const downloadVisualization = () => {
    const svgElement = document.getElementById('visualization-area');
    html2canvas(svgElement, { useCORS: true })
      .then((canvas) => {
        const link = document.createElement('a');
        link.download = 'visualization.png';
        link.href = canvas.toDataURL();
        link.click();
      })
      .catch((error) => {
        console.error('Error capturing visualization:', error);
        setError('Failed to download visualization.');
      });
  };

  // Render D3 Visualization
  useEffect(() => {
    if (loadingStaff || loadingClients || loadingCareEntities) {
      return;
    }

    const svgElement = document.getElementById('visualization-area');
    const width = svgElement.clientWidth;
    const height = svgElement.clientHeight;

    const svg = d3.select(svgElement);
    svg.selectAll('*').remove();

    const simulation = d3
      .forceSimulation(nodes)
      .force('link', d3.forceLink(links).id((d) => d.id).distance(150))
      .force('charge', d3.forceManyBody().strength(-300))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('collision', d3.forceCollide().radius(60));

    const link = svg
      .append('g')
      .attr('stroke', '#999')
      .attr('stroke-opacity', 0.6)
      .selectAll('line')
      .data(links)
      .enter()
      .append('line')
      .attr('stroke-width', 2);

    const node = svg
      .append('g')
      .selectAll('g')
      .data(nodes)
      .enter()
      .append('g')
      .call(
        d3
          .drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );

    node
      .append('rect')
      .attr('width', (d) => d.data.label.length * 10 + 40)
      .attr('height', 50)
      .attr('x', (d) => -((d.data.label.length * 10 + 40) / 2))
      .attr('y', -25)
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('fill', (d) =>
        d.type === 'careEntity' ? '#549F93' : d.type === 'staff' ? '#258EA6' : '#153243'
      );

    node
      .append('text')
      .attr('dy', 5)
      .attr('dx', 0)
      .attr('text-anchor', 'middle')
      .text((d) => d.data.label)
      .style('fill', 'white')
      .style('font-weight', 'bold');

    simulation.on('tick', () => {
      link
        .attr('x1', (d) => d.source.x)
        .attr('y1', (d) => d.source.y)
        .attr('x2', (d) => d.target.x)
        .attr('y2', (d) => d.target.y);

      node.attr('transform', (d) => {
        d.x = Math.max(50, Math.min(width - 50, d.x));
        d.y = Math.max(50, Math.min(height - 50, d.y));
        return `translate(${d.x},${d.y})`;
      });
    });

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    return () => {
      simulation.stop();
    };
  }, [nodes, links, loadingStaff, loadingClients, loadingCareEntities]);

  return (
    <>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px', overflow: 'hidden' }}>
          <Typography variant="h4" sx={{ marginBottom: '24px' }}>
            Care Visualizer
          </Typography>

          {/* Search and Action Buttons */}
          <Box sx={{ display: 'flex', gap: '16px', marginBottom: '24px' }}>
            <TextField
              variant="outlined"
              placeholder="Search staff, clients, or care entities..."
              value={staffSearch || clientSearch || careEntitySearch}
              onChange={(e) => {
                setStaffSearch(e.target.value);
                setClientSearch(e.target.value);
                setCareEntitySearch(e.target.value);
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <IconButton aria-label="Search">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            <Tooltip title="Filter" arrow>
              <IconButton aria-label="Filter">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sort" arrow>
              <IconButton aria-label="Sort">
                <SortIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Grid container spacing={3}>
            {/* Left Column: Accordions */}
            <Grid item xs={12} md={3}>
              {/* Staff Section */}
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="staff-content"
                  id="staff-header"
                >
                  <Typography variant="h6" sx={{ color: '#258EA6' }}>
                    Staff
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {loadingStaff ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Paper
                      sx={{
                        maxHeight: 'calc(100vh - 300px)',
                        overflow: 'auto',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {staff
                          .filter((person) =>
                            `${person.firstName} ${person.lastName}`
                              .toLowerCase()
                              .includes(staffSearch.toLowerCase())
                          )
                          .map((person) => (
                            <li
                              key={person._id}
                              draggable
                              onDragStart={(event) => handleDragStart(event, person, 'staff')}
                              style={{
                                cursor: 'grab',
                                marginBottom: '8px',
                                padding: '8px',
                                borderRadius: '4px',
                                backgroundColor: '#e0f7fa',
                              }}
                            >
                              {person.firstName} {person.lastName}
                            </li>
                          ))}
                      </ul>
                    </Paper>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* Clients Section */}
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="clients-content"
                  id="clients-header"
                >
                  <Typography variant="h6" sx={{ color: '#549F93' }}>
                    Clients
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {loadingClients ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Paper
                      sx={{
                        maxHeight: 'calc(100vh - 300px)',
                        overflow: 'auto',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {clients
                          .filter((person) =>
                            `${person.firstName} ${person.lastName}`
                              .toLowerCase()
                              .includes(clientSearch.toLowerCase())
                          )
                          .map((person) => (
                            <li
                              key={person._id}
                              draggable
                              onDragStart={(event) => handleDragStart(event, person, 'client')}
                              style={{
                                cursor: 'grab',
                                marginBottom: '8px',
                                padding: '8px',
                                borderRadius: '4px',
                                backgroundColor: '#e8f5e9',
                              }}
                            >
                              {person.firstName} {person.lastName}
                            </li>
                          ))}
                      </ul>
                    </Paper>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* Care Entities Section */}
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="care-entities-content"
                  id="care-entities-header"
                >
                  <Typography variant="h6" sx={{ color: '#153243' }}>
                    Care Entities
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {loadingCareEntities ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Paper
                      sx={{
                        maxHeight: 'calc(100vh - 300px)',
                        overflow: 'auto',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {careEntities
                          .filter((entity) =>
                            entity.name.toLowerCase().includes(careEntitySearch.toLowerCase())
                          )
                          .map((entity) => (
                            <li
                              key={entity._id}
                              draggable
                              onDragStart={(event) => handleDragStart(event, entity, 'careEntity')}
                              style={{
                                cursor: 'grab',
                                marginBottom: '8px',
                                padding: '8px',
                                borderRadius: '4px',
                                backgroundColor: '#e8f5e9',
                              }}
                            >
                              {entity.name}
                            </li>
                          ))}
                      </ul>
                    </Paper>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Right Column: Visualization */}
            <Grid item xs={12} md={9}>
              <Box sx={{ position: 'relative' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ position: 'absolute', right: 16, top: 16, zIndex: 1 }}
                  onClick={clearVisualization}
                  startIcon={<ClearAllIcon />}
                  aria-label="Clear Visualization"
                >
                  Clear Visualization
                </Button>
                <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                  Visualization Area
                </Typography>
                {loadingVisualization && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 2,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Paper
                  sx={{
                    width: '100%',
                    height: '70vh',
                    position: 'relative',
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  <svg
                    id="visualization-area"
                    width="100%"
                    height="100%"
                    onDrop={handleDrop}
                    onDragOver={(event) => event.preventDefault()}
                    style={{ cursor: 'grab' }}
                  ></svg>
                </Paper>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ position: 'absolute', right: 16, bottom: 16 }}
                  onClick={downloadVisualization}
                  aria-label="Download Visualization"
                >
                  Download
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* Error Snackbar */}
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedCareVisualizerScreen = () => (
  <ErrorBoundary>
    <CareVisualizerScreen />
  </ErrorBoundary>
);

export default WrappedCareVisualizerScreen;