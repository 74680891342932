// src/screens/StaticCarePlans.jsx

import React, { useEffect, useState } from 'react';
import {
  Box, Typography, TextField, Button, FormControl, FormControlLabel,
  Checkbox, MenuItem, IconButton, Slider, FormLabel, RadioGroup, Radio,
  InputLabel, Select
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import ErrorBoundary from '../components/ErrorBoundary';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const StaticCarePlans = ({ clientID, templateID }) => {
  const [carePlanTemplate, setCarePlanTemplate] = useState({ fields: [] });
  const [activeFields, setActiveFields] = useState([]);
  const [archivedFields, setArchivedFields] = useState([]);
  const [carePlanData, setCarePlanData] = useState({
    data: [],
    clientID: clientID,
  });
  const [initialData, setInitialData] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCarePlan = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('userToken');

        // Fetch Care Plan Template
        const templateResponse = await axios.get(`${API_BASE_URL}/api/careplantemplates/${templateID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (templateResponse.data && templateResponse.data.fields) {
          setCarePlanTemplate(templateResponse.data);
          const active = templateResponse.data.fields.filter(field => field.state !== 'Archived');
          const archived = templateResponse.data.fields.filter(field => field.state === 'Archived');
          setActiveFields(active);
          setArchivedFields(archived);
        } else {
          setCarePlanTemplate({ fields: [] });
          setActiveFields([]);
          setArchivedFields([]);
        }

        // Fetch Care Plan Data
        const dataResponse = await axios.get(`${API_BASE_URL}/api/careplandata/search`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            clientID: clientID,
            templateID: templateID,
          },
        });

        if (dataResponse.data && Array.isArray(dataResponse.data.data)) {
          setCarePlanData({
            ...dataResponse.data,
            clientID: clientID,
          });
          setInitialData(dataResponse.data);
          setCreatedAt(dataResponse.data.createdAt);
          setLastUpdate(dataResponse.data.updatedAt);
        } else {
          setCarePlanData({
            data: [],
            clientID: clientID,
          });
          setInitialData(null);
        }
      } catch (error) {
        console.error('Error fetching care plan data:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to load care plan data. Please try again later.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCarePlan();
  }, [clientID, templateID]);

  const handleSave = async () => {
    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');

      if (!carePlanData._id) {
        Swal.fire({
          title: 'Error',
          text: 'Cannot update care plan data. Please try again.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
        return;
      }

      await axios.put(
        `${API_BASE_URL}/api/careplandata/${carePlanData._id}`,
        {
          ...carePlanData,
          clientID: clientID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLastUpdate(new Date());
      setInitialData(carePlanData);

      Swal.fire({
        title: 'Success',
        text: 'Care plan updated successfully!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while updating the care plan.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleFieldChange = (fieldID, value) => {
    setCarePlanData((prevState) => {
      const updatedData = prevState.data.map((item) =>
        item.fieldID === fieldID ? { ...item, value } : item
      );

      if (!updatedData.some((item) => item.fieldID === fieldID)) {
        updatedData.push({ fieldID, value });
      }

      return { ...prevState, data: updatedData, clientID: clientID };
    });
  };

  const handleBackClick = () => {
    if (JSON.stringify(initialData) === JSON.stringify(carePlanData)) {
      navigate(-1);
    } else {
      Swal.fire({
        title: 'Unsaved Changes',
        text: 'You have unsaved changes. Would you like to save them before leaving?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: 'Discard',
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
        },
        ...customAlertStyles.sweetAlert,
      }).then((result) => {
        if (result.isConfirmed) {
          handleSave();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          navigate(-1);
        }
      });
    }
  };

  const renderField = (field, index, isArchived = false) => {
    const fieldValue =
      carePlanData.data ? carePlanData.data.find((item) => item.fieldID === field._id.toString())?.value || '' : '';

    const isEditable = !isArchived;

    switch (field.type) {
      case 'header':
        return (
          <Typography key={index} variant="h5" sx={{ marginTop: 2 }} aria-label={`Header ${field.label}`}>
            {field.label}
          </Typography>
        );
      case 'bodyText':
        return (
          <Typography key={index} variant="body1" sx={{ marginTop: 1 }} aria-label={`Body text ${field.label}`}>
            {field.label}
          </Typography>
        );
      case 'text':
      case 'number':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(field._id.toString(), e.target.value)}
            fullWidth
            margin="normal"
            type={field.type === 'number' ? 'number' : 'text'}
            aria-label={`${field.label} input`}
            InputProps={{
              readOnly: isArchived,
            }}
          />
        );
      case 'textarea':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(field._id.toString(), e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={field.numLines || 4}
            aria-label={`${field.label} textarea`}
            InputProps={{
              readOnly: isArchived,
            }}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={!!fieldValue}
                onChange={(e) => handleFieldChange(field._id.toString(), e.target.checked)}
                aria-label={`${field.label} checkbox`}
                disabled={isArchived}
              />
            }
            label={field.label}
          />
        );
      case 'date':
        return (
          <TextField
            key={index}
            label={field.label}
            type="date"
            value={fieldValue}
            onChange={(e) => handleFieldChange(field._id.toString(), e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            aria-label={`${field.label} date picker`}
            InputProps={{
              readOnly: isArchived,
            }}
          />
        );
      case 'time':
        return (
          <TextField
            key={index}
            label={field.label}
            type="time"
            value={fieldValue}
            onChange={(e) => handleFieldChange(field._id.toString(), e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            aria-label={`${field.label} time picker`}
            InputProps={{
              readOnly: isArchived,
            }}
          />
        );
      case 'select':
        const selectOptions = field.masterList?.options || [];
        return (
          <FormControl key={index} fullWidth margin="normal" disabled={isArchived}>
            <InputLabel>{field.label}</InputLabel>
            <Select
              value={fieldValue}
              onChange={(e) => handleFieldChange(field._id.toString(), e.target.value)}
              aria-label={`${field.label} select`}
              disabled={isArchived}
            >
              {selectOptions.length > 0 ? (
                selectOptions.map((option, idx) => (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No options available
                </MenuItem>
              )}
            </Select>
          </FormControl>
        );
      case 'radio':
        const radioOptions = field.masterList?.options || [];
        return (
          <FormControl
            key={index}
            component="fieldset"
            margin="normal"
            aria-label={`${field.label} radio group`}
            disabled={isArchived}
          >
            <FormLabel component="legend">{field.label}</FormLabel>
            <RadioGroup
              value={fieldValue}
              onChange={(e) => handleFieldChange(field._id.toString(), e.target.value)}
            >
              {radioOptions.length > 0 ? (
                radioOptions.map((option, idx) => (
                  <FormControlLabel
                    key={idx}
                    value={option.value}
                    control={<Radio disabled={isArchived} />}
                    label={option.label}
                  />
                ))
              ) : (
                <Typography>No options available</Typography>
              )}
            </RadioGroup>
          </FormControl>
        );
      case 'slider':
        return (
          <Box key={index} sx={{ marginBottom: 2 }} aria-label={`${field.label} slider`}>
            <Typography gutterBottom>{field.label}</Typography>
            <Slider
              value={fieldValue || 0}
              onChange={(e, newValue) => handleFieldChange(field._id.toString(), newValue)}
              aria-labelledby="continuous-slider"
              disabled={isArchived}
            />
          </Box>
        );
      case 'nested':
        const customTable = field.customTable;
        return (
          <Box key={index}>
            <Typography variant="h6" aria-label={`Nested ${field.label}`}>
              {field.label}
            </Typography>
            {customTable && customTable.fields.length > 0 ? (
              customTable.fields.map((nestedField, idx) => (
                <Box key={idx} sx={{ marginBottom: '8px' }}>
                  {renderNestedField(nestedField, carePlanData.data.find(item => item.fieldID === nestedField._id.toString())?.value || '', index, isArchived)}
                </Box>
              ))
            ) : (
              <Typography>No fields available in custom table.</Typography>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  const renderNestedField = (field, entryValue, index, isArchived) => {
    const fieldValue = entryValue !== undefined ? entryValue : '';

    const isEditable = !isArchived;

    switch (field.type) {
      case 'text':
      case 'number':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(field._id.toString(), e.target.value)}
            fullWidth
            margin="normal"
            type={field.type === 'number' ? 'number' : 'text'}
            aria-label={`Nested ${field.label} input`}
            InputProps={{
              readOnly: isArchived,
            }}
            disabled={isArchived}
          />
        );
      case 'textarea':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(field._id.toString(), e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={field.numLines || 4}
            aria-label={`Nested ${field.label} textarea`}
            InputProps={{
              readOnly: isArchived,
            }}
            disabled={isArchived}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={!!fieldValue}
                onChange={(e) => handleFieldChange(field._id.toString(), e.target.checked)}
                aria-label={`Nested ${field.label} checkbox`}
                disabled={isArchived}
              />
            }
            label={field.label}
          />
        );
      default:
        return null;
    }
  };

  const handleNestedFieldChange = (fieldID, value) => {
    setCarePlanData((prevState) => {
      const updatedData = prevState.data.map((item) =>
        item.fieldID === fieldID ? { ...item, value } : item
      );

      if (!updatedData.some((item) => item.fieldID === fieldID)) {
        updatedData.push({ fieldID, value });
      }

      return { ...prevState, data: updatedData, clientID: clientID };
    });
  };

  if (loading) {
    return (
      <Typography aria-label="Loading message" sx={{ textAlign: 'center', marginTop: '20px' }}>
        Loading...
      </Typography>
    );
  }

  if (!carePlanTemplate || !Array.isArray(carePlanTemplate.fields)) {
    return <Typography aria-label="No care plan template found">No care plan template found.</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <IconButton onClick={handleBackClick} sx={{ marginRight: '10px', color: '#153243' }} aria-label="Back button">
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#153243' }}
          aria-label="Care Plan Template Description"
        >
          {carePlanTemplate.description}
        </Typography>
      </Box>
      {createdAt && (
        <Typography variant="body1" aria-label="Creation date">
          Created: {new Date(createdAt).toLocaleString()}
        </Typography>
      )}
      {lastUpdate && (
        <>
          <Typography variant="body1" aria-label="Last update">
            Last Update: {new Date(lastUpdate).toLocaleString()}
          </Typography>
          <Typography variant="body1" aria-label="Version">
            Version: {carePlanTemplate.version}
          </Typography>
        </>
      )}
      <Box sx={{ marginTop: 2 }}>
        {activeFields.map((field, index) => renderField(field, index))}
      </Box>
      {archivedFields.length > 0 && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h5" sx={{ marginBottom: 2 }} aria-label="Archived Fields Heading">
            Archived Fields
          </Typography>
          {archivedFields.map((field, index) => renderField(field, index, true))}
        </Box>
      )}
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#258EA6',
          color: '#FFFFFF',
          padding: '16px',
          borderRadius: '8px',
          marginTop: '20px',
          width: '100%',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: '#549F93',
          },
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
        onClick={handleSave}
        disabled={saving}
        aria-label="Save button"
      >
        {saving ? 'Saving...' : 'Save'}
      </Button>
    </Box>
  );
};

const WrappedStaticCarePlans = (props) => (
  <ErrorBoundary>
    <StaticCarePlans {...props} />
  </ErrorBoundary>
);

export default WrappedStaticCarePlans;