// src/screens/CustomTableManagementScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  TableSortLabel,
  TablePagination,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  Chip,
  CircularProgress,
  Grid // Imported Grid
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getUserToken } from '../utils/api'; // Assuming a utility function for getting user token
import customAlertStyles from '../styles/CustomAlertStyles';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

// Import icons directly
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { purple, red, green } from '@mui/material/colors';

const CustomTableManagementScreen = () => {
  const navigate = useNavigate();
  const { permissions = [] } = useAuth(); // Removed 'authLoading' since it's unused

  const [customTables, setCustomTables] = useState([]);
  const [tableName, setTableName] = useState('');
  const [fields, setFields] = useState([{ label: '', type: 'text', options: [] }]);
  const [openAddTable, setOpenAddTable] = useState(false);
  const [openEditFields, setOpenEditFields] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true); // Added loading state for API calls

  // Wrap hasPermission with useCallback to prevent unnecessary re-creations
  const hasPermission = useCallback(
    (action) => {
      return permissions.includes(action);
    },
    [permissions]
  );

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Fetch custom tables
  const fetchCustomTables = useCallback(async () => {
    if (!hasPermission('view_custom-table-management')) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/home');
      return;
    }

    try {
      const token = getUserToken(); // Use the utility function
      const response = await axios.get(`${API_BASE_URL}/api/customtables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Ensure response.data is an array
      if (Array.isArray(response.data)) {
        setCustomTables(response.data);
      } else {
        // Handle unexpected response structure
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Unexpected response structure from the server.',
          ...customAlertStyles.sweetAlert,
        });
        console.error('Unexpected response structure:', response.data);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch custom tables.',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error fetching custom tables:', error);
    } finally {
      setLoading(false);
    }
  }, [hasPermission, navigate, API_BASE_URL]);

  useEffect(() => {
    fetchCustomTables();
  }, [fetchCustomTables]);

  // Handle adding a new custom table
  const handleAddTable = async () => {
    if (!hasPermission('add_custom-table-management')) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Denied',
        text: 'You do not have permission to add custom tables.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!tableName.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Table Name is required.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (fields.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'At least one field is required.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (fields.some(field => !field.label.trim())) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'All fields must have a label.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const tableData = { name: tableName, fields };

    try {
      const token = getUserToken(); // Use the utility function
      const response = await axios.post(`${API_BASE_URL}/api/customtables`, tableData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      // Determine the new table object
      const newTable = response.data.customTable || response.data; // Adjust based on API response

      // Validate the new table object
      if (newTable && newTable.name && newTable._id) {
        setCustomTables(prevTables => [...prevTables, newTable]); // Functional update
        setTableName('');
        setFields([{ label: '', type: 'text', options: [] }]);
        setOpenAddTable(false);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Custom table added successfully.',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add custom table. Invalid response from server.',
          ...customAlertStyles.sweetAlert,
        });
        console.error('Invalid table object:', newTable);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.error || 'Failed to add custom table.',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error adding custom table:', error);
    }
  };

  // Handle deleting a custom table
  const handleDeleteTable = async (table) => {
    if (!hasPermission('delete_custom-table-management')) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Denied',
        text: 'You do not have permission to delete custom tables.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    Swal.fire({
      title: `Delete ${table.name}?`,
      text: "Are you sure you want to delete this custom table?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: red[500],
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      ...customAlertStyles.sweetAlert,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = getUserToken(); // Use the utility function
          await axios.delete(`${API_BASE_URL}/api/customtables/${table._id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setCustomTables(prevTables => prevTables.filter(ct => ct._id !== table._id)); // Functional update
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: `${table.name} has been deleted successfully.`,
            ...customAlertStyles.sweetAlert,
          });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Failed to delete ${table.name}.`,
            ...customAlertStyles.sweetAlert,
          });
          console.error('Error deleting custom table:', error);
        }
      }
    });
  };

  // Handle opening the add table dialog
  const handleOpenAddTable = () => {
    setTableName('');
    setFields([{ label: '', type: 'text', options: [] }]);
    setOpenAddTable(true);
  };

  // Handle opening the edit fields dialog
  const handleOpenEditFields = (table) => {
    if (!hasPermission('edit_custom-table-management')) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Denied',
        text: 'You do not have permission to edit fields.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    setSelectedTable(table);
    setTableName(table.name);
    setFields(table.fields);
    setOpenEditFields(true);
  };

  // Handle saving edited fields
  const handleSaveFields = async () => {
    if (!hasPermission('edit_custom-table-management')) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Denied',
        text: 'You do not have permission to edit fields.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!tableName.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Table Name is required.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (fields.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'At least one field is required.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (fields.some(field => !field.label.trim())) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'All fields must have a label.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const tableData = { name: tableName, fields };

    try {
      const token = getUserToken(); // Use the utility function
      const response = await axios.put(`${API_BASE_URL}/api/customtables/${selectedTable._id}`, tableData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      // Determine the updated table object
      const updatedTable = response.data.customTable || response.data; // Adjust based on API response

      // Validate the updated table object
      if (updatedTable && updatedTable.name && updatedTable._id) {
        setCustomTables(prevTables =>
          prevTables.map(ct => ct._id === selectedTable._id ? updatedTable : ct)
        ); // Functional update
        setSelectedTable(null);
        setTableName('');
        setFields([{ label: '', type: 'text', options: [] }]);
        setOpenEditFields(false);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Custom table updated successfully.',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update custom table. Invalid response from server.',
          ...customAlertStyles.sweetAlert,
        });
        console.error('Invalid table object:', updatedTable);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.error || 'Failed to save fields.',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error saving fields:', error);
    }
  };

  // Handle archiving/unarchiving a table
  const handleArchiveTable = async (table) => {
    if (!hasPermission('delete_custom-table-management')) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Denied',
        text: 'You do not have permission to archive custom tables.',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const action = table.isArchived ? 'unarchive' : 'archive';
    const actionLabel = table.isArchived ? 'Unarchive' : 'Archive';

    Swal.fire({
      title: `${actionLabel} ${table.name}?`,
      text: `Are you sure you want to ${action.toLowerCase()} this custom table?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: table.isArchived ? green[500] : red[500],
      cancelButtonColor: '#3085d6',
      confirmButtonText: `Yes, ${action.toLowerCase()} it!`,
      ...customAlertStyles.sweetAlert,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = getUserToken(); // Use the utility function
          const url = `${API_BASE_URL}/api/customtables/${action}/${table._id}`;
          const response = await axios.put(url, {}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // Determine the updated table object
          const updatedTable = response.data.customTable || response.data; // Adjust based on API response

          // Validate the updated table object
          if (updatedTable && updatedTable.name && updatedTable._id) {
            setCustomTables(prevTables =>
              prevTables.map(ct => ct._id === table._id ? updatedTable : ct)
            ); // Functional update
            Swal.fire({
              title: actionLabel,
              text: `Custom table has been ${actionLabel.toLowerCase()}d successfully.`,
              icon: 'success',
              ...customAlertStyles.sweetAlert,
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: `Failed to ${action} custom table. Invalid response from server.`,
              icon: 'error',
              ...customAlertStyles.sweetAlert,
            });
            console.error('Invalid table object:', updatedTable);
          }
        } catch (error) {
          console.error(`Error ${action}ing custom table:`, error.response?.data);
          Swal.fire({
            title: 'Error',
            text: error.response?.data?.error || `Failed to ${action} custom table.`,
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        }
      }
    });
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  // Handle sorting
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter tables based on search and status
  const filteredTables = customTables.filter(table => {
    // Defensive coding: ensure table is defined and has a name
    if (!table || !table.name) return false;

    const matchesSearch = table.name.toLowerCase().includes(search.toLowerCase());
    const matchesStatus =
      statusFilter === 'All' ||
      (statusFilter === 'Active' && !table.isArchived) ||
      (statusFilter === 'Archived' && table.isArchived);
    return matchesSearch && matchesStatus;
  });

  // Comparator functions for sorting
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (!a[orderBy] || !b[orderBy]) return 0;
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) return -1;
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) return 1;
    return 0;
  };

  const stableSort = (array, comparator) => {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
      const orderComp = comparator(a[0], b[0]);
      if (orderComp !== 0) return orderComp;
      return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
  };

  const sortedTables = stableSort(filteredTables, getComparator(order, orderBy));

  const paginatedTables = sortedTables.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <NavigationBar />
      <Box display="flex">
        <Sidebar />
        <Box flexGrow={1}>
          <Box sx={{ padding: '24px', marginTop: '64px' }}>
            <Typography component="h1" variant="h5" sx={{ marginBottom: '24px' }}>
              Custom Table Management
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', gap: 2 }}>
              <TextField
                variant="outlined"
                placeholder="Search Custom Tables"
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="Search Custom Tables Button">
                      <SearchIcon />
                    </IconButton>
                  )
                }}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenAddTable}
                disabled={!hasPermission('add_custom-table-management')}
                startIcon={<AddCircleIcon />}
              >
                Add Custom Table
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: '16px', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={statusFilter}
                  label="Status"
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Archived">Archived</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress /> {/* Now properly imported */}
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="custom tables table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'name'}
                          direction={orderBy === 'name' ? order : 'asc'}
                          onClick={() => handleSortRequest('name')}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Fields</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedTables.map((table) => (
                      <TableRow key={table._id} hover>
                        <TableCell>{table.name}</TableCell>
                        <TableCell>
                          {table.fields.slice(0, 3).map(field => field.label).join(', ')}
                          {table.fields.length > 3 && '...'}
                        </TableCell>
                        <TableCell>
                          {table.isArchived ? (
                            <Chip label="Archived" color="error" />
                          ) : (
                            <Chip label="Active" color="success" />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            sx={{ color: purple[500] }}
                            onClick={() => handleOpenEditFields(table)}
                            aria-label={`View/Edit ${table.name}`}
                            disabled={!hasPermission('edit_custom-table-management')}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            sx={{ color: table.isArchived ? green[500] : red[500] }}
                            onClick={() => handleArchiveTable(table)}
                            aria-label={`${table.isArchived ? 'Unarchive' : 'Archive'} ${table.name}`}
                            disabled={!hasPermission('delete_custom-table-management')}
                          >
                            {table.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                          </IconButton>
                          <IconButton
                            sx={{ color: red[500] }}
                            onClick={() => handleDeleteTable(table)}
                            aria-label={`Delete ${table.name}`}
                            disabled={!hasPermission('delete_custom-table-management')}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {paginatedTables.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No custom tables found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={sortedTables.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            )}

            {/* Add/Edit Custom Table Dialog */}
            <Dialog
              open={openAddTable || openEditFields}
              onClose={() => {
                setOpenAddTable(false);
                setOpenEditFields(false);
              }}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>{openAddTable ? 'Add New Custom Table' : 'Edit Custom Table'}</DialogTitle>
              <DialogContent dividers>
                <Box sx={{ padding: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Table Name"
                        value={tableName}
                        onChange={(e) => setTableName(e.target.value)}
                        error={!tableName.trim()}
                        helperText={!tableName.trim() ? 'Table Name is required' : ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Fields
                      </Typography>
                    </Grid>
                    {fields.map((field, index) => (
                      <Grid container spacing={1} key={index} alignItems="center">
                        <Grid item xs={12} sm={5}>
                          <TextField
                            required
                            fullWidth
                            label="Field Label"
                            value={field.label}
                            onChange={(e) => {
                              const newFields = [...fields];
                              newFields[index].label = e.target.value;
                              setFields(newFields);
                            }}
                            error={!field.label.trim()}
                            helperText={!field.label.trim() ? 'Label is required' : ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <FormControl fullWidth>
                            <InputLabel>Field Type</InputLabel>
                            <Select
                              value={field.type}
                              onChange={(e) => {
                                const newFields = [...fields];
                                newFields[index].type = e.target.value;
                                setFields(newFields);
                              }}
                              label="Field Type"
                            >
                              <MenuItem value="text">Text</MenuItem>
                              <MenuItem value="number">Number</MenuItem>
                              <MenuItem value="date">Date</MenuItem>
                              <MenuItem value="checkbox">Checkbox</MenuItem>
                              <MenuItem value="textarea">Textarea</MenuItem>
                              <MenuItem value="select">Select</MenuItem>
                              <MenuItem value="radio">Radio</MenuItem>
                              <MenuItem value="fileUpload">File Upload</MenuItem>
                              <MenuItem value="slider">Slider</MenuItem>
                              <MenuItem value="nested">Nested</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <IconButton
                            color="error"
                            onClick={() => {
                              const newFields = fields.filter((_field, idx) => idx !== index);
                              setFields(newFields);
                            }}
                            aria-label={`Remove Field ${index + 1}`}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                        onClick={() => setFields([...fields, { label: '', type: 'text', options: [] }])}
                      >
                        Add Another Field
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {
                  setOpenAddTable(false);
                  setOpenEditFields(false);
                }}>
                  Cancel
                </Button>
                <Button
                  onClick={openAddTable ? handleAddTable : handleSaveFields}
                  disabled={
                    !tableName.trim() ||
                    fields.length === 0 ||
                    fields.some(field => !field.label.trim())
                  }
                  variant="contained"
                  color="primary"
                >
                  {openAddTable ? 'Add Custom Table' : 'Save Changes'}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedCustomTableManagementScreen = () => (
  <ErrorBoundary>
    <CustomTableManagementScreen />
  </ErrorBoundary>
);

export default WrappedCustomTableManagementScreen;