// src/screens/ClientManagementScreen.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import customAlertStyles from '../styles/CustomAlertStyles';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';

const ClientManagementScreen = () => {
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openAddClient, setOpenAddClient] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [orgClientID, setOrgClientID] = useState('');
  const [status, setStatus] = useState('Active');
  const [file, setFile] = useState(null);

  // Pagination and Sorting
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { permissions = [], loading: authLoading } = useAuth();

  // Permission checks
  const hasViewPermission = permissions.includes('view_clients');
  const hasAddPermission = permissions.includes('add_clients');
  const hasEditPermission = permissions.includes('edit_clients');
  const hasDeletePermission = permissions.includes('delete_clients');

  const navigate = useNavigate();

  useEffect(() => {
    if (!authLoading && hasViewPermission) {
      fetchClients();
    } else if (!authLoading && !hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view clients.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoading, hasViewPermission]);

  const fetchClients = async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClients(response.data);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch clients. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  const handleAddClient = async () => {
    if (!hasAddPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to add clients.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!firstName || !lastName) {
      Swal.fire({
        title: 'Error',
        text: 'First Name and Last Name are required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/clients`,
        { firstName, lastName, orgClientID, status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        title: 'Success',
        text: 'Client added successfully!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
      setOpenAddClient(false);
      setFirstName('');
      setLastName('');
      setOrgClientID('');
      setStatus('Active');
      fetchClients();
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to add client. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  const handleEditClient = (clientId) => {
    if (!hasEditPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit clients.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    // Navigate to the client profile page
    navigate(`/client-profile/${clientId}`);
  };

  const handleDeactivateClient = async (clientId) => {
    if (!hasDeletePermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to deactivate clients.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to deactivate this client?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, deactivate it!',
      cancelButtonText: 'No, keep it active',
      ...customAlertStyles.sweetAlert,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem('userToken');
          await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/api/clients/${clientId}`,
            { status: 'Inactive' },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          Swal.fire({
            title: 'Deactivated!',
            text: 'Client has been deactivated.',
            icon: 'success',
            ...customAlertStyles.sweetAlert,
          });
          fetchClients();
        } catch (error) {
          Swal.fire({
            title: 'Error!',
            text: 'Failed to deactivate client. Please try again.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        }
      }
    });
  };

  const handleImport = async () => {
    if (!hasAddPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to import clients.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!file) {
      Swal.fire({
        title: 'Error',
        text: 'Please select a file to import.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('userToken');
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/clients/import`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      Swal.fire({
        title: 'Success',
        text: 'Clients imported successfully!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
      setOpenImport(false);
      setFile(null);
      fetchClients();
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to import clients. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Search and Filter
  const filteredClients = clients.filter((client) =>
    `${client.firstName} ${client.lastName} ${client.orgClientID}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Sorting
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedClients = filteredClients.sort((a, b) => {
    const aValue = a[orderBy] || '';
    const bValue = b[orderBy] || '';
    if (order === 'asc') {
      return aValue.toString().localeCompare(bValue.toString());
    } else {
      return bValue.toString().localeCompare(aValue.toString());
    }
  });

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedClients = sortedClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const styles = {
    input: {
      marginBottom: '16px',
    },
  };

  if (authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (!hasViewPermission) {
    return null;
  }

  return (
    <ErrorBoundary>
      <>
        <Navbar />
        <Box sx={{ display: 'flex' }}>
          <Sidebar />
          <Box flexGrow={1}>
            <Box sx={{ padding: '24px', marginTop: '64px' }}>
              <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
                Client Management
              </Typography>
              <Box sx={{ display: 'flex', marginBottom: 2 }}>
                <TextField
                  label="Search Clients"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  fullWidth
                  aria-label="Search Clients"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenAddClient(true)}
                  sx={{ marginLeft: 2 }}
                  disabled={!hasAddPermission}
                  aria-label="Add Client"
                >
                  Add Client
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenImport(true)}
                  sx={{ marginLeft: 2 }}
                  disabled={!hasAddPermission}
                  aria-label="Import Clients"
                >
                  Import Clients
                </Button>
              </Box>

              <TableContainer component={Paper}>
                <Table aria-label="clients table">
                  <TableHead>
                    <TableRow>
                      <TableCell sortDirection={orderBy === 'firstName' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'firstName'}
                          direction={orderBy === 'firstName' ? order : 'asc'}
                          onClick={() => handleSortRequest('firstName')}
                        >
                          First Name
                          {orderBy === 'firstName' ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sortDirection={orderBy === 'lastName' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'lastName'}
                          direction={orderBy === 'lastName' ? order : 'asc'}
                          onClick={() => handleSortRequest('lastName')}
                        >
                          Last Name
                          {orderBy === 'lastName' ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Org Client ID</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedClients.map((client) => (
                      <TableRow key={client._id}>
                        <TableCell>{client.firstName}</TableCell>
                        <TableCell>{client.lastName}</TableCell>
                        <TableCell>{client.orgClientID}</TableCell>
                        <TableCell>{client.status}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleEditClient(client._id)}
                            disabled={!hasEditPermission}
                            aria-label={`Edit ${client.firstName} ${client.lastName}`}
                            sx={{
                              backgroundColor: hasEditPermission ? undefined : '#ccc',
                              cursor: hasEditPermission ? 'pointer' : 'not-allowed',
                              marginRight: '8px',
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDeactivateClient(client._id)}
                            disabled={!hasDeletePermission}
                            aria-label={`Deactivate ${client.firstName} ${client.lastName}`}
                            sx={{
                              backgroundColor: hasDeletePermission ? undefined : '#ccc',
                              cursor: hasDeletePermission ? 'pointer' : 'not-allowed',
                            }}
                          >
                            Deactivate
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={sortedClients.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>

              {/* Add Client Dialog */}
              <Dialog
                open={openAddClient}
                onClose={() => setOpenAddClient(false)}
                aria-labelledby="add-client-dialog-title"
              >
                <DialogTitle id="add-client-dialog-title">Add New Client</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    sx={styles.input}
                    aria-label="First Name"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    sx={styles.input}
                    aria-label="Last Name"
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Org Client ID"
                    value={orgClientID}
                    onChange={(e) => setOrgClientID(e.target.value)}
                    sx={styles.input}
                    aria-label="Org Client ID"
                  />
                  <InputLabel>Status</InputLabel>
                  <Select
                    fullWidth
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    sx={styles.input}
                    aria-label="Status"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Away">Away</MenuItem>
                  </Select>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenAddClient(false)} aria-label="Cancel Adding Client">
                    Cancel
                  </Button>
                  <Button onClick={handleAddClient} aria-label="Confirm Adding Client">
                    Add Client
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Import Clients Dialog */}
              <Dialog
                open={openImport}
                onClose={() => setOpenImport(false)}
                aria-labelledby="import-clients-dialog-title"
              >
                <DialogTitle id="import-clients-dialog-title">Import Clients</DialogTitle>
                <DialogContent>
                  <InputLabel htmlFor="file-upload" aria-label="Choose File">
                    Choose File
                  </InputLabel>
                  <input
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ margin: '16px 0' }}
                    aria-label="File Upload"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenImport(false)} aria-label="Cancel Importing Clients">
                    Cancel
                  </Button>
                  <Button onClick={handleImport} aria-label="Confirm Importing Clients">
                    Import
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
        </Box>
      </>
    </ErrorBoundary>
  );
};

export default ClientManagementScreen;