import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  Button, TextField, Typography, Container, Box, CircularProgress,
} from '@mui/material';
import logo from '../shared/logo.png';
import { styles } from '../styles/styles';
import customAlertStyles from '../styles/CustomAlertStyles';
import ErrorBoundary from '../components/ErrorBoundary';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const ChooseOrganization = () => {
  const [organization, setOrganization] = useState('');
  const [email, setEmail] = useState('');
  const [loadingOrganization, setLoadingOrganization] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [organizationError, setOrganizationError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [organizationChecked, setOrganizationChecked] = useState(false);
  const [organizationSuccessMessage, setOrganizationSuccessMessage] = useState(false);

  const navigate = useNavigate();

  const handleCheckOrganization = async () => {
    if (!organization.trim()) {
      setOrganizationError('Organization is required');
      Swal.fire({
        title: 'Error',
        text: 'Organization is required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    setLoadingOrganization(true);
    setOrganizationError('');
    setEmailError('');
    setOrganizationSuccessMessage(false);

    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/organization/${encodeURIComponent(organization.trim())}`);
      if (response.status === 200 && response.data.organization) {
        setOrganizationChecked(true);
        setOrganizationError('');
        setEmail('');
        setEmailError('');
        setOrganizationSuccessMessage(true);
        Swal.fire({
          title: 'Organization Found',
          text: 'Please enter your email.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Organization check error:', error);
      setOrganizationError('Organization not found. Check your spelling or contact your Administrative Department.');
      setOrganizationSuccessMessage(false);
      Swal.fire({
        title: 'Error',
        text: 'Organization not found. Check your spelling or contact your Administrative Department.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoadingOrganization(false);
    }
  };

  const handleCheckEmail = async () => {
    if (!email.trim()) {
      setEmailError('Email is required');
      Swal.fire({
        title: 'Error',
        text: 'Email is required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      setEmailError('Please enter a valid email address');
      Swal.fire({
        title: 'Error',
        text: 'Please enter a valid email address.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setLoadingEmail(true);
    setEmailError('');

    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/role`, {
        params: {
          email: email.trim(),
          organization: organization.trim(),
        },
      });

      if (response.status === 200) {
        setEmailError('');
        navigate('/signup-completion', { state: { organization: organization.trim(), email: email.trim() } });
        Swal.fire({
          title: 'Email Verified',
          text: 'Redirecting to sign up completion screen.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        setEmailError('Email not found in this organization. Contact your Administrative Department.');
        Swal.fire({
          title: 'Error',
          text: 'Email not found in this organization. Contact your Administrative Department.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Email check error:', error);
      setEmailError('Email not found in this organization. Contact your Administrative Department.');
      Swal.fire({
        title: 'Error',
        text: 'Email not found in this organization. Contact your Administrative Department.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoadingEmail(false);
    }
  };

  return (
    <ErrorBoundary>
      <Container component="main" maxWidth="xs" sx={styles.loginContainer}>
        <Box sx={styles.formContainer}>
          <img src={logo} style={styles.logo} alt="Logo" aria-label="Application Logo" />
          <Typography component="h1" variant="h5" aria-label="Create Your Account Header" sx={styles.primaryHeader}>
            Create Your Account
          </Typography>
          <Typography component="h2" variant="subtitle1" aria-label="Your organization subheader">
            Your organization just made one big leap in the right direction
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="organization"
            label="Organization"
            name="organization"
            autoComplete="organization"
            autoFocus
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            aria-label="Organization Input"
            sx={styles.input}
            error={!!organizationError}
            helperText={organizationError}
          />
          <Box sx={styles.buttonContainer}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={styles.button}
              onClick={handleCheckOrganization}
              disabled={loadingOrganization}
              aria-label="Check Organization Button"
            >
              {loadingOrganization ? <CircularProgress size={24} /> : 'Check for Organization'}
            </Button>
          </Box>
          {organizationSuccessMessage && (
            <Typography component="p" variant="body2" color="success" sx={{ marginTop: 1 }} aria-label="Organization Success Message">
              Organization found. Please enter your email (Tip: We're probably looking for your work one).
            </Typography>
          )}
          {organizationChecked && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="Email Input"
                sx={styles.input}
                error={!!emailError}
                helperText={emailError}
              />
              <Box sx={styles.buttonContainer}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={styles.button}
                  onClick={handleCheckEmail}
                  disabled={loadingEmail}
                  aria-label="Next Button"
                >
                  {loadingEmail ? <CircularProgress size={24} /> : 'Next'}
                </Button>
              </Box>
            </>
          )}
          <Box sx={styles.linkContainer}>
            <Button
              type="button"
              onClick={() => navigate('/login')}
              aria-label="Back to Login Link"
              sx={styles.link}
            >
              Back to Login
            </Button>
          </Box>
        </Box>
      </Container>
    </ErrorBoundary>
  );
};

export default ChooseOrganization;