import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Box, 
  Menu, 
  MenuItem, 
  Fade 
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { NavLink } from 'react-router-dom';
import logo from '../shared/logo.png';
import { useAuth } from '../context/AuthContext'; // Import useAuth hook to get logout function
import axios from 'axios';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles'; // Import custom alert styles

const Navbar = () => {
  const { logout } = useAuth(); // Get logout function from AuthContext
  const [permissions, setPermissions] = useState([]); // User permissions state

  // States for Account dropdown
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const isAccountMenuOpen = Boolean(accountAnchorEl);

  // States for Notifications dropdown
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const isNotificationsMenuOpen = Boolean(notificationsAnchorEl);

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  useEffect(() => {
    const fetchPermissions = async () => {
      const token = localStorage.getItem('userToken');
      if (!token) {
        console.log('No token found. Logging out user.');
        logout(); // Log out if no token is available
        return;
      }

      try {
        const response = await axios.get(`${API_BASE_URL}/api/roles/user/permissions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPermissions(response.data.permissions || []);
      } catch (error) {
        console.error('Error fetching permissions:', error);
        if (error.response && error.response.status === 401) {
          console.log('Token expired or unauthorized. Logging out user.');
          logout(); // Log out if token is expired or invalid
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Failed to fetch user permissions. Please try again later.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        }
      }
    };

    fetchPermissions(); // Fetch user permissions on component mount
  }, [logout, API_BASE_URL]);

  // Check if the user has "view_profile" permission
  const hasViewPermission = (resource) => {
    return permissions.includes(`view_${resource}`);
  };

  // Handlers for Account Menu
  const handleAccountMouseEnter = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountMouseLeave = () => {
    setAccountAnchorEl(null);
  };

  // Handlers for Notifications Menu
  const handleNotificationsMouseEnter = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleNotificationsMouseLeave = () => {
    setNotificationsAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 1201,
        background: 'linear-gradient(to right, white, #549F93)', // Gradient background
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box
          component="img"
          src={logo} // Use the imported logo
          alt="CarePlan AI"
          sx={{ height: 50 }} // Adjust size
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Notifications Dropdown */}
          <Box
            onMouseEnter={handleNotificationsMouseEnter} // Open notifications menu on hover
            onMouseLeave={handleNotificationsMouseLeave} // Close notifications menu when hover is removed
            sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          >
            <IconButton edge="end" color="inherit" aria-label="notifications">
              <NotificationsIcon />
            </IconButton>

            {/* Dropdown menu for Notifications */}
            <Menu
              anchorEl={notificationsAnchorEl}
              open={isNotificationsMenuOpen}
              onClose={handleNotificationsMouseLeave}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              MenuListProps={{
                onMouseEnter: handleNotificationsMouseEnter,
                onMouseLeave: handleNotificationsMouseLeave,
              }}
              PaperProps={{
                elevation: 3,
                sx: { mt: 1.5, minWidth: 200 },
              }}
            >
              <MenuItem disabled>No new notifications</MenuItem>
            </Menu>
          </Box>

          {/* Account Dropdown */}
          <Box
            onMouseEnter={handleAccountMouseEnter} // Open account menu on hover
            onMouseLeave={handleAccountMouseLeave} // Close account menu when hover is removed
            sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          >
            <IconButton edge="end" color="inherit" aria-label="account">
              <AccountCircle />
            </IconButton>

            {/* Dropdown menu for Profile and Password and Security */}
            <Menu
              anchorEl={accountAnchorEl}
              open={isAccountMenuOpen}
              onClose={handleAccountMouseLeave}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              MenuListProps={{
                onMouseEnter: handleAccountMouseEnter,
                onMouseLeave: handleAccountMouseLeave,
              }}
              PaperProps={{
                elevation: 3,
                sx: { mt: 1.5, minWidth: 200 },
              }}
            >
              {hasViewPermission('profile') && (
                <MenuItem
                  component={NavLink}
                  to="/profile" // Direct to profile screen
                  onClick={handleAccountMouseLeave}
                >
                  Profile
                </MenuItem>
              )}
              <MenuItem
                component={NavLink}
                to="/password-and-security" // Direct to Password and Security screen
                onClick={handleAccountMouseLeave}
              >
                Password and Security
              </MenuItem>
            </Menu>
          </Box>

          {/* Logout Button */}
          <IconButton edge="end" color="inherit" aria-label="logout" onClick={logout}>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;