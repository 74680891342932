// src/screens/AssignCareEntityScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Save from '@mui/icons-material/Save';
import Edit from '@mui/icons-material/Edit';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';
import customAlertStyles from '../styles/CustomAlertStyles';
import './SweetAlertCustom.css'; // Ensure this CSS is compatible with the updated components

// Initialize SweetAlert with React Content
const MySwal = withReactContent(Swal);

const AssignCareEntityScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth(); // Fetching permissions from AuthContext

  // Define permission flags
  const hasViewPermission = permissions.includes('view_assign-care-entity');
  const hasEditPermission = permissions.includes('edit_assign-care-entity');
  const hasDeletePermission = permissions.includes('delete_assign-care-entity');

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // State variables
  const [careEntity, setCareEntity] = useState(null);
  const [staff, setStaff] = useState([]);
  const [clients, setClients] = useState([]);
  const [assignedStaff, setAssignedStaff] = useState([]);
  const [assignedClients, setAssignedClients] = useState([]);
  const [staffSearch, setStaffSearch] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // Fetch care entity
  const fetchCareEntity = useCallback(async () => {
    if (!hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      }).then(() => {
        navigate('/home');
      });
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careentities/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCareEntity(response.data || {});
      setAssignedStaff(response.data.assignedStaff || []);
      setAssignedClients(response.data.assignedClients || []);
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch care entity data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  }, [API_BASE_URL, id, hasViewPermission, navigate]);

  // Fetch staff without pagination
  const fetchStaff = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/users?role=staff`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStaff(response.data || []);
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch staff data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  // Fetch clients without pagination
  const fetchClients = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClients(response.data || []);
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch client data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  // Fetch data on component load
  useEffect(() => {
    fetchCareEntity();
    fetchStaff();
    fetchClients();
  }, [fetchCareEntity, fetchStaff, fetchClients]);

  // Handle before unload for unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  // Handle assign operation
  const handleAssign = async () => {
    if (!hasEditPermission) {
      MySwal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to edit care entities.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      const staffIds = assignedStaff.map((person) => person._id);
      const clientIDs = assignedClients.map((person) => person._id);

      const payload = { staff: staffIds, clients: clientIDs };

      await axios.post(`${API_BASE_URL}/api/careentities/${id}/assign`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setUnsavedChanges(false);
      MySwal.fire({
        title: 'Success',
        text: 'Care Assignments Updated!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Failed to update care assignments.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // Handle drag start
  const handleDragStart = (event, type) => {
    const draggedItems = type === 'staff' ? selectedStaff : selectedClients;
    const draggedIds = draggedItems.map((item) => item._id);
    event.dataTransfer.setData('draggedItems', JSON.stringify(draggedIds));
    event.dataTransfer.effectAllowed = 'move';
  };

  // Handle drop
  const handleDrop = async (event, type) => {
    event.preventDefault();
    const draggedIds = JSON.parse(event.dataTransfer.getData('draggedItems'));
    if (type === 'staff') {
      const draggedStaff = staff.filter((person) => draggedIds.includes(person._id));
      setAssignedStaff((prev) => [...prev, ...draggedStaff]);
      setStaff((prev) => prev.filter((person) => !draggedIds.includes(person._id)));
    } else if (type === 'client') {
      const draggedClients = clients.filter((person) => draggedIds.includes(person._id));
      setAssignedClients((prev) => [...prev, ...draggedClients]);
      setClients((prev) => prev.filter((person) => !draggedIds.includes(person._id)));
    }
    setUnsavedChanges(true);
  };

  // Handle drag over
  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  // Handle select staff
  const handleSelectStaff = (person) => {
    setSelectedStaff((prevSelected) =>
      prevSelected.includes(person)
        ? prevSelected.filter((p) => p !== person)
        : [...prevSelected, person]
    );
  };

  // Handle select client
  const handleSelectClient = (person) => {
    setSelectedClients((prevSelected) =>
      prevSelected.includes(person)
        ? prevSelected.filter((p) => p !== person)
        : [...prevSelected, person]
    );
  };

  // Handle remove staff
  const handleRemoveStaff = (personId) => {
    if (!hasDeletePermission) {
      MySwal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to delete assignments.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const person = assignedStaff.find((p) => p._id === personId);
    setAssignedStaff((prev) => prev.filter((person) => person._id !== personId));
    setStaff((prev) => [...prev, person]);
    setUnsavedChanges(true);
  };

  // Handle remove client
  const handleRemoveClient = (personId) => {
    if (!hasDeletePermission) {
      MySwal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to delete assignments.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const person = assignedClients.find((p) => p._id === personId);
    setAssignedClients((prev) => prev.filter((person) => person._id !== personId));
    setClients((prev) => [...prev, person]);
    setUnsavedChanges(true);
  };

  // Filter staff based on search
  const filteredStaff = staff.filter((person) =>
    `${person.firstName} ${person.lastName}`.toLowerCase().includes(staffSearch.toLowerCase())
  );

  // Filter clients based on search
  const filteredClients = clients.filter((person) =>
    `${person.firstName} ${person.lastName}`.toLowerCase().includes(clientSearch.toLowerCase())
  );

  // Handle back navigation
  const handleBackClick = () => {
    if (unsavedChanges) {
      MySwal.fire({
        title: 'Unsaved Changes',
        text: 'You have unsaved changes. Are you sure you want to leave?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, leave',
        cancelButtonText: 'No, stay',
        ...customAlertStyles.sweetAlert,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
    } else {
      navigate(-1);
    }
  };

  // Loading indicator
  if (loading || authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
        aria-label="Loading Indicator"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Access denied message
  if (!hasViewPermission) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error" aria-label="Access Denied Message">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <NavigationBar />
      <Box display="flex">
        <Sidebar />
        <Box flexGrow={1}>
          <Box sx={{ padding: '24px', marginTop: '64px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '24px',
              }}
              aria-label="Header Section"
            >
              <IconButton onClick={handleBackClick} aria-label="Go back">
                <ArrowBackIcon />
              </IconButton>
              {careEntity && (
                <Typography
                  component="h1"
                  variant="h5"
                  aria-label={`Assign Staff and Clients to ${careEntity.name}`}
                >
                  Assign Staff and Clients to {careEntity.name}
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleAssign}
                aria-label="Save Assignments"
                disabled={!hasEditPermission || saving} // Disable if no edit permission or saving in progress
                startIcon={saving ? <CircularProgress size={20} /> : <Save />}
              >
                {saving ? 'Saving...' : 'Save Assignments'}
              </Button>
            </Box>

            {/* Summary Section */}
            <Box display="flex" justifyContent="space-between" marginBottom={2}>
              <Typography variant="h6" aria-label="Staff Summary">
                Assigned Staff: {assignedStaff.length}
              </Typography>
              <Typography variant="h6" aria-label="Client Summary">
                Assigned Clients: {assignedClients.length}
              </Typography>
            </Box>

            <Grid container spacing={2}>
              {/* Staff Assignment */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" aria-label="Staff">
                  Staff
                </Typography>
                <Autocomplete
                  freeSolo
                  options={staff.map((person) => `${person.firstName} ${person.lastName}`)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Staff"
                      variant="outlined"
                      fullWidth
                      aria-label="Search Staff"
                    />
                  )}
                  onInputChange={(e, value) => setStaffSearch(value)}
                  aria-label="Staff Autocomplete"
                />
                <Typography
                  variant="body2"
                  sx={{ marginTop: '8px' }}
                  aria-label="Drag Staff Instructions"
                >
                  Drag selected staff and drop below to assign.
                </Typography>
                <Paper
                  sx={{ maxHeight: 400, overflow: 'auto', marginTop: '8px' }}
                  aria-label="Unassigned Staff List Container"
                >
                  <List dense sx={{ maxHeight: 300, overflowY: 'auto' }} aria-label="Unassigned Staff List">
                    {filteredStaff.map((person) => (
                      <ListItem
                        key={person._id}
                        draggable
                        onDragStart={(event) => handleDragStart(event, 'staff')}
                        aria-label={`Unassigned Staff: ${person.firstName} ${person.lastName}`}
                      >
                        <Checkbox
                          checked={selectedStaff.includes(person)}
                          onChange={() => handleSelectStaff(person)}
                          aria-label={`Select ${person.firstName} ${person.lastName}`}
                        />
                        <ListItemText primary={`${person.firstName} ${person.lastName}`} />
                      </ListItem>
                    ))}
                    {filteredStaff.length === 0 && (
                      <ListItem>
                        <ListItemText primary="No staff found." />
                      </ListItem>
                    )}
                  </List>
                </Paper>

                <Typography
                  variant="h6"
                  sx={{ marginTop: '24px' }}
                  aria-label="Assigned Staff Header"
                >
                  Assigned Staff
                </Typography>
                <Paper
                  sx={{ maxHeight: 400, overflow: 'auto', marginTop: '8px' }}
                  onDrop={(event) => handleDrop(event, 'staff')}
                  onDragOver={handleDragOver}
                  aria-label="Assigned Staff Drop Zone"
                >
                  <List dense sx={{ maxHeight: 300, overflowY: 'auto' }} aria-label="Assigned Staff List">
                    {assignedStaff.map((person) => (
                      <ListItem key={person._id}>
                        <ListItemText primary={`${person.firstName} ${person.lastName}`} />
                        <IconButton
                          edge="end"
                          aria-label={`Remove ${person.firstName} ${person.lastName}`}
                          onClick={() => handleRemoveStaff(person._id)}
                          disabled={!hasDeletePermission}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                    {assignedStaff.length === 0 && (
                      <ListItem>
                        <ListItemText primary="No staff assigned." />
                      </ListItem>
                    )}
                  </List>
                </Paper>
              </Grid>

              {/* Client Assignment */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" aria-label="Clients">
                  Clients
                </Typography>
                <Autocomplete
                  freeSolo
                  options={clients.map((person) => `${person.firstName} ${person.lastName}`)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Clients"
                      variant="outlined"
                      fullWidth
                      aria-label="Search Clients"
                    />
                  )}
                  onInputChange={(e, value) => setClientSearch(value)}
                  aria-label="Clients Autocomplete"
                />
                <Typography
                  variant="body2"
                  sx={{ marginTop: '8px' }}
                  aria-label="Drag Clients Instructions"
                >
                  Drag selected clients and drop below to assign.
                </Typography>
                <Paper
                  sx={{ maxHeight: 400, overflow: 'auto', marginTop: '8px' }}
                  aria-label="Unassigned Clients List Container"
                >
                  <List dense sx={{ maxHeight: 300, overflowY: 'auto' }} aria-label="Unassigned Clients List">
                    {filteredClients.map((person) => (
                      <ListItem
                        key={person._id}
                        draggable
                        onDragStart={(event) => handleDragStart(event, 'client')}
                        aria-label={`Unassigned Client: ${person.firstName} ${person.lastName}`}
                      >
                        <Checkbox
                          checked={selectedClients.includes(person)}
                          onChange={() => handleSelectClient(person)}
                          aria-label={`Select ${person.firstName} ${person.lastName}`}
                        />
                        <ListItemText primary={`${person.firstName} ${person.lastName}`} />
                      </ListItem>
                    ))}
                    {filteredClients.length === 0 && (
                      <ListItem>
                        <ListItemText primary="No clients found." />
                      </ListItem>
                    )}
                  </List>
                </Paper>

                <Typography
                  variant="h6"
                  sx={{ marginTop: '24px' }}
                  aria-label="Assigned Clients Header"
                >
                  Assigned Clients
                </Typography>
                <Paper
                  sx={{ maxHeight: 400, overflow: 'auto', marginTop: '8px' }}
                  onDrop={(event) => handleDrop(event, 'client')}
                  onDragOver={handleDragOver}
                  aria-label="Assigned Clients Drop Zone"
                >
                  <List dense sx={{ maxHeight: 300, overflowY: 'auto' }} aria-label="Assigned Clients List">
                    {assignedClients.map((person) => (
                      <ListItem key={person._id}>
                        <ListItemText primary={`${person.firstName} ${person.lastName}`} />
                        <IconButton
                          edge="end"
                          aria-label={`Remove ${person.firstName} ${person.lastName}`}
                          onClick={() => handleRemoveClient(person._id)}
                          disabled={!hasDeletePermission}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                    {assignedClients.length === 0 && (
                      <ListItem>
                        <ListItemText primary="No clients assigned." />
                      </ListItem>
                    )}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedAssignCareEntityScreen = () => (
  <ErrorBoundary>
    <AssignCareEntityScreen />
  </ErrorBoundary>
);

export default WrappedAssignCareEntityScreen;