// src/screens/CustomClientFieldsScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormHelperText,
  CircularProgress
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import TemplatePreview from '../components/templateBuilder/TemplatePreview';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles'; 
import { useAuth } from '../context/AuthContext'; 
import ErrorBoundary from '../components/ErrorBoundary'; 

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const CustomClientFieldsScreen = () => {
  const { permissions = [], loading: authLoading } = useAuth();
  const [fields, setFields] = useState([]);
  const [fieldLabel, setFieldLabel] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [selectedField, setSelectedField] = useState(null);
  const [openCustomizeField, setOpenCustomizeField] = useState(false);
  const [errors, setErrors] = useState({});
  const [masterLists, setMasterLists] = useState([]);
  const [customTables, setCustomTables] = useState([]);
  const [newListOptions, setNewListOptions] = useState([{ label: '', value: '' }]);
  const [newListName, setNewListName] = useState('');
  const [openNewListDialog, setOpenNewListDialog] = useState(false);
  const [search, setSearch] = useState('');
  const [draggedIndex, setDraggedIndex] = useState(null); 
  const [isLoadingMasterLists, setIsLoadingMasterLists] = useState(false);
  const [isLoadingCustomTables, setIsLoadingCustomTables] = useState(false);
  const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // For now, remove permission checks and always allow actions
  const hasPermission = () => true;

  const fetchMasterLists = useCallback(async () => {
    setIsLoadingMasterLists(true);
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/masterlists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMasterLists(response.data);
    } catch (error) {
      Swal.fire({
        title: 'Error Fetching Master Lists',
        text: error.response?.data?.message || error.message,
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    } finally {
      setIsLoadingMasterLists(false);
    }
  }, []);

  const fetchCustomTables = useCallback(async () => {
    setIsLoadingCustomTables(true);
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/customtables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomTables(response.data);
    } catch (error) {
      Swal.fire({
        title: 'Error Fetching Custom Tables',
        text: error.response?.data?.message || error.message,
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    } finally {
      setIsLoadingCustomTables(false);
    }
  }, []);

  const fetchCustomFields = useCallback(async () => {
    setIsLoadingCustomFields(true);
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/customfields`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFields(response.data);
    } catch (error) {
      Swal.fire({
        title: 'Error Fetching Custom Fields',
        text: error.response?.data?.message || error.message,
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    } finally {
      setIsLoadingCustomFields(false);
    }
  }, []);

  useEffect(() => {
    fetchMasterLists();
    fetchCustomTables();
    fetchCustomFields();
  }, [fetchMasterLists, fetchCustomTables, fetchCustomFields]);

  const addField = () => {
    // No permission check now
    if (!fieldLabel.trim()) {
      Swal.fire({
        title: 'Warning',
        text: 'Field label is empty. Consider providing one.',
        icon: 'warning',
        ...customAlertStyles.sweetAlert
      });
    }

    if (!fieldType.trim()) {
      Swal.fire({
        title: 'Warning',
        text: 'Field type is not selected. Consider choosing one.',
        icon: 'warning',
        ...customAlertStyles.sweetAlert
      });
    }

    const newField = {
      id: `field-${fields.length + 1}`,
      label: fieldLabel,
      type: fieldType,
      options: (fieldType === 'select' || fieldType === 'radio') ? [] : undefined,
      customTable: fieldType === 'nested' ? '' : undefined,
      isDeleted: false
    };

    setFields((prevFields) => [...prevFields, newField]);
    setFieldLabel('');
    setFieldType('');
    setErrors({});
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedField((prevField) => ({ ...prevField, [name]: value }));
  };

  const saveFieldCustomization = () => {
    if (selectedField.type === 'select' || selectedField.type === 'radio') {
      const selectedList = masterLists.find(list => list._id === selectedField.masterList);
      if (selectedList) {
        selectedField.options = selectedList.options;
        selectedField.masterList = selectedList._id;
      }
    } else if (selectedField.type === 'nested') {
      const selectedTable = customTables.find(table => table._id === selectedField.customTable);
      if (selectedTable) {
        selectedField.customTable = selectedTable._id;
      }
    }
    setFields((prevFields) => prevFields.map(f => (f.id === selectedField.id ? selectedField : f)));
    setOpenCustomizeField(false);
    setSelectedField(null);
  };

  const handleDeleteField = async (fieldId) => {
    const fieldToDelete = fields.find(field => field.id === fieldId || field._id === fieldId);

    if (!fieldToDelete._id) {
      setFields((prevFields) => prevFields.filter(field => field.id !== fieldId));
      return;
    }

    // No permission check now
    try {
      const token = localStorage.getItem('userToken');
      await axios.delete(`${API_BASE_URL}/api/customfields/${fieldId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setFields((prevFields) => prevFields.filter(field => field.id !== fieldId && field._id !== fieldId));

      Swal.fire({
        title: 'Success',
        text: 'Field successfully deleted.',
        icon: 'success',
        ...customAlertStyles.sweetAlert
      });
    } catch (error) {
      Swal.fire({
        title: 'Error Deleting Field',
        text: error.response?.data?.message || error.message,
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    }
  };

  const handleDragStart = (event, index) => {
    setDraggedIndex(index);
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();
    if (draggedIndex === null || draggedIndex === dropIndex) return;

    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      const draggedField = updatedFields.splice(draggedIndex, 1)[0]; 
      updatedFields.splice(dropIndex, 0, draggedField);
      return updatedFields;
    });
    setDraggedIndex(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleNewListOptionChange = (index, key, value) => {
    setNewListOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index][key] = value;
      return updatedOptions;
    });
  };

  const addNewListOption = () => {
    setNewListOptions((prevOptions) => [...prevOptions, { label: '', value: '' }]);
  };

  const removeNewListOption = (index) => {
    setNewListOptions((prevOptions) => prevOptions.filter((_, idx) => idx !== index));
  };

  const saveNewList = async () => {
    const options = newListOptions;
    const masterListData = { name: newListName, options };

    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.post(`${API_BASE_URL}/api/masterlists`, masterListData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const newMasterList = response.data.masterList;
      setMasterLists((prevMasterLists) => [...prevMasterLists, newMasterList]);
      setNewListName('');
      setNewListOptions([{ label: '', value: '' }]);
      setOpenNewListDialog(false);

      if (selectedField) {
        setSelectedField((prevField) => ({
          ...prevField,
          masterList: newMasterList._id,
          options: newMasterList.options
        }));
      }
    } catch (error) {
      Swal.fire({
        title: 'Error Adding Master List',
        text: error.response?.data?.message || error.message,
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    }
  };

  const saveCustomFields = async () => {
    // No permission check now
    setIsSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      const userID = '6695926b33e663e94117f688'; 

      const fieldsToSave = fields.map(field => ({
        ...field,
        createdBy: userID,
      }));

      await axios.post(`${API_BASE_URL}/api/customfields`, { fields: fieldsToSave }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });

      Swal.fire({
        title: 'Success!',
        text: 'Custom fields saved successfully.',
        icon: 'success',
        ...customAlertStyles.sweetAlert
      });
    } catch (error) {
      Swal.fire({
        title: 'Error Saving Custom Fields',
        text: error.response?.data?.message || error.message,
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    } finally {
      setIsSaving(false);
    }
  };

  const filteredMasterLists = masterLists.filter(list =>
    list.name.toLowerCase().includes(search.toLowerCase())
  );

  if (authLoading || isLoadingMasterLists || isLoadingCustomTables || isLoadingCustomFields) {
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  // With permission checks removed, the button is always enabled
  const addButtonDisabled = false;

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, marginTop: '64px', padding: '16px' }}>
        <Typography variant="h6" aria-label="Add Custom Fields Section">Add Custom Fields</Typography>
        <Box display="flex" gap="16px">
          <FormControl fullWidth error={Boolean(errors.fieldLabel)}>
            <TextField
              label="Field Label"
              value={fieldLabel}
              onChange={(e) => setFieldLabel(e.target.value)}
              aria-label="Field Label Input"
            />
            {errors.fieldLabel && <FormHelperText>{errors.fieldLabel}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Field Type</InputLabel>
            <Select
              value={fieldType}
              onChange={(e) => setFieldType(e.target.value)}
              label="Field Type"
              aria-label="Field Type Select"
            >
              <MenuItem value="header">Header</MenuItem>
              <MenuItem value="bodyText">Body Text</MenuItem>
              <MenuItem value="checkbox">Checkbox</MenuItem>
              <MenuItem value="date">Date Picker</MenuItem>
              <MenuItem value="fileUpload">File Upload</MenuItem>
              <MenuItem value="textarea">Large Text</MenuItem>
              <MenuItem value="nested">Custom Table</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="slider">Slider</MenuItem>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="select">Select</MenuItem>
              <MenuItem value="radio">Radio Button</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={addField}
            disabled={addButtonDisabled}
            aria-label="Add Custom Field Button"
          >
            Add Field
          </Button>
        </Box>

        <Box mt={4}>
          <Typography variant="h6" aria-label="Arrange Custom Fields Section">Arrange Custom Fields</Typography>
          <Paper sx={{ padding: '16px', minHeight: '200px' }}>
            {fields.length === 0 ? (
              <Typography>No custom fields added yet.</Typography>
            ) : (
              fields.map((field, index) => (
                !field.isDeleted && (
                  <Box
                    key={field.id || field._id}
                    sx={{
                      padding: '8px',
                      marginBottom: '4px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                      backgroundColor: draggedIndex === index ? '#f0f0f0' : 'white'
                    }}
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDrop={(e) => handleDrop(e, index)}
                    onDragOver={handleDragOver}
                    aria-label={`Drag ${field.label} Field`}
                    draggable
                  >
                    <DragIndicatorIcon />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="body1" aria-label={`Field Name and Type ${field.label} (${field.type})`}>
                        {field.label} ({field.type})
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => handleDeleteField(field.id || field._id)}
                      sx={{ color: 'red' }}
                      aria-label={`Delete ${field.label} Field`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )
              ))
            )}
          </Paper>

          <Box mt={4}>
            <Typography variant="h6" aria-label="Preview Custom Fields Section">Preview Custom Fields</Typography>
            <TemplatePreview fields={fields} masterLists={masterLists} customTables={customTables} />
          </Box>

          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={saveCustomFields}
              disabled={isSaving}
              aria-label="Save Custom Fields Button"
            >
              {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Save Custom Fields'}
            </Button>
          </Box>

          {/* Customize Field Dialog */}
          <Dialog
            open={openCustomizeField}
            onClose={() => setOpenCustomizeField(false)}
            aria-labelledby="customize-field-dialog"
          >
            <DialogTitle id="customize-field-dialog">Customize Field</DialogTitle>
            <DialogContent>
              <TextField
                margin="normal"
                fullWidth
                label="Field Label"
                name="label"
                value={selectedField?.label || ''}
                onChange={handleFieldChange}
                aria-label="Customize Field Label Input"
              />
              {(selectedField?.type === 'select' || selectedField?.type === 'radio') && (
                <Box mt={2}>
                  <Button
                    onClick={() => setOpenNewListDialog(true)}
                    aria-label="Create New List Button"
                  >
                    Create New List
                  </Button>
                  <Typography variant="h6" aria-label="Import Existing List Section" mt={2}>Import Existing List</Typography>
                  <TextField
                    fullWidth
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{ marginBottom: '16px', marginTop: '8px' }}
                    aria-label="Search Existing Lists Input"
                  />
                  {isLoadingMasterLists ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="50px">
                      <CircularProgress aria-label="Loading Master Lists" />
                    </Box>
                  ) : (
                    <Select
                      fullWidth
                      name="masterList"
                      value={selectedField?.masterList || ''}
                      onChange={handleFieldChange}
                      aria-label="Select Existing List Dropdown"
                    >
                      {filteredMasterLists.length > 0 ? (
                        filteredMasterLists.map((list) => (
                          <MenuItem key={list._id} value={list._id}>
                            {list.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled>No lists available</MenuItem>
                      )}
                    </Select>
                  )}
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenCustomizeField(false)} aria-label="Cancel Field Customization Button">Cancel</Button>
              <Button onClick={saveFieldCustomization} aria-label="Save Field Customization Button">Save</Button>
            </DialogActions>
          </Dialog>

          {/* New List Dialog */}
          <Dialog
            open={openNewListDialog}
            onClose={() => setOpenNewListDialog(false)}
            aria-labelledby="new-list-dialog"
          >
            <DialogTitle id="new-list-dialog">Create New List</DialogTitle>
            <DialogContent>
              <TextField
                margin="normal"
                fullWidth
                label="List Name"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                aria-label="New List Name Input"
              />
              {newListOptions.map((option, index) => (
                <Box key={index} display="flex" gap="8px" alignItems="center" mt={1}>
                  <TextField
                    margin="normal"
                    fullWidth
                    label={`Option ${index + 1} Label`}
                    value={option.label}
                    onChange={(e) => handleNewListOptionChange(index, 'label', e.target.value)}
                    aria-label={`Option ${index + 1} Label Input`}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    label={`Option ${index + 1} Value`}
                    value={option.value}
                    onChange={(e) => handleNewListOptionChange(index, 'value', e.target.value)}
                    aria-label={`Option ${index + 1} Value Input`}
                  />
                  <IconButton
                    onClick={() => removeNewListOption(index)}
                    aria-label={`Remove Option ${index + 1} Button`}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button
                onClick={addNewListOption}
                aria-label="Add Another Option Button"
                sx={{ mt: 2 }}
              >
                Add Another Option
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenNewListDialog(false)} aria-label="Cancel New List Creation Button">Cancel</Button>
              <Button onClick={saveNewList} aria-label="Save New List Button">Save</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
};

const WrappedCustomClientFieldsScreen = () => (
  <ErrorBoundary>
    <CustomClientFieldsScreen />
  </ErrorBoundary>
);

export default WrappedCustomClientFieldsScreen;