// src/screens/CareNavigatorScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Grid, TextField, IconButton, Modal, Tooltip, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { styles } from '../styles/styles';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary'; 

import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ProgramIcon from '@mui/icons-material/School';
import BuildIcon from '@mui/icons-material/Build';

const CareNavigatorScreen = () => {
  const navigate = useNavigate();
  const { permissions = [] } = useAuth();
  
  const [careEntities, setCareEntities] = useState([]);
  const [careEntitiesTotal, setCareEntitiesTotal] = useState(0);
  const [careEntitiesOffset, setCareEntitiesOffset] = useState(0);

  const [clients, setClients] = useState([]);
  const [clientsTotal, setClientsTotal] = useState(0);
  const [clientsOffset, setClientsOffset] = useState(0);

  const [entityClients, setEntityClients] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [search, setSearch] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const limitCareEntities = 12;
  const limitClients = 18;

  const fetchCareEntities = useCallback(async (append = false) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careentities/assigned-careentities`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: limitCareEntities,
          offset: careEntitiesOffset
        }
      });

      const newEntities = response.data.careEntities;
      const total = response.data.total || 0;

      setCareEntitiesTotal(total);
      if (append) {
        setCareEntities((prev) => [...prev, ...newEntities]);
      } else {
        setCareEntities(newEntities);
      }
    } catch (error) {
      console.error('Error fetching care entities:', error);
      if (!append) setCareEntities([]);
    }
  }, [API_BASE_URL, careEntitiesOffset]);

  const fetchClients = useCallback(async (append = false) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careentities/assigned-clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: limitClients,
          offset: clientsOffset
        }
      });

      const newClients = response.data.clients;
      const total = response.data.total || 0;

      setClientsTotal(total);
      if (append) {
        setClients((prev) => [...prev, ...newClients]);
      } else {
        setClients(newClients);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      if (!append) setClients([]);
    }
  }, [API_BASE_URL, clientsOffset]);

  useEffect(() => {
    fetchCareEntities();
  }, [fetchCareEntities]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const handleEntityClick = async (entityId) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careentities/${entityId}/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedEntity(entityId);
      setEntityClients(response.data);
      setOpenModal(true);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setEntityClients([]);
    }
  };

  const handleClientClick = (id) => {
    navigate(`/client-profile/${id}`);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Filtered entities and clients based on search
  let filteredEntities = careEntities.filter(entity =>
    entity.name.toLowerCase().includes(search.toLowerCase()) ||
    entity.type.toLowerCase().includes(search.toLowerCase()) ||
    entity.description.toLowerCase().includes(search.toLowerCase())
  );

  // Alphabetize entities by name
  filteredEntities.sort((a, b) => a.name.localeCompare(b.name));

  let filteredClients = clients.filter(client =>
    `${client.firstName} ${client.lastName}`.toLowerCase().includes(search.toLowerCase())
  );

  // Alphabetize clients by full name (firstName + lastName)
  filteredClients.sort((a, b) => {
    const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
    const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
    return nameA.localeCompare(nameB);
  });

  const hasViewPermission = (resource) => {
    return permissions.includes(`view_${resource}`);
  };

  if (!permissions.length) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6">Loading permissions...</Typography>
      </Box>
    );
  }

  if (!hasViewPermission('care-navigator')) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  // "Load More" logic
  const canLoadMoreEntities = careEntities.length < careEntitiesTotal;
  const canLoadMoreClients = clients.length < clientsTotal;

  const loadMoreEntities = () => {
    setCareEntitiesOffset((prevOffset) => prevOffset + limitCareEntities);
    setTimeout(() => fetchCareEntities(true), 0);
  };

  const loadMoreClients = () => {
    setClientsOffset((prevOffset) => prevOffset + limitClients);
    setTimeout(() => fetchClients(true), 0);
  };

  return (
    <>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          <Box sx={{ display: 'flex', gap: '16px', marginBottom: '24px' }}>
            <TextField
              variant="outlined"
              placeholder="Search care entities or clients..."
              value={search}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon aria-label="Search icon" />,
              }}
              inputProps={{
                'aria-label': 'Search care entities or clients',
              }}
            />
            <Tooltip title="Filter" arrow>
              <IconButton aria-label="Filter options">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sort" arrow>
              <IconButton aria-label="Sort options">
                <SortIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Care Entities Section */}
          <Typography component="h2" variant="h5" sx={{ marginBottom: '24px' }} aria-label="Care Entities Title">
            Care Entities
          </Typography>
          <Grid container spacing={3} sx={{ marginBottom: '24px' }} aria-label="Care Entities List">
            {filteredEntities.map((entity) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={entity._id} aria-label={`Care Entity ${entity.name}`}>
                <Box sx={{
                  backgroundColor: styles.lightBackground,
                  borderColor: styles.border,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  background: `linear-gradient(145deg, ${styles.lightBackground}, #FFFFFF)`,
                  padding: '16px', 
                  borderRadius: '8px', 
                  color: styles.textPrimary,
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                  }
                }}>
                    <Typography variant="h6" sx={{ marginBottom: '8px' }} aria-label="Entity Name">
                      {entity.name}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }} aria-label="Entity Type">
                      {entity.type && (
                        <>
                          {entity.type === 'location' && <LocationOnIcon sx={{ color: styles.accent, marginRight: '8px' }} aria-label="Location Icon" />}
                          {entity.type === 'program' && <ProgramIcon sx={{ color: styles.accent, marginRight: '8px' }} aria-label="Program Icon" />}
                          {entity.type === 'service' && <BuildIcon sx={{ color: styles.accent, marginRight: '8px' }} aria-label="Service Icon" />}
                          <Typography variant="body2" sx={{ color: styles.textSecondary }} aria-label={`Entity Type ${entity.type}`}>
                            {entity.type.charAt(0).toUpperCase() + entity.type.slice(1)}
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Typography variant="body2" sx={{ marginBottom: '16px' }} aria-label="Entity Description">
                      {entity.description}
                    </Typography>
                    <Tooltip title="View Clients" arrow>
                      <IconButton
                        color="primary"
                        onClick={() => handleEntityClick(entity._id)}
                        sx={{ backgroundColor: styles.primary, color: styles.background }}
                        aria-label="View Clients"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
          {canLoadMoreEntities && (
            <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
              <Button variant="contained" onClick={loadMoreEntities}>
                Load More Care Entities
              </Button>
            </Box>
          )}

          {/* Clients Section */}
          <Typography component="h2" variant="h5" sx={{ marginBottom: '24px' }} aria-label="Clients Title">
            Clients
          </Typography>
          <Grid container spacing={3} aria-label="Clients List" sx={{ marginBottom: '24px' }}>
            {filteredClients.map((client) => (
              <Grid
                item xs={12} sm={4} md={3} lg={2}
                key={client._id}
                onClick={() => handleClientClick(client._id)}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  '&:hover .profile-circle': {
                    transform: 'scale(1.1)',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                  }
                }}
                aria-label={`Client ${client.firstName} ${client.lastName}`}
              >
                <Box
                  className="profile-circle"
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    backgroundColor: styles.accent,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '8px',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  }}
                  aria-label={`Client Initials ${client.firstName[0]}${client.lastName[0]}`}
                >
                  <Typography variant="h6" sx={{ color: styles.background }}>
                    {client.firstName[0]}{client.lastName[0]}
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ color: styles.textPrimary }} aria-label="Client Name">
                  {client.firstName} {client.lastName}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {canLoadMoreClients && (
            <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
              <Button variant="contained" onClick={loadMoreClients}>
                Load More Clients
              </Button>
            </Box>
          )}

          {/* Modal for displaying clients under a specific care entity */}
          <Modal open={openModal} onClose={() => setOpenModal(false)} aria-labelledby="entity-clients-modal" aria-describedby="entity-clients-description">
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px',
              }}
              aria-label="Entity Clients Modal"
            >
              <Typography variant="h5" sx={{ marginBottom: '16px' }} id="entity-clients-modal">
                Clients under {careEntities.find(entity => entity._id === selectedEntity)?.name}
              </Typography>
              <Box id="entity-clients-description">
                {entityClients.length > 0 ? (
                  entityClients.map((client) => (
                    <Typography
                      key={client._id}
                      variant="body1"
                      onClick={() => handleClientClick(client._id)}
                      sx={{ cursor: 'pointer', padding: '8px 0', borderBottom: '1px solid', borderColor: styles.border, borderRadius: '4px', '&:hover': { backgroundColor: styles.lightBackground } }}
                      aria-label={`Client ${client.firstName} ${client.lastName}`}
                    >
                      {client.firstName} {client.lastName}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="body1" aria-label="No clients assigned to this care entity.">No clients assigned to this care entity.</Typography>
                )}
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

const WrappedCareNavigatorScreen = () => (
  <ErrorBoundary>
    <CareNavigatorScreen />
  </ErrorBoundary>
);

export default WrappedCareNavigatorScreen;