// src/screens/CareEntitiesScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, InputLabel, TableSortLabel, TablePagination, Select, MenuItem, FormControl, CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { styles } from '../styles/styles';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';

// Import icons directly (if needed in future enhancements)
// Example:
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';

const CareEntitiesScreen = () => {
  const navigate = useNavigate();
  
  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Use Auth context to get permissions and loading state
  const { permissions = [], loading: authLoading } = useAuth();

  // Define permission flags
  const hasViewPermission = permissions.includes('view_care-entities');
  const hasAddPermission = permissions.includes('add_care-entities');
  const hasEditPermission = permissions.includes('edit_care-entities');
  const hasDeletePermission = permissions.includes('delete_care-entities');

  // State variables
  const [careEntities, setCareEntities] = useState([]);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('active');
  const [openAddEntity, setOpenAddEntity] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [file, setFile] = useState(null);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loadingCareEntities, setLoadingCareEntities] = useState(false);
  const [saving, setSaving] = useState(false);

  // Fetch the user's permissions and handle access
  const fetchPermissions = useCallback(async () => {
    // Permissions are already fetched via useAuth
    if (!hasViewPermission && !authLoading) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      }).then(() => {
        navigate('/home'); // Redirect to home or an appropriate page
      });
    }
  }, [hasViewPermission, authLoading, navigate]);

  // Fetch care entities
  const fetchCareEntities = useCallback(async () => {
    if (hasViewPermission) {
      setLoadingCareEntities(true);
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get(`${API_BASE_URL}/api/careentities`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Fetched care entities:', response.data); // Log the fetched data
        setCareEntities(response.data);
      } catch (error) {
        console.error('Error fetching care entities:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch care entities.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      } finally {
        setLoadingCareEntities(false);
      }
    }
  }, [API_BASE_URL, hasViewPermission]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  useEffect(() => {
    if (hasViewPermission) {
      fetchCareEntities();
    }
  }, [fetchCareEntities, hasViewPermission]);

  // Handle adding a new care entity
  const handleAddEntity = async () => {
    if (!hasAddPermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to add care entities.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!name || !type || !description) {
      Swal.fire({
        title: 'Invalid Input',
        text: 'Please fill in all required fields.',
        icon: 'warning',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      const careEntityID = uuidv4(); // Generate a unique ID for the care entity
      const response = await axios.post(`${API_BASE_URL}/api/careentities`, 
        { 
          careEntityID, // Include the unique ID
          name, 
          type, 
          description,
          status
        }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      setCareEntities(prevCareEntities => [...prevCareEntities, response.data.careEntity]);
      setName('');
      setType('');
      setDescription('');
      setStatus('active');
      setOpenAddEntity(false);
      Swal.fire({
        title: 'Success',
        text: 'Care entity added successfully.',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      console.error('Error adding care entity:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to add care entity.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // Handle deactivating a care entity
  const handleDeactivateEntity = async (entityId) => {
    if (!hasDeletePermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to deactivate care entities.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, deactivate it!',
      customClass: customAlertStyles.sweetAlert,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSaving(true);
        try {
          const token = localStorage.getItem('userToken');
          await axios.put(`${API_BASE_URL}/api/careentities/${entityId}/inactivate`, null, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          fetchCareEntities();
          Swal.fire({
            title: 'Deactivated!',
            text: 'The care entity has been deactivated.',
            icon: 'success',
            ...customAlertStyles.sweetAlert,
          });
        } catch (error) {
          console.error('Error deactivating care entity:', error);
          Swal.fire({
            title: 'Error',
            text: 'Failed to deactivate care entity.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        } finally {
          setSaving(false);
        }
      }
    });
  };

  // Handle activating a care entity
  const handleActivateEntity = async (entityId) => {
    if (!hasEditPermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to activate care entities.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You want to activate this entity?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, activate it!',
      customClass: customAlertStyles.sweetAlert,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSaving(true);
        try {
          const token = localStorage.getItem('userToken');
          await axios.put(`${API_BASE_URL}/api/careentities/${entityId}/activate`, null, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          fetchCareEntities();
          Swal.fire({
            title: 'Activated!',
            text: 'The care entity has been activated.',
            icon: 'success',
            ...customAlertStyles.sweetAlert,
          });
        } catch (error) {
          console.error('Error activating care entity:', error);
          Swal.fire({
            title: 'Error',
            text: 'Failed to activate care entity.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        } finally {
          setSaving(false);
        }
      }
    });
  };

  // Handle file change for import
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle importing care entities
  const handleImport = async () => {
    if (!file || !hasAddPermission) {
      Swal.fire({
        title: 'Invalid Operation',
        text: 'No file selected or insufficient permissions.',
        icon: 'warning',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('userToken');
      await axios.post(`${API_BASE_URL}/api/careentities/import`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchCareEntities();
      setOpenImport(false);
      Swal.fire({
        title: 'Success',
        text: 'Care entities imported successfully.',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      console.error('Error importing care entities:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to import care entities.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  // Handle sorting request
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle editing a care entity
  const handleEditEntity = (entityId) => {
    if (!entityId) {
      console.error('No ID provided for editing');
      Swal.fire({
        title: 'Error',
        text: 'No ID provided for editing.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    if (!hasEditPermission) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to edit care entities.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    console.log(`Navigating to profile with ID: ${entityId}`);
    navigate(`/care-entity-profile/${entityId}`);
  };

  // Filter care entities based on search input
  const filteredEntities = careEntities.filter(entity => 
    entity.name.toLowerCase().includes(search.toLowerCase()) ||
    entity.type.toLowerCase().includes(search.toLowerCase()) ||
    entity.description.toLowerCase().includes(search.toLowerCase()) ||
    (entity.status && entity.status.toLowerCase().includes(search.toLowerCase()))
  );

  // Sort the filtered entities
  const sortedEntities = filteredEntities.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy].localeCompare(b[orderBy]);
    }
    return b[orderBy].localeCompare(a[orderBy]);
  });

  // Paginate the sorted entities
  const paginatedEntities = sortedEntities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (authLoading || loadingCareEntities) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
        aria-label="Loading Indicator"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1}>
          <Box sx={{ padding: '24px', marginTop: '64px' }}>
            <Typography component="h1" variant="h5" sx={styles.primaryHeader} aria-label="Care Entities Management">
              Care Entities Management
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
              <TextField
                variant="outlined"
                placeholder="Search..."
                value={search}
                onChange={handleSearchChange}
                sx={{ marginRight: '16px', flex: 1 }}
                aria-label="Search Care Entities"
              />
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <Button 
                  variant="contained" 
                  sx={{
                    ...styles.button, 
                    width: '180px', 
                    height: '40px', 
                    backgroundColor: hasAddPermission ? undefined : '#ccc', // Disable style if no add permission
                  }} 
                  onClick={() => hasAddPermission ? setOpenAddEntity(true) : null} 
                  aria-label="Add Care Entity"
                  disabled={!hasAddPermission}
                >
                  Add Care Entity
                </Button>
                <Button 
                  variant="contained" 
                  sx={{
                    ...styles.button, 
                    width: '180px', 
                    height: '40px', 
                    backgroundColor: hasAddPermission ? undefined : '#ccc', // Disable style if no add permission
                  }} 
                  onClick={() => hasAddPermission ? setOpenImport(true) : null}
                  aria-label="Import Care Entities"
                  disabled={!hasAddPermission}
                >
                  Import
                </Button>
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="care entities table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={() => handleSortRequest('name')}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'type'}
                        direction={orderBy === 'type' ? order : 'asc'}
                        onClick={() => handleSortRequest('type')}
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'status'}
                        direction={orderBy === 'status' ? order : 'asc'}
                        onClick={() => handleSortRequest('status')}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedEntities.map((entity) => (
                    <TableRow key={entity._id}>
                      <TableCell>{entity.name}</TableCell>
                      <TableCell>{entity.type}</TableCell>
                      <TableCell>{entity.description}</TableCell>
                      <TableCell style={{ color: entity.status === 'active' ? 'green' : 'red' }}>
                        {entity.status}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate(`/assign-care-entity/${entity._id}`)}
                          aria-label={`Assign ${entity.name}`}
                        >
                          Assign
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleEditEntity(entity._id)}
                          aria-label={`Edit ${entity.name}`}
                          sx={{ marginLeft: '8px' }}
                          disabled={!hasEditPermission}
                        >
                          Edit
                        </Button>
                        {entity.status === 'active' ? (
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDeactivateEntity(entity._id)}
                            aria-label={`Deactivate ${entity.name}`}
                            sx={{ marginLeft: '8px' }}
                            disabled={!hasDeletePermission}
                          >
                            Deactivate
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleActivateEntity(entity._id)}
                            aria-label={`Activate ${entity.name}`}
                            sx={{ marginLeft: '8px' }}
                            disabled={!hasEditPermission}
                          >
                            Activate
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {paginatedEntities.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No care entities found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={sortedEntities.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Rows per page:"
                aria-label="Table Pagination"
              />
            </TableContainer>

            {/* Add Care Entity Dialog */}
            <Dialog 
              open={openAddEntity} 
              onClose={() => setOpenAddEntity(false)} 
              aria-labelledby="add-care-entity-dialog-title"
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle id="add-care-entity-dialog-title">Add New Care Entity</DialogTitle>
              <DialogContent>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={styles.input}
                  aria-label="Care Entity Name"
                />
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    aria-label="Care Entity Type"
                  >
                    <MenuItem value="program">Program</MenuItem>
                    <MenuItem value="service">Service</MenuItem>
                    <MenuItem value="location">Location</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={styles.input}
                  aria-label="Care Entity Description"
                />
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    aria-label="Care Entity Status"
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenAddEntity(false)} aria-label="Cancel Adding Care Entity">Cancel</Button>
                <Button 
                  onClick={handleAddEntity} 
                  aria-label="Confirm Adding Care Entity" 
                  disabled={saving}
                  variant="contained"
                  color="primary"
                >
                  {saving ? <CircularProgress size={24} /> : 'Add Care Entity'}
                </Button>
              </DialogActions>
            </Dialog>

            {/* Import Care Entities Dialog */}
            <Dialog 
              open={openImport} 
              onClose={() => setOpenImport(false)} 
              aria-labelledby="import-care-entities-dialog-title"
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle id="import-care-entities-dialog-title">Import Care Entities</DialogTitle>
              <DialogContent>
                <InputLabel htmlFor="file-upload" aria-label="Choose File">Choose File</InputLabel>
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  style={{ margin: '16px 0' }}
                  aria-label="File Upload"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenImport(false)} aria-label="Cancel Importing Care Entities">Cancel</Button>
                <Button 
                  onClick={handleImport} 
                  aria-label="Confirm Importing Care Entities" 
                  disabled={saving}
                  variant="contained"
                  color="primary"
                >
                  {saving ? <CircularProgress size={24} /> : 'Import'}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedCareEntitiesScreen = () => (
  <ErrorBoundary>
    <CareEntitiesScreen />
  </ErrorBoundary>
);

export default WrappedCareEntitiesScreen;