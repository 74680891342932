// SignUpCompletion.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Button, TextField, Typography, Container, Box, CircularProgress,
} from '@mui/material';
import logo from '../shared/logo.png';
import { styles } from '../styles/styles';
import customAlertStyles from '../styles/CustomAlertStyles';
import Swal from 'sweetalert2';
import ErrorBoundary from '../components/ErrorBoundary';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const SignUpCompletion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { organization, email } = location.state || {};

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  useEffect(() => {
    if (!organization || !email) {
      Swal.fire({
        title: 'Error',
        text: 'Required information is missing. Please start over.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/choose-organization');
    }
  }, [organization, email, navigate]);

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordsMatch(value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setPasswordsMatch(password === value);
  };

  const handleSignUp = async () => {
    if (!firstName.trim() || !lastName.trim() || !password || !confirmPassword) {
      setError('All fields are required');
      Swal.fire({
        title: 'Error',
        text: 'All fields are required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!passwordsMatch) {
      setError('Passwords do not match');
      Swal.fire({
        title: 'Error',
        text: 'Passwords do not match.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${API_BASE_URL}/api/users/signup`, {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        password,
        organization: organization.trim(),
        // Removed 'role' from the payload
      });

      if (response.status === 201) {
        Swal.fire({
          title: 'Success',
          text: 'Account created successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
        navigate('/login');
      } else {
        setError('Sign up failed');
        Swal.fire({
          title: 'Error',
          text: 'Sign up failed.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Sign up error:', error);
      setError('Sign up failed');
      Swal.fire({
        title: 'Error',
        text: 'Sign up failed.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    navigate('/login');
  };

  return (
    <ErrorBoundary>
      <Container component="main" maxWidth="xs" sx={styles.loginContainer}>
        <Box sx={styles.formContainer}>
          <img src={logo} style={styles.logo} alt="Logo" aria-label="Application Logo" />
          <Typography component="h1" variant="h5" aria-label="Sign Up Completion Header" sx={styles.primaryHeader}>
            Complete Your Registration
          </Typography>
          <Typography component="h2" variant="subtitle1" aria-label="Provide your details subheader">
            Provide your details to create your account
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoComplete="given-name"
            autoFocus
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            aria-label="First Name Input"
            sx={styles.input}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            aria-label="Last Name Input"
            sx={styles.input}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            disabled
            aria-label="Email Input"
            sx={styles.input}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={handlePasswordChange}
            aria-label="Password Input"
            sx={styles.input}
            error={!passwordsMatch && !!password}
            helperText={!passwordsMatch && !!password ? 'Passwords do not match' : ''}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            aria-label="Confirm Password Input"
            sx={styles.input}
            error={!passwordsMatch && !!confirmPassword}
            helperText={!passwordsMatch && !!confirmPassword ? 'Passwords do not match' : ''}
          />
          {error && (
            <Typography component="p" variant="body2" color="error" aria-label="Sign Up Error">
              {error}
            </Typography>
          )}
          <Box sx={styles.buttonContainer}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={styles.button}
              onClick={handleSignUp}
              disabled={loading || !passwordsMatch}
              aria-label="Sign Up Button"
            >
              {loading ? <CircularProgress size={24} /> : 'Sign Up'}
            </Button>
          </Box>
          <Box sx={styles.linkContainer}>
            <Button
              type="button"
              onClick={handleBack}
              aria-label="Back Button"
              sx={styles.link}
            >
              Back
            </Button>
            <Button
              type="button"
              onClick={handleCancel}
              aria-label="Cancel Button"
              sx={styles.link}
            >
              Cancel, Back to Login
            </Button>
          </Box>
        </Box>
      </Container>
    </ErrorBoundary>
  );
};

export default SignUpCompletion;