// src/screens/MyTeamScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Grid, TextField, IconButton, Tooltip, Modal, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { styles } from '../styles/styles';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import { useAuth } from '../context/AuthContext'; 
import ErrorBoundary from '../components/ErrorBoundary';

import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const MyTeamScreen = () => {
  const navigate = useNavigate();
  const { permissions = [], loading } = useAuth(); 
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersTotal, setTeamMembersTotal] = useState(0);
  const [teamMembersOffset, setTeamMembersOffset] = useState(0);

  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState({});
  const [selectedContact, setSelectedContact] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const limit = 12; // Number of care entities/team members to load per request

  const fetchTeamMembers = useCallback(async (append = false) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careentities/team-members`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit,
          offset: teamMembersOffset
        }
      });

      // Expecting response in the format: { teamMembers: [...], total: number }
      const newMembers = response.data.teamMembers || [];
      const total = response.data.total || 0;

      setTeamMembersTotal(total);
      if (append) {
        setTeamMembers((prev) => [...prev, ...newMembers]);
      } else {
        setTeamMembers(newMembers);
      }

      // Initialize expanded state if needed
      if (!append) {
        const initialExpandedState = newMembers.reduce((acc, entity) => {
          acc[entity.careEntity._id] = true;
          return acc;
        }, {});
        setExpanded(initialExpandedState);
      }

    } catch (error) {
      console.error('Error fetching team members:', error);
      setTeamMembers([]);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch team members.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL, teamMembersOffset, limit]);

  useEffect(() => {
    fetchTeamMembers();
  }, [fetchTeamMembers]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleExpandClick = (entityId) => {
    setExpanded(prevExpanded => ({
      ...prevExpanded,
      [entityId]: !prevExpanded[entityId]
    }));
  };

  const handleContactClick = (member) => {
    setSelectedContact(member);
  };

  const handleCloseModal = () => {
    setSelectedContact(null);
  };

  const filteredTeamMembers = teamMembers.filter(entity => {
    return entity.staff.some(member =>
      `${member.firstName} ${member.lastName}`.toLowerCase().includes(search.toLowerCase())
    );
  });

  const hasViewPermission = (resource) => {
    return permissions.includes(`view_${resource}`);
  };

  console.log('Permissions:', permissions);

  if (loading) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (!hasViewPermission('my-team')) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  const canLoadMore = teamMembers.length < teamMembersTotal;

  const loadMoreTeamMembers = () => {
    setTeamMembersOffset((prevOffset) => prevOffset + limit);
    setTimeout(() => fetchTeamMembers(true), 0);
  };

  return (
    <>
      <NavigationBar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          <Box sx={{ display: 'flex', gap: '16px', marginBottom: '24px' }}>
            <TextField
              variant="outlined"
              placeholder="Search team members..."
              value={search}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon aria-label="Search icon" />,
              }}
              aria-label="Search Team Members"
            />
            <Tooltip title="Filter" arrow>
              <IconButton aria-label="Filter Team Members">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sort" arrow>
              <IconButton aria-label="Sort Team Members">
                <SortIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Typography component="h2" variant="h5" sx={{ marginBottom: '24px' }}>
            My Team
          </Typography>

          {filteredTeamMembers.map((entity) => (
            <Box key={entity.careEntity._id} sx={{ marginBottom: '24px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <Typography variant="h6" sx={{ flexGrow: 1 }} aria-label={`Care Entity: ${entity.careEntity.name || 'No Care Entity'}`}>
                  {entity.careEntity.name || 'No Care Entity'}
                </Typography>
                <IconButton onClick={() => handleExpandClick(entity.careEntity._id)} aria-label={`Expand ${entity.careEntity.name}`}>
                  <ExpandMoreIcon />
                </IconButton>
              </Box>

              {expanded[entity.careEntity._id] && (
                <Grid container spacing={3} sx={{ marginBottom: '48px' }}>
                  {entity.staff.map((member) => (
                    <Grid
                      item xs={12} sm={6} md={4} lg={3}
                      key={member._id}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '&:hover .profile-circle': {
                          transform: 'scale(1.1)',
                          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                        }
                      }}
                      onClick={() => handleContactClick(member)}
                      aria-label={`Team Member: ${member.firstName} ${member.lastName}`}
                    >
                      <Box
                        className="profile-circle"
                        sx={{
                          width: 80,
                          height: 80,
                          borderRadius: '50%',
                          backgroundColor: styles.accent,
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '8px',
                          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                        }}
                      >
                        <Typography variant="h6" sx={{ color: styles.background }}>
                          {member.firstName[0]}{member.lastName[0]}
                        </Typography>
                      </Box>
                      <Typography variant="body1" sx={{ color: styles.textPrimary }}>
                        {member.firstName} {member.lastName}
                      </Typography>
                      <Typography variant="body2" sx={{ color: styles.textSecondary }}>
                        {member.jobTitle || ''}
                      </Typography>
                      <Box sx={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
                        <Tooltip title={member.phoneNumber || 'No record found'} arrow>
                          <IconButton
                            sx={{ color: styles.textPrimary, fontSize: '1.2rem' }}
                            aria-label={`Phone number of ${member.firstName} ${member.lastName}`}
                          >
                            <PhoneIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={member.email || 'No record found'} arrow>
                          <IconButton
                            sx={{ color: styles.textPrimary, fontSize: '1.2rem' }}
                            aria-label={`Email of ${member.firstName} ${member.lastName}`}
                          >
                            <EmailIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          ))}

          {canLoadMore && (
            <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
              <Button variant="contained" onClick={loadMoreTeamMembers}>
                Load More
              </Button>
            </Box>
          )}

          <Modal
            open={!!selectedContact}
            onClose={handleCloseModal}
            aria-labelledby="contact-modal-title"
            aria-describedby="contact-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px',
              }}
            >
              <Typography id="contact-modal-title" variant="h6" component="h2">
                Contact Information
              </Typography>
              {selectedContact && (
                <>
                  <Typography id="contact-modal-description" sx={{ mt: 2 }}>
                    Name: {selectedContact.firstName} {selectedContact.lastName}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    Job Title: {selectedContact.jobTitle || 'No record found'}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    Phone: {selectedContact.phoneNumber || 'No record found'}
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    Email: {selectedContact.email || 'No record found'}
                  </Typography>
                </>
              )}
            </Box>
          </Modal>

        </Box>
      </Box>
    </>
  );
};

const WrappedMyTeamScreen = () => (
  <ErrorBoundary>
    <MyTeamScreen />
  </ErrorBoundary>
);

export default WrappedMyTeamScreen;