// src/screens/CareEntityProfileScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  IconButton,
  Collapse,
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import customAlertStyles from '../styles/CustomAlertStyles';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';

// Initialize SweetAlert with React Content
const MySwal = withReactContent(Swal);

const CareEntityProfileScreen = () => {
  const { id: careEntityId } = useParams();
  
  // Use Auth context to get permissions and loading state
  const { permissions = [], loading: authLoading } = useAuth();

  // Define permission flags
  const hasViewPermission = permissions.includes('view_care-entity-profile');
  const hasEditPermission = permissions.includes('edit_care-entity-profile');

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // State variables
  const [careEntity, setCareEntity] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);

  // Fetch care entity data
  const fetchCareEntity = useCallback(async () => {
    if (!hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      }).then(() => {
        // Optionally, navigate to a different page
      });
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/careentities/${careEntityId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCareEntity(response.data || {});
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch care entity data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    } finally {
      setLoading(false);
    }
  }, [API_BASE_URL, careEntityId, hasViewPermission]);

  useEffect(() => {
    fetchCareEntity();
  }, [fetchCareEntity]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Handle input changes with functional updates
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCareEntity(prevCareEntity => ({
      ...prevCareEntity,
      [name]: value,
    }));
  };

  // Handle manager change
  const handleManagerChange = (e) => {
    const { value } = e.target;
    setCareEntity(prevCareEntity => ({
      ...prevCareEntity,
      manager: value,
    }));
  };

  // Toggle expand/collapse
  const toggleExpand = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  // Handle save operation
  const handleSave = async () => {
    if (!hasEditPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
      return;
    }

    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.put(`${API_BASE_URL}/api/careentities/${careEntityId}`, careEntity, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data) {
        Swal.fire({
          title: 'Success',
          text: 'Care entity updated successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert
        });

        // Fetch updated care entity data
        const updatedResponse = await axios.get(`${API_BASE_URL}/api/careentities/${careEntityId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCareEntity(updatedResponse.data || {});
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Failed to update care entity data.',
          icon: 'error',
          ...customAlertStyles.sweetAlert
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    } finally {
      setSaving(false);
      setEditMode(false);
    }
  };

  // Loading indicator
  if (loading || authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
        aria-label="Loading Indicator"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Access denied message
  if (!hasViewPermission) {
    return (
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" color="error" aria-label="Access Denied Message">
          You do not have permission to view this page.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Navbar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          <Box 
            sx={{
              marginBottom: 2,
              border: '1px solid #549F93',
              padding: '8px',
              borderRadius: '4px',
              background: 'linear-gradient(to right, #258EA6, #549F93)',
              position: 'relative',
            }}
            aria-label="Care Entity Header"
          >
            <Avatar
              alt={`${careEntity.name}`}
              src={careEntity.profilePicture || undefined}
              sx={{
                width: 160,
                height: 160,
                position: 'absolute',
                top: '50%',
                right: '16px',
                transform: 'translateY(-50%)',
                border: '4px solid #FFFFFF',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                fontSize: '36px',
                backgroundColor: careEntity.profilePicture ? 'transparent' : '#BDBDBD',
                color: '#FFFFFF',
              }}
              aria-label="Care Entity Avatar"
            >
              {!careEntity.profilePicture && careEntity.name ? careEntity.name[0] : ''}
            </Avatar>
            <Box sx={{ marginRight: '180px' }}>
              <Typography variant="h6" sx={{ color: '#FFFFFF' }} aria-label="Care Entity Name">
                {careEntity.name || 'Unnamed Entity'}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }} aria-label="Care Entity Phone">
                Phone: {careEntity.phoneNumber || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }} aria-label="Care Entity Address">
                Address: {careEntity.address || 'N/A'}
              </Typography>
            </Box>
            <IconButton onClick={toggleExpand} sx={{ color: '#FFFFFF' }} aria-label="Toggle Expand">
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            {expanded && hasEditPermission && (
              <>
                <IconButton onClick={() => setEditMode(true)} sx={{ color: '#FFFFFF' }} aria-label="Edit Care Entity">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={handleSave} sx={{ color: '#FFFFFF' }} disabled={saving} aria-label="Save Care Entity">
                  {saving ? <CircularProgress size={24} /> : <SaveIcon />}
                </IconButton>
              </>
            )}
          </Box>
          <Collapse in={expanded}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="Care Entity Tabs">
              <Tab label="General Information" aria-label="General Information Tab" />
              <Tab label="Assigned Staff" aria-label="Assigned Staff Tab" />
              <Tab label="Assigned Clients" aria-label="Assigned Clients Tab" />
              <Tab label="Profile Picture" aria-label="Profile Picture Tab" />
            </Tabs>
            <Box sx={{ marginTop: 2 }}>
              {activeTab === 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Name"
                      name="name"
                      value={careEntity.name || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                      aria-label="Care Entity Name Input"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Type"
                      name="type"
                      value={careEntity.type || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                      aria-label="Care Entity Type Input"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      label="Description"
                      name="description"
                      value={careEntity.description || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                      multiline
                      rows={3}
                      aria-label="Care Entity Description Input"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      value={careEntity.phoneNumber || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                      aria-label="Care Entity Phone Number Input"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Address"
                      name="address"
                      value={careEntity.address || ''}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={!editMode}
                      aria-label="Care Entity Address Input"
                    />
                  </Grid>
                </Grid>
              )}
              {activeTab === 1 && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" aria-label="Assigned Staff Header">
                      Assigned Staff
                    </Typography>
                    {/* Manager Dropdown */}
                    <FormControl fullWidth sx={{ marginTop: 2 }}>
                      <InputLabel id="manager-select-label">Manager</InputLabel>
                      <Select
                        labelId="manager-select-label"
                        label="Manager"
                        name="manager"
                        value={careEntity.manager?._id || ''}
                        onChange={handleManagerChange}
                        disabled={!editMode}
                        aria-label="Manager Select"
                      >
                        <MenuItem value="">None</MenuItem>
                        {careEntity.assignedStaff?.map((staff) => (
                          <MenuItem key={staff._id} value={staff._id}>
                            {staff.firstName} {staff.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {/* Staff Table */}
                    <TableContainer component={Paper} aria-label="Assigned Staff Table">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Job Title</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {careEntity.assignedStaff?.map((staff) => (
                            <TableRow key={staff._id}>
                              <TableCell>{staff.firstName} {staff.lastName}</TableCell>
                              <TableCell>{staff.phoneNumber}</TableCell>
                              <TableCell>{staff.email}</TableCell>
                              <TableCell>{staff.jobTitle}</TableCell>
                            </TableRow>
                          ))}
                          {(!careEntity.assignedStaff || careEntity.assignedStaff.length === 0) && (
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                No assigned staff found.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
              {activeTab === 2 && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" aria-label="Assigned Clients Header">
                      Assigned Clients
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {/* Clients Table */}
                    <TableContainer component={Paper} aria-label="Assigned Clients Table">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Org Client ID</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {careEntity.assignedClients?.map((client) => (
                            <TableRow key={client._id}>
                              <TableCell>{client.firstName}</TableCell>
                              <TableCell>{client.lastName}</TableCell>
                              <TableCell>{client.orgClientId}</TableCell>
                              <TableCell>{client.status}</TableCell>
                            </TableRow>
                          ))}
                          {(!careEntity.assignedClients || careEntity.assignedClients.length === 0) && (
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                No assigned clients found.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
              {activeTab === 3 && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" aria-label="Profile Picture Header">
                      Profile Picture
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        flexDirection: 'column',
                        marginTop: 2 
                      }}
                      aria-label="Profile Picture Upload Section"
                    >
                      <Avatar
                        alt={`${careEntity.name}`}
                        src={careEntity.profilePicture || undefined}
                        sx={{
                          width: 200,
                          height: 200,
                          marginBottom: 2,
                        }}
                      >
                        {!careEntity.profilePicture && careEntity.name ? careEntity.name[0] : ''}
                      </Avatar>
                      {editMode && (
                        <Button
                          variant="contained"
                          component="label"
                          aria-label="Upload Profile Picture Button"
                        >
                          Upload New Picture
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                // Implement the logic to upload and set the profile picture
                                // This may involve uploading the image to the server and updating the careEntity state
                                // For demonstration, we'll assume the file URL is obtained after upload
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  setCareEntity(prevCareEntity => ({
                                    ...prevCareEntity,
                                    profilePicture: reader.result,
                                  }));
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedCareEntityProfileScreen = () => (
  <ErrorBoundary>
    <CareEntityProfileScreen />
  </ErrorBoundary>
);

export default WrappedCareEntityProfileScreen;