import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import Swal
import { Button, TextField, Typography, Container, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import logo from '../shared/logo.png';
import { styles } from '../styles/styles';
import customAlertStyles from '../styles/CustomAlertStyles'; // Import custom alert styles
import ErrorBoundary from '../components/ErrorBoundary';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); // Added loading state
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    if (!email.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Email field cannot be blank.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      Swal.fire({
        title: 'Error',
        text: 'Please enter a valid email address.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setLoading(true); // Start loading

    try {
      // Make an API call to send the password reset email
      await axios.post(`${API_BASE_URL}/api/users/forgot-password`, {
        email: email.trim(),
      });

      Swal.fire({
        title: 'Reset link sent',
        text: 'Please check your email for the password reset link.',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });

      navigate('/login');
    } catch (error) {
      console.error('Password reset error:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while sending the reset link. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <ErrorBoundary>
      <Container component="main" maxWidth="xs" sx={styles.loginContainer} aria-label="Forgot Password Screen">
        <Box sx={styles.formContainer} aria-label="Forgot Password Form Container">
          <img
            src={logo}
            style={styles.logo}
            alt="Application Logo"
            aria-label="Application Logo"
          />
          <Typography component="h1" variant="h5" aria-label="Forgot Password Header" sx={styles.primaryHeader}>
            Forgot Your Password?
          </Typography>
          <Typography component="h2" variant="subtitle1" aria-label="Reset Instruction">
            No worries, we’ll send you a reset link.
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-label="Email Input"
            sx={styles.input}
          />
          <Box sx={styles.buttonContainer}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={styles.button}
              onClick={handlePasswordReset}
              aria-label="Send Reset Link Button"
              disabled={loading} // Disable button when loading
            >
              {loading ? <CircularProgress size={24} /> : 'Send Reset Link'}
            </Button>
          </Box>
          <Box sx={styles.linkContainer}>
            <Button
              type="button"
              onClick={() => navigate('/login')}
              aria-label="Back to Login Link"
              sx={styles.link}
            >
              Back to Login
            </Button>
          </Box>
        </Box>
      </Container>
    </ErrorBoundary>
  );
};

export default ForgotPassword;