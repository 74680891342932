// src/components/templateBuilder/FinalReviewModal.jsx

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  Chip,
} from '@mui/material';
import { styled } from '@mui/system';
import TemplatePreviewModal from './TemplatePreviewModal';
import customAlertStyles from '../../styles/CustomAlertStyles';
import '../../screens/SweetAlertCustom.css';
import ErrorBoundary from '../ErrorBoundary';
import { useAuth } from '../../context/AuthContext';
import Swal from 'sweetalert2';

const LeftPanel = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  backgroundColor: customAlertStyles.backgroundColor,
}));

const RightPanel = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  borderLeft: `1px solid ${theme.palette.divider}`,
  backgroundColor: customAlertStyles.backgroundColor,
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: customAlertStyles.sweetAlert.background,
  color: customAlertStyles.sweetAlert.color,
  textAlign: 'center',
  fontSize: customAlertStyles.primaryHeader.fontSize,
  fontWeight: customAlertStyles.primaryHeader.fontWeight,
}));

const FinalReviewModal = ({ open, onClose, onFinish, templateDetails, fields }) => {
  const { permissions = [], loading: authLoading } = useAuth();

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  if (authLoading) {
    return null; // Or a loading spinner
  }

  if (!hasViewPermission) {
    Swal.fire({
      title: 'Access Denied',
      text: 'You do not have permission to view this content.',
      icon: 'error',
      ...customAlertStyles.sweetAlert,
    });
    return null;
  }

  // Prepare data for display
  const {
    description,
    isStatic,
    updateFrequency,
    customFrequency,
    activePeriod,
    startDate,
    endDate,
    assignedClients = [],
    assignedUsers = [],
    assignedCareEntities = [],
    assignedProcessLabels = [],
    permissionsForRoles = {},
  } = templateDetails;

  // Prepare process labels (states)
  const processLabels = assignedProcessLabels
    .filter(Boolean)
    .map((label) => label.name)
    .filter(Boolean);

  // Improved descriptions for isStatic
  const isStaticDescription = isStatic
    ? 'This template is "Static". It serves as a traditional form that can be filled out and stored. Updates are occasional and not tracked over time.'
    : 'This template is "Dynamic". It is designed for regular data entry and operational use, allowing you to track changes and versions over time.';

  // Prepare update frequency description
  const updateFrequencyDescription = (() => {
    let desc = `Update Frequency: ${updateFrequency}`;
    if (updateFrequency === 'custom' && customFrequency) {
      desc += ` every ${customFrequency.interval} ${customFrequency.unit}`;
      if (customFrequency.specificDays && customFrequency.specificDays.length > 0) {
        desc += ` on specific days: ${customFrequency.specificDays.join(', ')}`;
      }
    }
    desc += '. This setting indicates the intended use of the template but does not limit updates.';
    return desc;
  })();

  // Prepare period description
  const periodDescription =
    activePeriod === 'temporary'
      ? `This template is temporary and will only be accessible between ${
          startDate && new Date(startDate).toLocaleDateString()
        } and ${endDate && new Date(endDate).toLocaleDateString()}.`
      : 'This template is permanent and will always be accessible unless an end date is added or it is made inactive.';

  // Prepare assigned to description
  const assignedToDescription = `This template will be assigned to ${assignedClients.length} client(s), ${assignedUsers.length} user(s), and ${assignedCareEntities.length} care entity(ies).`;

  // Prepare roles description
  const rolesWithPermissions = Object.keys(permissionsForRoles).filter(
    (roleId) => permissionsForRoles[roleId].length > 0
  );
  const rolesDescription = `Users with ${rolesWithPermissions.length} role(s) have varied access to this template.`;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <StyledDialogTitle>Final Review Before Publishing</StyledDialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box sx={{ display: 'flex' }}>
          {/* Left Panel */}
          <LeftPanel>
            <Typography variant="h6" sx={customAlertStyles.primaryHeader}>
              Summary
            </Typography>
            <Divider sx={{ marginY: 1 }} />
            {/* CarePlan Description */}
            <Typography variant="subtitle1" gutterBottom sx={customAlertStyles.subHeader}>
              <strong>CarePlan Description:</strong>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
              <Typography variant="body1" gutterBottom>
                {description}
              </Typography>
              {processLabels.length > 0 && (
                <Box sx={{ marginLeft: 2 }}>
                  {processLabels.map((label, index) => (
                    <Chip
                      key={index}
                      label={label}
                      sx={{
                        backgroundColor: 'blue',
                        color: 'white',
                        marginLeft: index > 0 ? 1 : 0,
                      }}
                    />
                  ))}
                </Box>
              )}
            </Box>
            {/* Template Type */}
            <Typography variant="subtitle1" gutterBottom sx={customAlertStyles.subHeader}>
              <strong>Template Type:</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              {isStatic ? 'Static' : 'Dynamic'}
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ fontStyle: 'italic' }}>
              {isStaticDescription}
            </Typography>
            {/* Update Frequency */}
            <Typography variant="subtitle1" gutterBottom sx={customAlertStyles.subHeader}>
              <strong>Update Frequency:</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              {updateFrequency}
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ fontStyle: 'italic' }}>
              {updateFrequencyDescription}
            </Typography>
            {/* Active Period */}
            <Typography variant="subtitle1" gutterBottom sx={customAlertStyles.subHeader}>
              <strong>Active Period:</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              {activePeriod === 'temporary' ? 'Temporary' : 'Permanent'}
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ fontStyle: 'italic' }}>
              {periodDescription}
            </Typography>
            {/* Assigned To */}
            <Typography variant="subtitle1" gutterBottom sx={customAlertStyles.subHeader}>
              <strong>Assigned To:</strong>
            </Typography>
            <Typography variant="body2" gutterBottom>
              {assignedToDescription}
            </Typography>
            {/* Roles */}
            <Typography variant="subtitle1" gutterBottom sx={customAlertStyles.subHeader}>
              <strong>Roles:</strong>
            </Typography>
            <Typography variant="body2" gutterBottom>
              {rolesDescription}
            </Typography>
          </LeftPanel>
          {/* Right Panel */}
          <RightPanel>
            <Typography variant="h6" sx={customAlertStyles.primaryHeader}>
              Template Preview
            </Typography>
            <Divider sx={{ marginY: 1 }} />
            {/* Render Template Preview Component */}
            <TemplatePreviewModal fields={fields} />
          </RightPanel>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
        <Button onClick={onClose} sx={{ ...customAlertStyles.button, marginRight: '16px' }}>
          Continue Editing
        </Button>
        <Button onClick={onFinish} variant="contained" sx={customAlertStyles.button}>
          Finish & Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Wrap the component with ErrorBoundary
const WrappedFinalReviewModal = (props) => (
  <ErrorBoundary>
    <FinalReviewModal {...props} />
  </ErrorBoundary>
);

export default WrappedFinalReviewModal;