// src/screens/HomeScreen.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Avatar,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Notifications as NotificationsIcon,
  Web as WebIcon,
} from '@mui/icons-material';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';

const HomeScreen = ({ setIsAuthenticated }) => {
  const [user, setUser] = useState({ firstName: '', lastName: '', profilePicture: '' });
  const [loading, setLoading] = useState(true);

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  useEffect(() => {
    fetchUserData();
  }, [API_BASE_URL]);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('userToken');

      if (token) {
        const response = await axios.get(`${API_BASE_URL}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          const { firstName, lastName, profilePicture } = response.data;
          setUser({ firstName, lastName, profilePicture });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Failed to retrieve user data.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
        }
      } else {
        Swal.fire({
          title: 'No Token Found',
          text: 'Please log in to access your account.',
          icon: 'warning',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.log('Error fetching user data:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while fetching user data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setIsAuthenticated(false);
    localStorage.removeItem('userToken');
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#FFFFFF' }} aria-label="Home Screen">
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, pt: 10, backgroundColor: '#FFFFFF' }}
        aria-label="Main Content Area"
      >
        <Navbar handleLogout={handleLogout} />
        {loading ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          >
            <CircularProgress aria-label="Loading Indicator" />
          </Box>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
              <Avatar
                alt={`${user.firstName} ${user.lastName}`}
                src={user.profilePicture || '/default-avatar.png'}
                sx={{ width: 80, height: 80, marginRight: 2 }}
              />
              <Box>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 'bold', color: '#153243' }}
                  aria-label="Welcome Message"
                >
                  Welcome, {user.firstName} {user.lastName}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ color: '#549F93', marginBottom: 4 }}
                  aria-label="System Description"
                >
                  Care Made Simple
                </Typography>
              </Box>
            </Box>

            {/* Additional content */}
            <Grid container spacing={3} sx={{ marginTop: 4 }} aria-label="Additional Dashboard Sections">
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={3}
                  sx={{ p: 2, backgroundColor: '#FFFFFF', borderRadius: 2 }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: '#153243', fontWeight: 'bold', marginBottom: 2 }}
                    aria-label="Recent Activity Title"
                  >
                    Recent Activity
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: '#549F93', marginBottom: 2 }}
                    aria-label="Recent Activity Description"
                  >
                    Your most recent actions in the system.
                  </Typography>
                  {/* Sample activities */}
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Avatar sx={{ marginRight: 2, backgroundColor: '#258EA6' }}>C</Avatar>
                    <Box>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Updated Care Plan for Jane Smith
                      </Typography>
                      <Typography variant="caption" sx={{ color: '#549F93' }}>
                        1 hour ago - Care Plan Template
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Avatar sx={{ marginRight: 2, backgroundColor: '#153243' }}>S</Avatar>
                    <Box>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Added New Staff Member
                      </Typography>
                      <Typography variant="caption" sx={{ color: '#549F93' }}>
                        3 hours ago - Staff Management
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Avatar sx={{ marginRight: 2, backgroundColor: '#549F93' }}>R</Avatar>
                    <Box>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Reviewed Risk Assessment
                      </Typography>
                      <Typography variant="caption" sx={{ color: '#549F93' }}>
                        5 hours ago - Risk Management
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={3}
                  sx={{ p: 2, backgroundColor: '#FFFFFF', borderRadius: 2 }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: '#153243', fontWeight: 'bold', marginBottom: 2 }}
                    aria-label="Notifications & Workflows Title"
                  >
                    Notifications & Workflows
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: '#549F93', marginBottom: 2 }}
                    aria-label="Notifications & Workflows Description"
                  >
                    Pending actions and important updates.
                  </Typography>
                  {/* Sample notifications */}
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <NotificationsIcon sx={{ marginRight: 2, color: '#258EA6' }} />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        New Notification
                      </Typography>
                      <Typography variant="caption" sx={{ color: '#549F93' }}>
                        You have 5 new tasks.
                      </Typography>
                    </Box>
                    <IconButton aria-label="Mark as Read" sx={{ color: '#258EA6' }}>
                      <AssignmentTurnedInIcon />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <NotificationsIcon sx={{ marginRight: 2, color: '#153243' }} />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Pending Approval
                      </Typography>
                      <Typography variant="caption" sx={{ color: '#549F93' }}>
                        Please approve the recent changes.
                      </Typography>
                    </Box>
                    <IconButton aria-label="Mark as Read" sx={{ color: '#153243' }}>
                      <AssignmentTurnedInIcon />
                    </IconButton>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ marginTop: 4 }} aria-label="Quick Links and Resource Centre">
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={3}
                  sx={{ p: 2, backgroundColor: '#FFFFFF', borderRadius: 2 }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: '#153243', fontWeight: 'bold', marginBottom: 2 }}
                    aria-label="Quick Links Title"
                  >
                    Quick Links
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: '#549F93', marginBottom: 2 }}
                    aria-label="Quick Links Description"
                  >
                    Access external applications quickly.
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<WebIcon />}
                      href="https://external-application.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        backgroundColor: '#258EA6',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                      }}
                      aria-label="External Application 1 Button"
                    >
                      External Application 1
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<WebIcon />}
                      href="https://another-external-application.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        backgroundColor: '#549F93',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                      }}
                      aria-label="External Application 2 Button"
                    >
                      External Application 2
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={3}
                  sx={{ p: 2, backgroundColor: '#FFFFFF', borderRadius: 2 }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: '#153243', fontWeight: 'bold', marginBottom: 2 }}
                    aria-label="Resource Centre Title"
                  >
                    Resource Centre
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: '#549F93', marginBottom: 2 }}
                    aria-label="Resource Centre Description"
                  >
                    Access training materials, documentation, and submit feedback.
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/training-materials"
                      sx={{
                        backgroundColor: '#258EA6',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                      }}
                      aria-label="Training Materials Button"
                    >
                      Training Materials
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/feedback"
                      sx={{
                        backgroundColor: '#549F93',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                      }}
                      aria-label="Submit Feedback Button"
                    >
                      Submit Feedback
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default HomeScreen;