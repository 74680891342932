// src/screens/ProcessLabelManagementScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getUserToken } from '../utils/api';
import customAlertStyles from '../styles/CustomAlertStyles';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { purple, red } from '@mui/material/colors';

const ProcessLabelManagementScreen = () => {
  const navigate = useNavigate();
  const { permissions = [] } = useAuth();

  const [processLabels, setProcessLabels] = useState([]);
  const [name, setName] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null); // For drag-and-drop
  const [errors, setErrors] = useState({});

  const hasPermission = useCallback(
    (action) => permissions.includes(action),
    [permissions]
  );

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Fetch process labels
  const fetchProcessLabels = useCallback(async () => {
    if (!hasPermission('view_process-label-management')) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view process labels.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/home');
      return;
    }

    try {
      const token = getUserToken();
      const response = await axios.get(`${API_BASE_URL}/api/processlabels`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (Array.isArray(response.data)) {
        setProcessLabels(response.data);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Unexpected response from the server.',
          ...customAlertStyles.sweetAlert,
        });
        console.error('Unexpected response:', response.data);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch process labels.',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error fetching labels:', error);
    } finally {
      setLoading(false);
    }
  }, [hasPermission, navigate, API_BASE_URL]);

  useEffect(() => {
    fetchProcessLabels();
  }, [fetchProcessLabels]);

  // Add a process label
  const handleAddProcessLabel = async () => {
    if (!hasPermission('add_process-label-management')) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to add a process label.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!name.trim()) {
      setErrors({ name: 'Name is required.' });
      return;
    }

    setSaving(true);

    try {
      const token = getUserToken();
      const maxOrder = processLabels.length > 0 ? Math.max(...processLabels.map(label => label.order)) : 0;
      const newOrder = maxOrder + 1;
      const response = await axios.post(
        `${API_BASE_URL}/api/processlabels`,
        { name, order: newOrder },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const newLabel = response.data;
      setProcessLabels(prevLabels => [...prevLabels, newLabel]);
      setName('');
      setOpenAddDialog(false);
      setErrors({});
      Swal.fire({
        title: 'Success',
        text: 'Process label added successfully.',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.error || 'Failed to add process label.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error adding label:', error.response?.data);
    } finally {
      setSaving(false);
    }
  };

  // Edit a process label
  const handleEditProcessLabel = async () => {
    if (!hasPermission('edit_process-label-management')) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to edit this process label.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!name.trim()) {
      setErrors({ name: 'Name is required.' });
      return;
    }

    setSaving(true);

    try {
      const token = getUserToken();
      // Include the existing order number as well since the backend may require it
      const response = await axios.put(
        `${API_BASE_URL}/api/processlabels/${selectedLabel._id}`,
        { name, order: selectedLabel.order },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const updatedLabel = response.data;
      setProcessLabels(prevLabels =>
        prevLabels.map(label => (label._id === updatedLabel._id ? updatedLabel : label))
      );
      setSelectedLabel(null);
      setName('');
      setOpenEditDialog(false);
      setErrors({});
      Swal.fire({
        title: 'Success',
        text: 'Process label updated successfully.',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.error || 'Failed to update process label.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error updating label:', error.response?.data);
    } finally {
      setSaving(false);
    }
  };

  // Delete a process label
  const handleDeleteProcessLabel = async (label) => {
    if (!hasPermission('delete_process-label-management')) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to delete this process label.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    Swal.fire({
      title: `Delete ${label.name}?`,
      text: 'This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: red[500],
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      ...customAlertStyles.sweetAlert,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = getUserToken();
          await axios.delete(`${API_BASE_URL}/api/processlabels/${label._id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          setProcessLabels(prevLabels => prevLabels.filter(l => l._id !== label._id));

          Swal.fire({
            title: 'Deleted',
            text: 'Process label deleted successfully.',
            icon: 'success',
            ...customAlertStyles.sweetAlert,
          });
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: error.response?.data?.error || 'Failed to delete process label.',
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
          console.error('Error deleting label:', error.response?.data);
        }
      }
    });
  };

  // Handle drag-and-drop reordering
  const handleDragStart = (event, index) => {
    setDraggedIndex(index);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = async (event, dropIndex) => {
    event.preventDefault();
    if (draggedIndex === null || draggedIndex === dropIndex) return;

    const reorderedLabels = [...processLabels];
    const [movedLabel] = reorderedLabels.splice(draggedIndex, 1);
    reorderedLabels.splice(dropIndex, 0, movedLabel);

    // Update the order field for each label
    const updatedLabels = reorderedLabels.map((label, index) => ({
      ...label,
      order: index + 1,
    }));

    setProcessLabels(updatedLabels);
    setDraggedIndex(null);

    // Save the new order to the server
    try {
      const token = getUserToken();
      await axios.put(
        `${API_BASE_URL}/api/processlabels/reorder`,
        { labels: updatedLabels }, // Adjust the payload if the backend expects a different field name
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.error || 'Failed to reorder process labels.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error reordering labels:', error.response?.data);
    }
  };

  // Dialog handlers
  const handleOpenAddDialog = () => {
    setName('');
    setErrors({});
    setOpenAddDialog(true);
  };

  const handleOpenEditDialog = (label) => {
    if (!hasPermission('edit_process-label-management')) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to edit this process label.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    setSelectedLabel(label);
    setName(label.name);
    setOpenEditDialog(true);
    setErrors({});
  };

  return (
    <>
      <NavigationBar />
      <Box display="flex">
        <Sidebar />
        <Box flexGrow={1}>
          <Box sx={{ padding: '24px', marginTop: '64px' }}>
            <Typography variant="h5" sx={{ marginBottom: '24px' }}>
              Process Label Management
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenAddDialog}
                disabled={!hasPermission('add_process-label-management')}
                startIcon={<AddCircleIcon />}
                aria-label="Add Process Label Button"
              >
                Add Process Label
              </Button>
            </Box>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', height: '50vh' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Paper>
                <Box sx={{ padding: '16px' }}>
                  {processLabels.length === 0 ? (
                    <Typography>No process labels found.</Typography>
                  ) : (
                    <Box>
                      {processLabels
                        .sort((a, b) => a.order - b.order)
                        .map((label, index) => (
                          <Box
                            key={label._id}
                            sx={{
                              padding: '8px',
                              marginBottom: '4px',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                              backgroundColor: draggedIndex === index ? '#f0f0f0' : 'white',
                              cursor: 'move',
                            }}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, index)}
                            aria-label={`Drag ${label.name} Label`}
                          >
                            <DragIndicatorIcon />
                            <Typography sx={{ flexGrow: 1 }}>
                              {label.name} (Order: {label.order})
                            </Typography>
                            <IconButton
                              sx={{ color: purple[500] }}
                              onClick={() => handleOpenEditDialog(label)}
                              disabled={!hasPermission('edit_process-label-management')}
                              aria-label={`Edit ${label.name} Label`}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              sx={{ color: red[500] }}
                              onClick={() => handleDeleteProcessLabel(label)}
                              disabled={!hasPermission('delete_process-label-management')}
                              aria-label={`Delete ${label.name} Label`}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                    </Box>
                  )}
                </Box>
              </Paper>
            )}

            {/* Add Dialog */}
            <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)} maxWidth="sm" fullWidth>
              <DialogTitle>Add New Process Label</DialogTitle>
              <DialogContent>
                <Box sx={{ padding: 2 }}>
                  <TextField
                    required
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    aria-label="Process Label Name Input"
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenAddDialog(false)} aria-label="Cancel Add Process Label">
                  Cancel
                </Button>
                <Button
                  onClick={handleAddProcessLabel}
                  disabled={!name.trim() || saving}
                  variant="contained"
                  color="primary"
                  aria-label="Save New Process Label"
                >
                  {saving ? 'Saving...' : 'Add Process Label'}
                </Button>
              </DialogActions>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="sm" fullWidth>
              <DialogTitle>Edit Process Label</DialogTitle>
              <DialogContent>
                <Box sx={{ padding: 2 }}>
                  <TextField
                    required
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    aria-label="Edit Process Label Name Input"
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenEditDialog(false)} aria-label="Cancel Edit Process Label">
                  Cancel
                </Button>
                <Button
                  onClick={handleEditProcessLabel}
                  disabled={!name.trim() || saving}
                  variant="contained"
                  color="primary"
                  aria-label="Save Edited Process Label"
                >
                  {saving ? 'Saving...' : 'Save Changes'}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default () => (
  <ErrorBoundary>
    <ProcessLabelManagementScreen />
  </ErrorBoundary>
);