const customAlertStyles = {
  primaryColor: '#258EA6',  // Primary color
  secondaryColor: '#549F93',  // Secondary color
  tertiaryColor: '#153243',  // Tertiary color
  backgroundColor: '#FFFFFF',  // Background color
  textColor: '#EAEAEA',  // Text color
  sweetAlert: {
    background: 'linear-gradient(135deg, #258EA6, #549F93)',
    color: '#FFFFFF',
    confirmButtonColor: '#258EA6',
    confirmButtonText: 'OK',
    confirmButtonClass: 'custom-confirm-button'
  },
  primaryHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#153243',
    marginBottom: '20px'
  },
  subHeader: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#549F93',
    marginBottom: '15px'
  },
  input: {
    marginBottom: '10px'
  },
  button: {
    backgroundColor: '#258EA6',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#549F93'
    }
  }
};

export default customAlertStyles;
