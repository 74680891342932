// CAREPLANAIv5/packages/web/src/utils/api.js

import axios from 'axios';

export const getUserToken = () => {
  return localStorage.getItem('userToken');
};

export const fetchUserData = async (token) => {
  const response = await axios.get('http://localhost:3000/api/users/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const updateUserData = async (token, userData) => {
  const response = await axios.put('http://localhost:3000/api/users/me', userData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
