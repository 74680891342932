// src/components/templateBuilder/TemplatePreviewModal.jsx

import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
} from '@mui/material';
import ErrorBoundary from '../ErrorBoundary'; // Adjusted the import path
import { useAuth } from '../../context/AuthContext';
import Swal from 'sweetalert2';
import customAlertStyles from '../../styles/CustomAlertStyles';

const TemplatePreviewModal = ({ fields }) => {
  const { permissions = [], loading: authLoading } = useAuth();

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  if (authLoading) {
    return null; // Or a loading spinner
  }

  if (!hasViewPermission) {
    Swal.fire({
      title: 'Access Denied',
      text: 'You do not have permission to view this content.',
      icon: 'error',
      ...customAlertStyles.sweetAlert,
    });
    return null;
  }

  return (
    <Box>
      {fields.map((field, index) => (
        <Box key={index} sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {field.label}
          </Typography>
          {/* Render a simple representation of the field based on its type */}
          {field.type === 'text' && (
            <TextField variant="outlined" fullWidth disabled placeholder="Text Input" />
          )}
          {field.type === 'textarea' && (
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              disabled
              placeholder="Text Area"
            />
          )}
          {field.type === 'select' && (
            <FormControl fullWidth variant="outlined" disabled>
              <InputLabel>{field.label}</InputLabel>
              <Select label={field.label}>
                {field.options &&
                  field.options.map((option, idx) => (
                    <MenuItem key={idx} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {field.type === 'checkbox' && (
            <FormControlLabel control={<Checkbox disabled />} label="Checkbox" />
          )}
          {field.type === 'radio' && (
            <FormControl component="fieldset">
              {field.options &&
                field.options.map((option, idx) => (
                  <FormControlLabel
                    key={idx}
                    control={<Radio disabled />}
                    label={option.label}
                  />
                ))}
            </FormControl>
          )}
          {field.type === 'date' && (
            <TextField variant="outlined" type="date" fullWidth disabled />
          )}
          {field.type === 'number' && (
            <TextField
              variant="outlined"
              type="number"
              fullWidth
              disabled
              placeholder="Number Input"
            />
          )}
          {field.type === 'switch' && (
            <FormControlLabel control={<Switch disabled />} label="Toggle Switch" />
          )}
          {/* Add more field types as necessary */}
        </Box>
      ))}
    </Box>
  );
};

// Wrap the component with ErrorBoundary
const WrappedTemplatePreviewModal = (props) => (
  <ErrorBoundary>
    <TemplatePreviewModal {...props} />
  </ErrorBoundary>
);

export default WrappedTemplatePreviewModal;