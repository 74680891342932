// src/components/templateBuilder/TemplateBuilder.jsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  FormHelperText,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import TemplatePreview from './TemplatePreview';
import ErrorBoundary from '../ErrorBoundary';
import Swal from 'sweetalert2';
import customAlertStyles from '../../styles/CustomAlertStyles';
import { useAuth } from '../../context/AuthContext';

const TemplateBuilder = ({ fields, setFields }) => {
  const [fieldLabel, setFieldLabel] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [selectedField, setSelectedField] = useState(null);
  const [openCustomizeField, setOpenCustomizeField] = useState(false);
  const [errors, setErrors] = useState({});
  const [masterLists, setMasterLists] = useState([]);
  const [customTables, setCustomTables] = useState([]);
  const [openNewListDialog, setOpenNewListDialog] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [newListOptions, setNewListOptions] = useState([{ label: '', value: '' }]);
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const [charCount, setCharCount] = useState(0);

  const { permissions = [], loading: authLoading } = useAuth();

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const handleMenuClick = (event, field) => {
    setAnchorEl(event.currentTarget);
    setCurrentField(field);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentField(null);
  };

  const handleDuplicate = () => {
    if (currentField) {
      const duplicatedField = { ...currentField, isNew: true, _id: undefined };
      setFields([...fields, duplicatedField]);
    }
    handleMenuClose();
  };

  const handleRemoveOrArchive = () => {
    if (currentField) {
      if (currentField.isNew) {
        removeLocalField(currentField._id);
      } else {
        setFields((prevFields) =>
          prevFields.map((f) => (f._id === currentField._id ? { ...f, state: 'Archived' } : f))
        );
      }
    }
    handleMenuClose();
  };

  const handleReactivate = () => {
    if (currentField) {
      const updatedFields = fields.map((f) =>
        f._id === currentField._id ? { ...f, state: 'Active' } : f
      );
      setFields(updatedFields);
    }
    handleMenuClose();
  };

  const fetchMasterLists = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/masterlists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMasterLists(response.data);
    } catch (error) {
      console.error('Error fetching master lists:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch master lists.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  const fetchCustomTables = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(`${API_BASE_URL}/api/customtables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomTables(response.data);
    } catch (error) {
      console.error('Error fetching custom tables:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch custom tables.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    if (!authLoading && hasViewPermission) {
      fetchMasterLists();
      fetchCustomTables();
    } else if (!authLoading && !hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [authLoading, hasViewPermission, fetchMasterLists, fetchCustomTables]);

  const addField = () => {
    if (!fieldLabel) {
      setErrors({ ...errors, fieldLabel: 'Field label is required' });
      return;
    }

    setFields([
      ...fields,
      {
        // _id will be assigned by Mongoose
        label: fieldLabel,
        type: fieldType,
        isNew: true,
        state: 'Active',
      },
    ]);
    setFieldLabel('');
    setFieldType('');
    setErrors({});
    setCharCount(0);
  };

  const openFieldCustomization = (field) => {
    setSelectedField(field);
    setOpenCustomizeField(true);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedField({ ...selectedField, [name]: value });
  };

  const saveFieldCustomization = () => {
    setFields(fields.map((f) => (f._id === selectedField._id ? selectedField : f)));
    setOpenCustomizeField(false);
    setSelectedField(null);
  };

  const removeLocalField = (_id) => {
    setFields(fields.filter((field) => field._id !== _id));
  };

  const archiveField = (_id) => {
    setFields((prevFields) =>
      prevFields.map((f) => (f._id === _id ? { ...f, state: 'Archived' } : f))
    );
  };

  const handleDragStart = (event, field) => {
    event.dataTransfer.setData('field', JSON.stringify(field));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedField = JSON.parse(event.dataTransfer.getData('field'));
    const updatedFields = fields.filter((f) => f._id !== droppedField._id);

    // Calculate the new index based on the drop position
    const dropTarget = event.target.closest('.field-item');
    const dropIndex = Array.from(dropTarget.parentNode.children).indexOf(dropTarget);

    updatedFields.splice(dropIndex, 0, droppedField);
    setFields(updatedFields);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleNewListOptionChange = (index, key, value) => {
    const updatedOptions = [...newListOptions];
    updatedOptions[index][key] = value;
    setNewListOptions(updatedOptions);
  };

  const addNewListOption = () => {
    setNewListOptions([...newListOptions, { label: '', value: '' }]);
  };

  const removeNewListOption = (index) => {
    const updatedOptions = newListOptions.filter((_, idx) => idx !== index);
    setNewListOptions(updatedOptions);
  };

  const saveNewList = async () => {
    const options = newListOptions;
    const masterListData = { name: newListName, options };
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.post(`${API_BASE_URL}/api/masterlists`, masterListData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const newMasterList = response.data.masterList;
      setMasterLists([...masterLists, newMasterList]);
      setNewListName('');
      setNewListOptions([{ label: '', value: '' }]);
      setOpenNewListDialog(false);

      if (selectedField) {
        setSelectedField({ ...selectedField, masterList: newMasterList._id });
      }
    } catch (error) {
      console.error('Error adding master list:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to save new master list.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  const filteredMasterLists = masterLists.filter((list) =>
    list.name.toLowerCase().includes(search.toLowerCase())
  );

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (!hasViewPermission) {
    return null; // Return null or redirect if the user doesn't have permission
  }

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography variant="h6">Add Elements</Typography>
      <Box display="flex" gap="16px">
        <FormControl fullWidth error={Boolean(errors.fieldLabel)}>
          <TextField
            label="Field Label"
            value={fieldLabel}
            onChange={(e) => {
              setFieldLabel(e.target.value);
              setCharCount(e.target.value.length);
            }}
            inputProps={{ maxLength: 250 }}
          />
          {errors.fieldLabel ? (
            <FormHelperText>{errors.fieldLabel}</FormHelperText>
          ) : (
            <FormHelperText>{`${charCount}/250 characters`}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Field Type</InputLabel>
          <Select
            value={fieldType}
            onChange={(e) => setFieldType(e.target.value)}
            label="Field Type"
          >
            <MenuItem value="header">Header</MenuItem>
            <MenuItem value="bodyText">Body Text</MenuItem>
            <MenuItem value="checkbox">Checkbox</MenuItem>
            <MenuItem value="date">Date Picker</MenuItem>
            <MenuItem value="time">Time Picker</MenuItem>
            <MenuItem value="fileUpload">File Upload</MenuItem>
            <MenuItem value="textarea">Large Text</MenuItem>
            <MenuItem value="nested">Custom Table</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="slider">Slider</MenuItem>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="select">Select</MenuItem>
            <MenuItem value="radio">Radio Button</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={addField}>
          Add Field
        </Button>
      </Box>
      <Box mt={4}>
        <Typography variant="h6">Arrange Elements</Typography>
        <Paper
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          sx={{
            padding: '16px',
            minHeight: '200px',
            maxWidth: '100%',
            overflowX: 'auto',
          }}
        >
          {fields.map((field, index) => (
            <Box
              key={field._id || index}
              className="field-item"
              draggable
              onDragStart={(event) => handleDragStart(event, field)}
              sx={{
                padding: '8px',
                marginBottom: '4px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                backgroundColor: field.state === 'Archived' ? '#d3d3d3' : 'transparent',
                maxWidth: '100%',
              }}
            >
              <Box>
                <DragIndicatorIcon />
              </Box>
              <Box
                onClick={() => openFieldCustomization(field)}
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="body1">
                  {field.label.length > 100
                    ? `${field.label.substring(0, 100)}...`
                    : field.label}{' '}
                  ({field.type})
                </Typography>
              </Box>
              <IconButton onClick={(e) => handleMenuClick(e, field)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && currentField?._id === field._id}
                onClose={handleMenuClose}
              >
                {currentField?.state === 'Archived' ? (
                  <MenuItem onClick={handleReactivate}>Reactivate</MenuItem>
                ) : (
                  <>
                    <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
                    <MenuItem onClick={handleRemoveOrArchive}>
                      {currentField?.isNew ? 'Remove' : 'Archive'}
                    </MenuItem>
                  </>
                )}
              </Menu>
            </Box>
          ))}
        </Paper>
      </Box>

      <Typography variant="h6" mt={4}>
        Template Preview
      </Typography>
      <TemplatePreview fields={fields} masterLists={masterLists} customTables={customTables} />

      {/* Field Customization Dialog */}
      <Dialog open={openCustomizeField} onClose={() => setOpenCustomizeField(false)}>
        <DialogTitle>Customize Field</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            fullWidth
            label="Field Label"
            name="label"
            value={selectedField?.label || ''}
            onChange={handleFieldChange}
            inputProps={{ maxLength: 250 }}
          />
          {selectedField?.type === 'checkbox' && (
            <TextField
              margin="normal"
              fullWidth
              label="Check List Option"
              name="checkListOption"
              value={selectedField?.checkListOption || ''}
              onChange={handleFieldChange}
            />
          )}
          {selectedField?.type === 'fileUpload' && (
            <TextField
              margin="normal"
              fullWidth
              label="File Types"
              name="fileTypes"
              value={selectedField?.fileTypes || ''}
              onChange={handleFieldChange}
            />
          )}
          {selectedField?.type === 'textarea' && (
            <TextField
              margin="normal"
              fullWidth
              label="Number of Lines"
              name="numLines"
              type="number"
              value={selectedField?.numLines || 4}
              onChange={handleFieldChange}
              inputProps={{ min: 4, max: 12 }}
            />
          )}
          {(selectedField?.type === 'select' || selectedField?.type === 'radio') && (
            <Box>
              <Button onClick={() => setOpenNewListDialog(true)}>Create New List</Button>
              <Typography variant="h6">Import Existing List</Typography>
              <TextField
                fullWidth
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ marginBottom: '16px' }}
              />
              <FormControl fullWidth>
                <InputLabel>Select Master List</InputLabel>
                <Select
                  fullWidth
                  name="masterList"
                  value={selectedField?.masterList || ''}
                  onChange={handleFieldChange}
                >
                  {filteredMasterLists.length > 0 ? (
                    filteredMasterLists.map((list) => (
                      <MenuItem key={list._id} value={list._id}>
                        {list.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      No lists available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          )}
          {selectedField?.type === 'nested' && (
            <Box>
              <InputLabel>Select Custom Table</InputLabel>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  name="customTable"
                  value={selectedField?.customTable || ''}
                  onChange={handleFieldChange}
                >
                  {customTables.map((table) => (
                    <MenuItem key={table._id} value={table._id}>
                      {table.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCustomizeField(false)}>Cancel</Button>
          <Button onClick={saveFieldCustomization}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* New List Dialog */}
      <Dialog open={openNewListDialog} onClose={() => setOpenNewListDialog(false)}>
        <DialogTitle>Create New List</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            fullWidth
            label="List Name"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
          />
          {newListOptions.map((option, index) => (
            <Box key={index} display="flex" gap="8px" alignItems="center">
              <TextField
                margin="normal"
                fullWidth
                label={`Option ${index + 1} Label`}
                value={option.label}
                onChange={(e) => handleNewListOptionChange(index, 'label', e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                label={`Option ${index + 1} Value`}
                value={option.value}
                onChange={(e) => handleNewListOptionChange(index, 'value', e.target.value)}
              />
              <IconButton onClick={() => removeNewListOption(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={addNewListOption}>Add Another Option</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewListDialog(false)}>Cancel</Button>
          <Button onClick={saveNewList}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Wrap the component with ErrorBoundary
const WrappedTemplateBuilder = (props) => (
  <ErrorBoundary>
    <TemplateBuilder {...props} />
  </ErrorBoundary>
);

export default WrappedTemplateBuilder;