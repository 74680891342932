// src/screens/AssignScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Collapse,
  IconButton,
  TextField,
  Tooltip,
  Pagination,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import customAlertStyles from '../styles/CustomAlertStyles';
import './SweetAlertCustom.css';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

// Import icons directly
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const MySwal = withReactContent(Swal);

const AssignScreen = ({ templateDetails, setTemplateDetails }) => {
  const [selection, setSelection] = useState('clients'); // Default to 'clients'
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [careEntities, setCareEntities] = useState([]);
  const [roles, setRoles] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState(templateDetails.assignedUsers || []);
  const [assignedClients, setAssignedClients] = useState(templateDetails.assignedClients || []);
  const [assignedCareEntities, setAssignedCareEntities] = useState(
    templateDetails.assignedCareEntities || []
  );
  const [localAssignedRoles, setLocalAssignedRoles] = useState(
    templateDetails.assignedRoles || []
  );
  const [permissionsForRoles, setPermissionsForRoles] = useState(
    templateDetails.permissionsForRoles || {}
  ); // Store permissions for each role locally
  const [userSearch, setUserSearch] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const [careEntitySearch, setCareEntitySearch] = useState('');
  const [expanded, setExpanded] = useState({
    assignmentOptions: true,
    roles: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 25;

  const carePlanTemplateID = templateDetails._id; // Use the actual template ID
  const actions = ['view', 'add', 'edit', 'delete']; // Actions for role permissions

  const { permissions = [], loading: authLoading } = useAuth();

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  // Fetch data for users, clients, care entities, and roles
  const fetchUsers = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_BASE_URL}/api/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setUsers(data);
      console.log('Fetched users:', data); // Log fetched users
    } catch (error) {
      console.error('Error fetching users:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch users.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  const fetchClients = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_BASE_URL}/api/clients`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setClients(data);
      console.log('Fetched clients:', data); // Log fetched clients
    } catch (error) {
      console.error('Error fetching clients:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch clients.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  const fetchCareEntities = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_BASE_URL}/api/careentities`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setCareEntities(data);
      console.log('Fetched care entities:', data); // Log fetched care entities
    } catch (error) {
      console.error('Error fetching care entities:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch care entities.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  const fetchRoles = useCallback(async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_BASE_URL}/api/roles/permissions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setRoles(data);
      console.log('Fetched roles:', data); // Log fetched roles
    } catch (error) {
      console.error('Error fetching roles:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Failed to fetch roles.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [API_BASE_URL]);

  // Process roles to extract existing permissions for the current template
  useEffect(() => {
    if (carePlanTemplateID && roles.length > 0) {
      console.log('Processing roles for the existing permissions');
      const updatedPermissionsForRoles = {};

      roles.forEach((role) => {
        const rolePermissions = role.permissions || [];
        const templatePermissions = rolePermissions.filter((permission) =>
          permission.endsWith(`_${carePlanTemplateID}`)
        );

        if (templatePermissions.length > 0) {
          const actionsForRole = templatePermissions.map((permission) => {
            const [action] = permission.split('_');
            return action;
          });
          updatedPermissionsForRoles[role._id] = actionsForRole.map(
            (action) => `${action}_TEMPLATE_ID`
          );
        }
      });

      console.log('Updated permissions for roles:', updatedPermissionsForRoles);
      setPermissionsForRoles((prev) => ({ ...prev, ...updatedPermissionsForRoles }));
    }
  }, [carePlanTemplateID, roles]);

  useEffect(() => {
    if (!authLoading && hasViewPermission) {
      // Optional: Remove this log if not needed
      console.log('Initial Template Details:', templateDetails);
      fetchUsers();
      fetchClients();
      fetchCareEntities();
      fetchRoles();
    } else if (!authLoading && !hasViewPermission) {
      MySwal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  }, [
    authLoading,
    hasViewPermission,
    fetchUsers,
    fetchClients,
    fetchCareEntities,
    fetchRoles,
    // Removed templateDetails from dependency array
  ]);

  // Handle permission changes for roles (locally only)
  const handlePermissionChange = (role, action, checked) => {
    const permissionKey = `${action}_TEMPLATE_ID`; // Use placeholder 'TEMPLATE_ID'
    console.log(`Updating permission for ${role.name}: ${action} set to ${checked}`); // Log permission update

    setPermissionsForRoles((prev) => {
      const updatedPermissions = checked
        ? [...(prev[role._id] || []), permissionKey]
        : (prev[role._id] || []).filter((permission) => permission !== permissionKey);

      console.log('Updated permissions:', updatedPermissions); // Log updated permissions

      return {
        ...prev,
        [role._id]: updatedPermissions, // Store permissions for each role based on its ID
      };
    });
  };

  // Handle "Assign All" functionality
  const handleAssignAll = (records, assigned, setAssigned) => {
    if (assigned.length === records.length) {
      // Unassign all
      setAssigned([]);
    } else {
      // Assign all
      const allIds = records.map((record) => record._id);
      setAssigned(allIds);
    }
  };

  // Handle sorting and pagination
  const getSortedRecords = (records, assigned, searchField) => {
    const filteredRecords = records.filter((record) => {
      const name = record.name || `${record.firstName} ${record.lastName}`;
      return name.toLowerCase().includes(searchField.toLowerCase());
    });

    // Sort assigned records first, then alphabetical
    const assignedRecords = filteredRecords.filter((record) => assigned.includes(record._id));
    const unassignedRecords = filteredRecords.filter((record) => !assigned.includes(record._id));

    assignedRecords.sort((a, b) => {
      const nameA = (a.name || `${a.firstName} ${a.lastName}`).toLowerCase();
      const nameB = (b.name || `${b.firstName} ${b.lastName}`).toLowerCase();
      return nameA.localeCompare(nameB);
    });

    unassignedRecords.sort((a, b) => {
      const nameA = (a.name || `${a.firstName} ${a.lastName}`).toLowerCase();
      const nameB = (b.name || `${b.firstName} ${b.lastName}`).toLowerCase();
      return nameA.localeCompare(nameB);
    });

    return [...assignedRecords, ...unassignedRecords];
  };

  const renderTable = (records, assigned, setAssigned, searchField, setSearchField) => {
    console.log('Rendering table with records:', records); // Log the records to be displayed

    const sortedRecords = getSortedRecords(records, assigned, searchField);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = sortedRecords.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(sortedRecords.length / recordsPerPage);

    return (
      <Collapse in={expanded.assignmentOptions} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
            <TextField
              variant="outlined"
              placeholder="Search..."
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              sx={{ width: '70%' }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={assigned.length === records.length}
                  onChange={() => handleAssignAll(sortedRecords, assigned, setAssigned)}
                />
              }
              label="Assign All"
            />
          </Box>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRecords.map((record) => (
                <TableRow key={record._id}>
                  <TableCell>{record.name || `${record.firstName} ${record.lastName}`}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={assigned.includes(record._id)}
                      onChange={(e) => {
                        const { checked } = e.target;
                        setAssigned((prev) =>
                          checked ? [...prev, record._id] : prev.filter((id) => id !== record._id)
                        );
                        console.log(`Assignment updated for ${record._id}: ${checked}`); // Log the assignment change
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(e, value) => setCurrentPage(value)}
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </TableContainer>
      </Collapse>
    );
  };

  const renderAssignmentOptions = () => {
    console.log('Current selection:', selection); // Log the current selection (users, clients, or care entities)
    let records = [];
    let assigned = [];
    let setAssigned;
    let searchField;
    let setSearchField;

    if (selection === 'users') {
      records = users;
      assigned = assignedUsers;
      setAssigned = setAssignedUsers;
      searchField = userSearch;
      setSearchField = setUserSearch;
    } else if (selection === 'clients') {
      records = clients;
      assigned = assignedClients;
      setAssigned = setAssignedClients;
      searchField = clientSearch;
      setSearchField = setClientSearch;
    } else if (selection === 'careEntities') {
      records = careEntities;
      assigned = assignedCareEntities;
      setAssigned = setAssignedCareEntities;
      searchField = careEntitySearch;
      setSearchField = setCareEntitySearch;
    } else {
      return null;
    }

    return renderTable(records, assigned, setAssigned, searchField, setSearchField);
  };

  const renderRoleAssignment = () => {
    console.log('Rendering role assignment for roles:', roles); // Log roles before rendering

    // Sort roles
    const assignedRoleIds = Object.keys(permissionsForRoles);
    const assignedRolesList = roles.filter((role) => assignedRoleIds.includes(role._id));
    const unassignedRolesList = roles.filter((role) => !assignedRoleIds.includes(role._id));

    assignedRolesList.sort((a, b) => a.name.localeCompare(b.name));
    unassignedRolesList.sort((a, b) => a.name.localeCompare(b.name));

    const sortedRoles = [...assignedRolesList, ...unassignedRolesList];

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRoles = sortedRoles.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(sortedRoles.length / recordsPerPage);

    return (
      <Box sx={{ marginTop: '32px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Provide Access</Typography>
          <IconButton
            onClick={() => setExpanded((prev) => ({ ...prev, roles: !prev.roles }))}
          >
            {expanded.roles ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>

        <Collapse in={expanded.roles} timeout="auto" unmountOnExit>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Role</TableCell>
                  {actions.map((action) => (
                    <TableCell key={action}>
                      {action.charAt(0).toUpperCase() + action.slice(1)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRoles.map((role) => {
                  const hasPermissions =
                    permissionsForRoles[role._id] && permissionsForRoles[role._id].length > 0;
                  return (
                    <TableRow key={role._id}>
                      <TableCell>{role.name}</TableCell>
                      {actions.map((action) => {
                        const permissionKey = `${action}_TEMPLATE_ID`; // Use placeholder
                        const hasPermission = (permissionsForRoles[role._id] || []).includes(
                          permissionKey
                        );

                        return (
                          <TableCell key={action}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={hasPermission}
                                  onChange={(e) =>
                                    handlePermissionChange(role, action, e.target.checked)
                                  }
                                />
                              }
                              label=""
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, value) => setCurrentPage(value)}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </TableContainer>
        </Collapse>
      </Box>
    );
  };

  // Update templateDetails when any assignment changes
  useEffect(() => {
    const updatedDetails = {
      ...templateDetails,
      assignedClients,
      assignedUsers,
      assignedCareEntities,
      assignedRoles: localAssignedRoles, // Update assigned roles locally
      permissionsForRoles, // Include permissions for roles
    };
    console.log('Updated template details:', updatedDetails); // Log updated template details
    setTemplateDetails(updatedDetails);
  }, [
    assignedClients,
    assignedUsers,
    assignedCareEntities,
    localAssignedRoles,
    permissionsForRoles,
    setTemplateDetails,
    templateDetails,
  ]);

  // Determine if there is data assigned to users or care entities
  const hasAssignedUsers = assignedUsers.length > 0;
  const hasAssignedCareEntities = assignedCareEntities.length > 0;

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '24px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        Assign CarePlan Template
      </Typography>

      <FormControl component="fieldset" sx={{ marginBottom: '24px' }}>
        <FormLabel component="legend">Assign to</FormLabel>
        <RadioGroup value={selection} onChange={(e) => setSelection(e.target.value)}>
          <FormControlLabel
            value="users"
            control={<Radio disabled />}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: hasAssignedUsers ? 'primary.main' : 'text.secondary',
                }}
              >
                Users
                {hasAssignedUsers && (
                  <Tooltip title="Users have assigned data">
                    <IconButton size="small" sx={{ marginLeft: '4px' }}>
                      <HelpOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            }
            disabled
          />
          <FormControlLabel value="clients" control={<Radio />} label="Clients" />
          <FormControlLabel
            value="careEntities"
            control={<Radio disabled />}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: hasAssignedCareEntities ? 'primary.main' : 'text.secondary',
                }}
              >
                Care Entities
                {hasAssignedCareEntities && (
                  <Tooltip title="Care Entities have assigned data">
                    <IconButton size="small" sx={{ marginLeft: '4px' }}>
                      <HelpOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            }
            disabled
          />
        </RadioGroup>
      </FormControl>

      {/* Expand/Collapse Assignment */}
      <Box sx={{ marginBottom: '16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            {selection.charAt(0).toUpperCase() + selection.slice(1)}
          </Typography>
          <IconButton
            onClick={() =>
              setExpanded((prev) => ({ ...prev, assignmentOptions: !prev.assignmentOptions }))
            }
          >
            {expanded.assignmentOptions ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        {renderAssignmentOptions()}
      </Box>

      {renderRoleAssignment()}
    </Box>
  );
};

// Wrap the component with ErrorBoundary
const WrappedAssignScreen = (props) => (
  <ErrorBoundary>
    <AssignScreen {...props} />
  </ErrorBoundary>
);

export default WrappedAssignScreen;