// src/screens/DynamicCarePlanScreen.js

import React from 'react';
import { Box } from '@mui/material';
import Sidebar from '../components/Sidebar';
import DynamicCarePlan from '../components/DynamicCarePlan'; // Import the updated DynamicCarePlan component
import { useParams } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';

const DynamicCarePlanScreen = () => {
  const { templateID, clientID } = useParams();

  return (
    <ErrorBoundary>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <Sidebar />
        <Box sx={{ flexGrow: 1, marginTop: '64px', display: 'flex', flexDirection: 'column' }}>
          <DynamicCarePlan templateID={templateID} clientID={clientID} />
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default DynamicCarePlanScreen;