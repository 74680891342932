// src/screens/PasswordAndSecurity.jsx

import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Button, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import axios from 'axios';
import customAlertStyles from '../styles/CustomAlertStyles';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';

const checkPasswordStrength = (password) => {
  const minLength = password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*]/.test(password);

  return minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
};

const PasswordAndSecurity = () => {
  const [user, setUser] = useState({
    _id: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isPasswordStrong, setIsPasswordStrong] = useState(true);

  const { loading: authLoading, user: authUser } = useAuth();

  useEffect(() => {
    if (!authLoading && authUser) {
      setUser((prevState) => ({
        ...prevState,
        _id: authUser._id,
      }));
    }
  }, [authLoading, authUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedUser = { ...user, [name]: value };

    setUser(updatedUser);

    if (name === 'newPassword') {
      // Check password strength when typing the new password
      setIsPasswordStrong(checkPasswordStrength(value));
    }

    if (name === 'newPassword' || name === 'confirmNewPassword') {
      setPasswordsMatch(updatedUser.newPassword === updatedUser.confirmNewPassword);
    }
  };

  const handlePasswordChange = async () => {
    const { currentPassword, newPassword, confirmNewPassword, _id } = user;

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      Swal.fire({
        title: 'Error',
        text: 'All fields are required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!passwordsMatch) {
      Swal.fire({
        title: 'Error',
        text: 'New passwords do not match.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!isPasswordStrong) {
      Swal.fire({
        title: 'Error',
        text: 'Password is not strong enough. Please ensure it meets all criteria.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/${_id}/change-password`,
        { currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          title: 'Success',
          text: 'Password changed successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
        setUser({ ...user, currentPassword: '', newPassword: '', confirmNewPassword: '' });
        setPasswordsMatch(true);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Failed to change password.',
          icon: 'error',
          ...customAlertStyles.sweetAlert,
        });
      }
    } catch (error) {
      console.error('Error changing password:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <>
        <Navbar />
        <Box sx={{ display: 'flex' }}>
          <Sidebar />
          <Box flexGrow={1}>
            <Box sx={{ padding: '24px', marginTop: '64px' }}>
              <Typography component="h1" variant="h5" sx={{ marginBottom: '16px' }}>
                Password and Security
              </Typography>

              {/* Password Strength Guide */}
              <Typography variant="body1" sx={{ marginBottom: '16px' }}>
                Ensure your password is strong. A strong password contains:
                <ul>
                  <li>At least 8 characters</li>
                  <li>At least one uppercase letter (A-Z)</li>
                  <li>At least one lowercase letter (a-z)</li>
                  <li>At least one number (0-9)</li>
                  <li>At least one special character (e.g., !@#$%^&*)</li>
                </ul>
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '50%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Current Password"
                      name="currentPassword"
                      type="password"
                      value={user.currentPassword || ''}
                      onChange={handleInputChange}
                      fullWidth
                      aria-label="Current Password"
                      sx={{ marginBottom: '16px' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="New Password"
                      name="newPassword"
                      type="password"
                      value={user.newPassword || ''}
                      onChange={handleInputChange}
                      fullWidth
                      error={!isPasswordStrong}
                      helperText={!isPasswordStrong ? 'Password is not strong enough' : ''}
                      aria-label="New Password"
                      sx={{ marginBottom: '16px' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Confirm New Password"
                      name="confirmNewPassword"
                      type="password"
                      value={user.confirmNewPassword || ''}
                      onChange={handleInputChange}
                      fullWidth
                      error={!passwordsMatch && !!user.confirmNewPassword}
                      helperText={
                        !passwordsMatch && !!user.confirmNewPassword ? 'Passwords do not match' : ''
                      }
                      aria-label="Confirm New Password"
                      sx={{ marginBottom: '16px' }}
                    />
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePasswordChange}
                  sx={{ width: '150px' }}
                  aria-label="Save Password"
                  disabled={
                    !passwordsMatch ||
                    !user.newPassword ||
                    !user.confirmNewPassword ||
                    !isPasswordStrong
                  }
                >
                  Save Password
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    </ErrorBoundary>
  );
};

export default PasswordAndSecurity;