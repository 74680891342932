// src/screens/WorkflowScreen.jsx

import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';

const WorkflowScreen = ({ templateDetails, setTemplateDetails }) => {
  const { permissions = [], loading: authLoading } = useAuth();

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (!hasViewPermission) {
    Swal.fire({
      title: 'Access Denied',
      text: 'You do not have permission to view this page.',
      icon: 'error',
      ...customAlertStyles.sweetAlert,
    });
    return null;
  }

  return (
    <Box sx={{ padding: '24px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        Workflow Configuration
      </Typography>
      <Box
        sx={{
          marginTop: '32px',
          padding: '24px',
          border: '1px dashed grey',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" color="textSecondary">
          Coming Soon!
        </Typography>
        <Typography variant="body1" color="textSecondary">
          The Workflow Configuration screen is under development. Please check back later.
        </Typography>
      </Box>
    </Box>
  );
};

// Wrap the component with ErrorBoundary
const WrappedWorkflowScreen = (props) => (
  <ErrorBoundary>
    <WorkflowScreen {...props} />
  </ErrorBoundary>
);

export default WrappedWorkflowScreen;