// src/screens/DynamicCarePlan.jsx

import React, { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  Pagination,
} from '@mui/material';
import MuiSlider from '@mui/material/Slider';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import customAlertStyles from '../styles/CustomAlertStyles';
import ErrorBoundary from '../components/ErrorBoundary';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const DynamicCarePlan = ({ clientID, templateID }) => {
  // State Variables
  const [carePlanTemplate, setCarePlanTemplate] = useState({ fields: [] });
  const [activeFields, setActiveFields] = useState([]);
  const [archivedFields, setArchivedFields] = useState([]);
  const [carePlanData, setCarePlanData] = useState([]);
  const [newEntry, setNewEntry] = useState({});
  const [isViewingPastRecord, setIsViewingPastRecord] = useState(false);
  const [createdAt, setCreatedAt] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [version, setVersion] = useState('1.0');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 15;
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  // Fetch Care Plan Template and Data
  const fetchCarePlan = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('userToken');
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Fetch Care Plan Template
      const templateResponse = await axios.get(
        `${API_BASE_URL}/api/careplantemplates/${templateID}`,
        { headers }
      );

      let templateFields = [];

      if (templateResponse.data && templateResponse.data.fields) {
        setCarePlanTemplate(templateResponse.data);
        const active = templateResponse.data.fields.filter(
          (field) => field.state !== 'Archived'
        );
        const archived = templateResponse.data.fields.filter(
          (field) => field.state === 'Archived'
        );
        setActiveFields(active);
        setArchivedFields(archived);
        setVersion(templateResponse.data.version || '1.0');

        templateFields = templateResponse.data.fields; // Assign to local variable
      } else {
        setCarePlanTemplate({ fields: [] });
        setActiveFields([]);
        setArchivedFields([]);
      }

      // Fetch Care Plan Data
      const dataResponse = await axios.get(
        `${API_BASE_URL}/api/careplandata/searchByClientAndTemplate`,
        {
          headers,
          params: {
            clientID,
            templateID,
          },
        }
      );

      if (Array.isArray(dataResponse.data) && dataResponse.data.length > 0) {
        // Sort data in descending order (most recent first)
        const sortedData = dataResponse.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCarePlanData(sortedData);
        setFilteredData(sortedData);

        // Log data for debugging
        console.log('Care Plan Data:', sortedData);
      } else {
        setCarePlanData([]);
        setFilteredData([]);
      }

      // Log template fields and data entries for debugging
      console.log(
        'Template Field IDs:',
        templateFields.map((field) => field._id.toString())
      );
      console.log(
        'Data Entries Field IDs:',
        dataResponse.data.map((entry) => entry.data.map((d) => d.fieldID.toString()))
      );
    } catch (error) {
      console.error('Error fetching care plan data:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to load care plan data. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setLoading(false);
    }
  }, [clientID, templateID]);

  useEffect(() => {
    fetchCarePlan();
  }, [fetchCarePlan]);

  // Handle Save New Entry
  const handleSave = async () => {
    setSaving(true);
    try {
      const token = localStorage.getItem('userToken');

      const formattedData = activeFields.map((field) => ({
        fieldID: field._id.toString(),
        value: newEntry[field._id.toString()] || '',
      }));

      await axios.post(
        `${API_BASE_URL}/api/careplandata`,
        {
          templateID,
          clientID,
          data: formattedData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Swal.fire({
        title: 'Success',
        text: 'New entry added successfully!',
        icon: 'success',
        ...customAlertStyles.sweetAlert,
      });

      // Reset State to New Entry Form
      setNewEntry({});
      setIsViewingPastRecord(false);
      setCreatedAt(null);
      setLastUpdate(null);
      setVersion('1.0');
      setCurrentPage(1);

      // Refetch Data to Update Timeline
      await fetchCarePlan();
    } catch (error) {
      console.error('Error saving entry:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while saving the entry.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    } finally {
      setSaving(false);
    }
  };

  // Handle Field Change
  const handleFieldChange = (fieldID, value) => {
    if (isViewingPastRecord) return; // Prevent editing when viewing past record
    setNewEntry((prevEntry) => ({
      ...prevEntry,
      [fieldID]: value,
    }));
  };

  // Handle Date Filter Change
  const handleDateChange = () => {
    if (startDate && endDate) {
      const filtered = carePlanData.filter((entry) => {
        const entryDate = new Date(entry.createdAt);
        return entryDate >= startDate && entryDate <= endDate;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(carePlanData);
    }
    setCurrentPage(1); // Reset to first page on date change
  };

  // Render Individual Field
  const renderField = (field, index, isArchived = false) => {
    const fieldIDString = field._id.toString();
    const fieldValue = newEntry[fieldIDString] !== undefined ? newEntry[fieldIDString] : '';

    const isEditable = !isArchived && !isViewingPastRecord;

    // Log field rendering for debugging
    // console.log('Rendering field:', field.label, 'ID:', fieldIDString, 'Value:', fieldValue);

    switch (field.type) {
      case 'header':
        return (
          <Typography
            key={index}
            variant="h5"
            sx={{ marginTop: 2 }}
            aria-label={`Header ${field.label}`}
          >
            {field.label}
          </Typography>
        );
      case 'bodyText':
        return (
          <Typography
            key={index}
            variant="body1"
            sx={{ marginTop: 1 }}
            aria-label={`Body text ${field.label}`}
          >
            {field.label}
          </Typography>
        );
      case 'text':
      case 'number':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldIDString, e.target.value)}
            fullWidth
            margin="normal"
            type={field.type === 'number' ? 'number' : 'text'}
            aria-label={`${field.label} input`}
            InputProps={{
              readOnly: !isEditable,
            }}
            disabled={!isEditable}
          />
        );
      case 'textarea':
        return (
          <TextField
            key={index}
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldIDString, e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={field.numLines || 4}
            aria-label={`${field.label} textarea`}
            InputProps={{
              readOnly: !isEditable,
            }}
            disabled={!isEditable}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={!!fieldValue}
                onChange={(e) => handleFieldChange(fieldIDString, e.target.checked)}
                aria-label={`${field.label} checkbox`}
                disabled={!isEditable}
              />
            }
            label={field.label}
          />
        );
      case 'date':
        return (
          <TextField
            key={index}
            label={field.label}
            type="date"
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldIDString, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            aria-label={`${field.label} date picker`}
            InputProps={{
              readOnly: !isEditable,
            }}
            disabled={!isEditable}
          />
        );
      case 'time':
        return (
          <TextField
            key={index}
            label={field.label}
            type="time"
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldIDString, e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            aria-label={`${field.label} time picker`}
            InputProps={{
              readOnly: !isEditable,
            }}
            disabled={!isEditable}
          />
        );
      case 'select':
        const selectOptions = field.masterList?.options || [];
        return (
          <FormControl key={index} fullWidth margin="normal" disabled={!isEditable}>
            <InputLabel>{field.label}</InputLabel>
            <Select
              value={fieldValue}
              onChange={(e) => handleFieldChange(fieldIDString, e.target.value)}
              aria-label={`${field.label} select`}
              disabled={!isEditable}
            >
              {selectOptions.length > 0 ? (
                selectOptions.map((option, idx) => (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No options available
                </MenuItem>
              )}
            </Select>
          </FormControl>
        );
      case 'radio':
        const radioOptions = field.masterList?.options || [];
        return (
          <FormControl
            key={index}
            component="fieldset"
            margin="normal"
            aria-label={`${field.label} radio group`}
            disabled={!isEditable}
          >
            <FormLabel component="legend">{field.label}</FormLabel>
            <RadioGroup
              value={fieldValue}
              onChange={(e) => handleFieldChange(fieldIDString, e.target.value)}
            >
              {radioOptions.length > 0 ? (
                radioOptions.map((option, idx) => (
                  <FormControlLabel
                    key={idx}
                    value={option.value}
                    control={<Radio disabled={!isEditable} />}
                    label={option.label}
                  />
                ))
              ) : (
                <Typography>No options available</Typography>
              )}
            </RadioGroup>
          </FormControl>
        );
      case 'slider':
        return (
          <Box key={index} sx={{ marginBottom: 2 }} aria-label={`${field.label} slider`}>
            <Typography gutterBottom>{field.label}</Typography>
            <MuiSlider
              value={fieldValue || 0}
              onChange={(e, newValue) => handleFieldChange(fieldIDString, newValue)}
              aria-labelledby="continuous-slider"
              disabled={!isEditable}
            />
          </Box>
        );
      case 'nested':
        const customTable = field.customTable;
        return (
          <Box key={index}>
            <Typography variant="h6" aria-label={`Nested ${field.label}`}>
              {field.label}
            </Typography>
            {customTable && customTable.fields.length > 0 ? (
              customTable.fields.map((nestedField, idx) => (
                <Box key={idx} sx={{ marginBottom: '8px' }}>
                  {renderNestedField(nestedField, fieldIDString)}
                </Box>
              ))
            ) : (
              <Typography>No fields available in custom table.</Typography>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  // Render Nested Field
  const renderNestedField = (field, parentFieldID) => {
    const fieldIDString = field._id.toString();
    const nestedFieldKey = `${parentFieldID}.${fieldIDString}`;
    const fieldValue = newEntry[nestedFieldKey] !== undefined ? newEntry[nestedFieldKey] : '';

    const isEditable = !isViewingPastRecord;

    switch (field.type) {
      case 'text':
      case 'number':
        return (
          <TextField
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleNestedFieldChange(nestedFieldKey, e.target.value)}
            fullWidth
            margin="normal"
            type={field.type === 'number' ? 'number' : 'text'}
            aria-label={`Nested ${field.label} input`}
            InputProps={{
              readOnly: !isEditable,
            }}
            disabled={!isEditable}
          />
        );
      case 'textarea':
        return (
          <TextField
            label={field.label}
            value={fieldValue}
            onChange={(e) => handleNestedFieldChange(nestedFieldKey, e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={field.numLines || 4}
            aria-label={`Nested ${field.label} textarea`}
            InputProps={{
              readOnly: !isEditable,
            }}
            disabled={!isEditable}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!fieldValue}
                onChange={(e) => handleNestedFieldChange(nestedFieldKey, e.target.checked)}
                aria-label={`Nested ${field.label} checkbox`}
                disabled={!isEditable}
              />
            }
            label={field.label}
          />
        );
      // Add more nested field types as needed
      default:
        return null;
    }
  };

  // Handle Nested Field Change
  const handleNestedFieldChange = (fieldKey, value) => {
    if (isViewingPastRecord) return; // Prevent editing when viewing past record
    setNewEntry((prevEntry) => ({
      ...prevEntry,
      [fieldKey]: value,
    }));
  };

  // Render Timeline with Pagination
  const renderTimeline = () => {
    if (!Array.isArray(filteredData) || filteredData.length === 0) {
      return <Typography aria-label="No previous records found">No previous records found.</Typography>;
    }

    // Calculate Pagination
    const totalPages = Math.ceil(filteredData.length / recordsPerPage);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

    return (
      <Box
        sx={{
          maxHeight: '600px',
          overflowY: 'auto',
          marginTop: 2,
          position: 'relative',
          paddingRight: '16px',
        }}
      >
        {/* Timeline Separator */}
        <Box
          sx={{
            position: 'absolute',
            right: 'calc(100% - 14px)',
            top: '0px',
            bottom: '20px',
            width: '4px',
            backgroundColor: '#258EA6',
            borderRadius: '2px',
          }}
          aria-label="Timeline separator"
        />
        {/* Timeline Records */}
        {currentRecords.map((entry) => {
          const entryDate = new Date(entry.createdAt);
          const dayOfWeek = entryDate.toLocaleDateString('en-US', { weekday: 'long' });

          return (
            <Box
              key={entry.carePlanID} // Use carePlanID as key
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 3,
                padding: '8px 16px',
                backgroundColor: '#f9f9f9',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                position: 'relative',
                '&:hover': { backgroundColor: '#e0f7fa' },
                transition: 'background-color 0.3s ease',
                minWidth: '250px',
                maxWidth: '300px',
              }}
              onClick={() => handleLoadEntry(entry)}
              aria-label={`Previous record from ${entryDate.toLocaleString()}`}
            >
              <Box sx={{ marginRight: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#153243' }}>
                  {entry.createdAt ? entryDate.toLocaleString() : 'No Date'}
                </Typography>
                <Typography variant="body2" sx={{ color: '#666666' }}>
                  {dayOfWeek}
                </Typography>
                <Typography variant="body2" sx={{ color: '#666666' }}>
                  {entry.updatedAt ? new Date(entry.updatedAt).toLocaleString() : 'No Date'}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  backgroundColor: '#258EA6',
                  position: 'absolute',
                  right: '-22px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  border: '2px solid #FFFFFF',
                }}
              />
            </Box>
          );
        })}

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            sx={{ marginTop: 2 }}
            aria-label="Pagination controls"
          />
        )}
      </Box>
    );
  };

  // Load Entry Data into Form
  const handleLoadEntry = async (entry) => {
    setIsViewingPastRecord(true);
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get(
        `${API_BASE_URL}/api/careplandata/byCarePlanID/${entry.carePlanID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const entryData = response.data.data.reduce((acc, field) => {
        acc[field.fieldID.toString()] = field.value;
        return acc;
      }, {});

      // Log loaded entry data for debugging
      console.log('Loaded Entry Data:', entryData);

      setNewEntry(entryData);
      setCreatedAt(response.data.createdAt);
      setLastUpdate(response.data.updatedAt);
      setVersion(response.data.version || '1.0');
    } catch (error) {
      console.error('Error loading entry:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to load the selected record. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
    }
  };

  // Handle Back Button Click
  const handleBackClick = () => {
    if (!isViewingPastRecord) {
      // If not viewing a past record, check for unsaved changes
      if (JSON.stringify(newEntry) !== '{}') {
        Swal.fire({
          title: 'Unsaved Changes',
          text: 'You have unsaved changes. Would you like to save them before leaving?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Discard',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
          },
          ...customAlertStyles.sweetAlert,
        }).then((result) => {
          if (result.isConfirmed) {
            handleSave();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate(-1);
          }
        });
      } else {
        navigate(-1);
      }
    } else {
      // If viewing a past record, reset to new entry form
      setIsViewingPastRecord(false);
      setNewEntry({});
      setCreatedAt(null);
      setLastUpdate(null);
      setVersion('1.0');
    }
  };

  // Determine if any archived fields have data in the current entry
  const archivedFieldsWithData = archivedFields.filter(
    (field) => newEntry[field._id.toString()] !== undefined && newEntry[field._id.toString()] !== ''
  );

  // Conditional Rendering for Loading and Template Validation
  if (loading) {
    return (
      <Typography aria-label="Loading message" sx={{ textAlign: 'center', marginTop: '20px' }}>
        Loading...
      </Typography>
    );
  }

  if (!carePlanTemplate || !Array.isArray(carePlanTemplate.fields)) {
    return <Typography aria-label="No care plan template found">No care plan template found.</Typography>;
  }

  return (
    <Container
      maxWidth={false}
      sx={{ paddingLeft: 0, paddingRight: 0, display: 'flex', flexDirection: 'column', height: '100vh' }}
    >
      <Box sx={{ flexGrow: 1, padding: 2, display: 'flex', flexDirection: 'column' }}>
        {/* Header Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <IconButton
            onClick={handleBackClick}
            sx={{ marginRight: '10px', color: '#153243' }}
            aria-label="Back button"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h4"
            sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#153243' }}
            aria-label="Care Plan Template Description"
          >
            {carePlanTemplate.description}
          </Typography>
        </Box>

        {/* Entry Metadata */}
        {createdAt && (
          <Typography variant="body1" aria-label="Creation date">
            Created: {new Date(createdAt).toLocaleString()}
          </Typography>
        )}
        {lastUpdate && (
          <>
            <Typography variant="body1" aria-label="Last update">
              Last Update: {new Date(lastUpdate).toLocaleString()}
            </Typography>
            <Typography variant="body1" aria-label="Version">
              Version: {version}
            </Typography>
          </>
        )}

        {/* Main Grid Layout */}
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {/* Entry Form */}
          <Grid item xs={12} md={8}>
            <Box>
              <Typography variant="h6" aria-label="Entry section">
                {isViewingPastRecord ? 'Viewing Past Record' : 'New Entry'}
              </Typography>
              {/* Render Active Fields */}
              {activeFields.map((field, index) =>
                renderField(field, index)
              )}
              {/* Show Archived Fields if any data present in a past record */}
              {isViewingPastRecord && archivedFieldsWithData.length > 0 && (
                <Box sx={{ marginTop: 4 }}>
                  <Typography variant="h5" sx={{ marginBottom: 2 }} aria-label="Archived Fields Heading">
                    Archived Fields
                  </Typography>
                  {archivedFieldsWithData.map((field, index) =>
                    renderField(field, index, true)
                  )}
                </Box>
              )}
            </Box>

            {/* Save Button (Only for New Entries) */}
            {!isViewingPastRecord && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#258EA6',
                  color: '#FFFFFF',
                  padding: '16px',
                  borderRadius: '8px',
                  marginTop: '20px',
                  width: '100%',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    backgroundColor: '#549F93',
                  },
                  fontFamily: 'Roboto, sans-serif',
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
                onClick={handleSave}
                disabled={saving}
                aria-label="Save New Entry button"
              >
                {saving ? 'Saving...' : 'Save New Entry'}
              </Button>
            )}
          </Grid>

          {/* Previous Records */}
          <Grid item xs={12} md={4} sx={{ paddingTop: 0 }}>
            <Box sx={{ marginTop: 0 }}>
              <Typography variant="h6" aria-label="Previous Records section">
                Previous Records
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start date"
                  value={startDate}
                  onChange={(newDate) => {
                    setStartDate(newDate);
                    handleDateChange();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      aria-label="Start date picker"
                    />
                  )}
                />
                <DatePicker
                  label="End date"
                  value={endDate}
                  onChange={(newDate) => {
                    setEndDate(newDate);
                    handleDateChange();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      aria-label="End date picker"
                    />
                  )}
                />
              </LocalizationProvider>
              {renderTimeline()}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

// Wrap Component with ErrorBoundary
const WrappedDynamicCarePlan = (props) => (
  <ErrorBoundary>
    <DynamicCarePlan {...props} />
  </ErrorBoundary>
);

export default WrappedDynamicCarePlan;