// src/screens/MasterListManagementScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  TableSortLabel,
  TablePagination,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
  Grid
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getUserToken } from '../utils/api'; // Assuming a utility function for getting user token
import customAlertStyles from '../styles/CustomAlertStyles';
import NavigationBar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

// Import icons directly
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { purple, red, green } from '@mui/material/colors';

const MasterListManagementScreen = () => {
  const navigate = useNavigate();
  const { permissions = [] } = useAuth(); // Providing default value for permissions

  const [masterLists, setMasterLists] = useState([]);
  const [name, setName] = useState('');
  const [options, setOptions] = useState([{ label: '', value: '' }]);
  const [openAddMasterList, setOpenAddMasterList] = useState(false);
  const [openEditOptions, setOpenEditOptions] = useState(false);
  const [selectedMasterList, setSelectedMasterList] = useState(null);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState('All');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // Memoize hasPermission to prevent unnecessary re-creations
  const hasPermission = useCallback(
    (action) => {
      return permissions.includes(action);
    },
    [permissions]
  );

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Fetch master lists from the API
  const fetchMasterLists = useCallback(async () => {
    if (!hasPermission('view_master-list-management')) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view master lists.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/home');
      return;
    }

    try {
      const token = getUserToken();
      const response = await axios.get(`${API_BASE_URL}/api/masterlists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (Array.isArray(response.data)) {
        setMasterLists(response.data);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Unexpected response structure from the server.',
          ...customAlertStyles.sweetAlert,
        });
        console.error('Unexpected response structure:', response.data);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch master lists.',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error fetching master lists:', error);
    } finally {
      setLoading(false);
    }
  }, [hasPermission, navigate, API_BASE_URL]);

  useEffect(() => {
    fetchMasterLists();
  }, [fetchMasterLists]);

  // Handle adding a new master list
  const handleAddMasterList = async () => {
    if (!hasPermission('add_master-list-management')) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to add a master list.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!name.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Name is required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (options.length === 0) {
      Swal.fire({
        title: 'Error',
        text: 'At least one option is required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (options.some(option => !option.label.trim() || !option.value.trim())) {
      Swal.fire({
        title: 'Error',
        text: 'All options must have a label and a value.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const masterListData = { name, options };

    setSaving(true);

    try {
      const token = getUserToken();
      const response = await axios.post(`${API_BASE_URL}/api/masterlists`, masterListData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      const newMasterList = response.data.masterList || response.data;

      if (newMasterList && newMasterList.name && newMasterList._id) {
        setMasterLists(prevMasterLists => [...prevMasterLists, newMasterList]);
        setName('');
        setOptions([{ label: '', value: '' }]);
        setOpenAddMasterList(false);
        Swal.fire({
          title: 'Success',
          text: 'Master list added successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add master list. Invalid response from server.',
          ...customAlertStyles.sweetAlert,
        });
        console.error('Invalid master list object:', newMasterList);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.error || 'Failed to add master list.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error adding master list:', error.response?.data);
    } finally {
      setSaving(false);
    }
  };

  // Handle editing and saving options of a master list
  const handleSaveOptions = async () => {
    if (!hasPermission('edit_master-list-management')) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to edit this master list.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (!name.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Name is required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (options.length === 0) {
      Swal.fire({
        title: 'Error',
        text: 'At least one option is required.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    if (options.some(option => !option.label.trim() || !option.value.trim())) {
      Swal.fire({
        title: 'Error',
        text: 'All options must have a label and a value.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const masterListData = { name, options };

    setSaving(true);

    try {
      const token = getUserToken();
      const response = await axios.put(`${API_BASE_URL}/api/masterlists/${selectedMasterList._id}`, masterListData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      const updatedMasterList = response.data.masterList || response.data;

      if (updatedMasterList && updatedMasterList.name && updatedMasterList._id) {
        setMasterLists(prevMasterLists =>
          prevMasterLists.map(ml => ml._id === selectedMasterList._id ? updatedMasterList : ml)
        );
        setSelectedMasterList(null);
        setName('');
        setOptions([{ label: '', value: '' }]);
        setOpenEditOptions(false);
        Swal.fire({
          title: 'Success',
          text: 'Master list updated successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update master list. Invalid response from server.',
          ...customAlertStyles.sweetAlert,
        });
        console.error('Invalid master list object:', updatedMasterList);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.error || 'Failed to update master list.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      console.error('Error updating master list:', error.response?.data);
    } finally {
      setSaving(false);
    }
  };

  // Handle archiving/unarchiving a master list
  const handleArchiveMasterList = async (masterList) => {
    if (!hasPermission('delete_master-list-management')) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to archive this master list.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    const action = masterList.isArchived ? 'unarchive' : 'archive';
    const actionLabel = masterList.isArchived ? 'Unarchive' : 'Archive';

    Swal.fire({
      title: `${actionLabel} ${masterList.name}?`,
      text: `Are you sure you want to ${action.toLowerCase()} this master list?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: masterList.isArchived ? green[500] : red[500],
      cancelButtonColor: '#3085d6',
      confirmButtonText: `Yes, ${action.toLowerCase()} it!`,
      ...customAlertStyles.sweetAlert,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = getUserToken();
          const url = `${API_BASE_URL}/api/masterlists/${action}/${masterList._id}`;
          const response = await axios.put(url, {}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const updatedMasterList = response.data.masterList || response.data;

          if (updatedMasterList && updatedMasterList.name && updatedMasterList._id) {
            setMasterLists(prevMasterLists =>
              prevMasterLists.map(ml => ml._id === masterList._id ? updatedMasterList : ml)
            );
            Swal.fire({
              title: actionLabel,
              text: `Master list has been ${actionLabel.toLowerCase()}d successfully.`,
              icon: 'success',
              ...customAlertStyles.sweetAlert,
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: `Failed to ${action} master list. Invalid response from server.`,
              icon: 'error',
              ...customAlertStyles.sweetAlert,
            });
            console.error('Invalid master list object:', updatedMasterList);
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: error.response?.data?.error || `Failed to ${action} master list.`,
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
          console.error(`Error ${action}ing master list:`, error.response?.data);
        }
      }
    });
  };

  // Handle deleting a master list
  const handleDeleteMasterList = async (masterList) => {
    if (!hasPermission('delete_master-list-management')) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to delete this master list.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    Swal.fire({
      title: `Delete ${masterList.name}?`,
      text: `Are you sure you want to permanently delete this master list? This action cannot be undone.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: red[500],
      cancelButtonColor: '#3085d6',
      confirmButtonText: `Yes, delete it!`,
      ...customAlertStyles.sweetAlert,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = getUserToken();
          const url = `${API_BASE_URL}/api/masterlists/${masterList._id}`;
          await axios.delete(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setMasterLists(prevMasterLists =>
            prevMasterLists.filter(ml => ml._id !== masterList._id)
          );

          Swal.fire({
            title: 'Deleted',
            text: `Master list has been deleted successfully.`,
            icon: 'success',
            ...customAlertStyles.sweetAlert,
          });
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: error.response?.data?.error || `Failed to delete master list.`,
            icon: 'error',
            ...customAlertStyles.sweetAlert,
          });
          console.error(`Error deleting master list:`, error.response?.data);
        }
      }
    });
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  // Handle sorting
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter master lists based on search and status
  const filteredMasterLists = masterLists.filter(masterList => {
    if (!masterList || !masterList.name) return false;

    const matchesSearch = masterList.name.toLowerCase().includes(search.toLowerCase());
    const matchesStatus =
      statusFilter === 'All' ||
      (statusFilter === 'Active' && !masterList.isArchived) ||
      (statusFilter === 'Archived' && masterList.isArchived);
    return matchesSearch && matchesStatus;
  });

  // Comparator functions for sorting
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (!a[orderBy] || !b[orderBy]) return 0;
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) return -1;
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) return 1;
    return 0;
  };

  const stableSort = (array, comparator) => {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
      const orderComp = comparator(a[0], b[0]);
      if (orderComp !== 0) return orderComp;
      return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
  };

  const sortedMasterLists = stableSort(filteredMasterLists, getComparator(order, orderBy));

  const paginatedMasterLists = sortedMasterLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Handle opening the add master list dialog
  const handleOpenAddMasterList = () => {
    setName('');
    setOptions([{ label: '', value: '' }]);
    setOpenAddMasterList(true);
  };

  // Handle opening the edit options dialog
  const handleOpenEditOptions = (masterList) => {
    if (!hasPermission('edit_master-list-management')) {
      Swal.fire({
        title: 'Permission Denied',
        text: 'You do not have permission to edit this master list.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }
    setSelectedMasterList(masterList);
    setName(masterList.name);
    setOptions(masterList.options);
    setOpenEditOptions(true);
  };

  return (
    <>
      <NavigationBar />
      <Box display="flex">
        <Sidebar />
        <Box flexGrow={1}>
          <Box sx={{ padding: '24px', marginTop: '64px' }}>
            <Typography component="h1" variant="h5" sx={{ marginBottom: '24px' }}>
              Master List Management
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', gap: 2 }}>
              <TextField
                variant="outlined"
                placeholder="Search Master Lists"
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="Search Master Lists Button">
                      <SearchIcon />
                    </IconButton>
                  )
                }}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenAddMasterList}
                disabled={!hasPermission('add_master-list-management')}
                startIcon={<AddCircleIcon />}
              >
                Add Master List
              </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: '16px', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={statusFilter}
                  label="Status"
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Archived">Archived</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="master lists table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'name'}
                          direction={orderBy === 'name' ? order : 'asc'}
                          onClick={() => handleSortRequest('name')}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Options</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedMasterLists.map((masterList) => (
                      <TableRow key={masterList._id} hover>
                        <TableCell>{masterList.name}</TableCell>
                        <TableCell>
                          {masterList.options.slice(0, 3).map(option => option.label).join(', ')}
                          {masterList.options.length > 3 && '...'}
                        </TableCell>
                        <TableCell>
                          {masterList.isArchived ? (
                            <Chip label="Archived" color="error" />
                          ) : (
                            <Chip label="Active" color="success" />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            sx={{ color: purple[500] }}
                            onClick={() => handleOpenEditOptions(masterList)}
                            aria-label={`View/Edit ${masterList.name}`}
                            disabled={!hasPermission('edit_master-list-management')}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            sx={{ color: masterList.isArchived ? green[500] : red[500] }}
                            onClick={() => handleArchiveMasterList(masterList)}
                            aria-label={`${masterList.isArchived ? 'Unarchive' : 'Archive'} ${masterList.name}`}
                            disabled={!hasPermission('delete_master-list-management')}
                          >
                            {masterList.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                          </IconButton>
                          <IconButton
                            sx={{ color: red[500] }}
                            onClick={() => handleDeleteMasterList(masterList)}
                            aria-label={`Delete ${masterList.name}`}
                            disabled={!hasPermission('delete_master-list-management')}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {paginatedMasterLists.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No master lists found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={sortedMasterLists.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            )}

            {/* Add/Edit Master List Dialog */}
            <Dialog
              open={openAddMasterList || openEditOptions}
              onClose={() => {
                setOpenAddMasterList(false);
                setOpenEditOptions(false);
              }}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>{openAddMasterList ? 'Add New Master List' : 'Edit Master List'}</DialogTitle>
              <DialogContent dividers>
                <Box sx={{ padding: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={!name.trim()}
                        helperText={!name.trim() ? 'Name is required' : ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Options
                      </Typography>
                    </Grid>
                    {options.map((option, index) => (
                      <Grid container spacing={1} key={index} alignItems="center">
                        <Grid item xs={12} sm={5}>
                          <TextField
                            required
                            fullWidth
                            label="Label"
                            value={option.label}
                            onChange={(e) => {
                              const newOptions = [...options];
                              newOptions[index].label = e.target.value;
                              setOptions(newOptions);
                            }}
                            error={!option.label.trim()}
                            helperText={!option.label.trim() ? 'Label is required' : ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <TextField
                            required
                            fullWidth
                            label="Value"
                            value={option.value}
                            onChange={(e) => {
                              const newOptions = [...options];
                              newOptions[index].value = e.target.value;
                              setOptions(newOptions);
                            }}
                            error={!option.value.trim()}
                            helperText={!option.value.trim() ? 'Value is required' : ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <IconButton
                            color="error"
                            onClick={() => {
                              const newOptions = options.filter((_option, idx) => idx !== index);
                              setOptions(newOptions);
                            }}
                            aria-label={`Remove Option ${index + 1}`}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                        onClick={() => setOptions([...options, { label: '', value: '' }])}
                      >
                        Add Another Option
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {
                  setOpenAddMasterList(false);
                  setOpenEditOptions(false);
                }}>
                  Cancel
                </Button>
                <Button
                  onClick={openAddMasterList ? handleAddMasterList : handleSaveOptions}
                  disabled={
                    !name.trim() ||
                    options.length === 0 ||
                    options.some(option => !option.label.trim() || !option.value.trim()) ||
                    saving
                  }
                  variant="contained"
                  color="primary"
                  startIcon={saving && (openAddMasterList ? <AddCircleIcon /> : <EditIcon />)}
                >
                  {saving ? 'Saving...' : (openAddMasterList ? 'Add Master List' : 'Save Changes')}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedMasterListManagementScreen = () => (
  <ErrorBoundary>
    <MasterListManagementScreen />
  </ErrorBoundary>
);

export default WrappedMasterListManagementScreen;