// src/screens/UserProfileScreen.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  IconButton,
  Collapse,
  Button,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getUserToken } from '../utils/api';
import { styles } from '../styles/styles';
import customAlertStyles from '../styles/CustomAlertStyles';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';

// Import icons directly
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SaveIcon from '@mui/icons-material/Save';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const UserProfileScreen = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { permissions = [], loading: authLoading } = useAuth(); // Provide default empty array

  // User state
  const [user, setUser] = useState({
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    profilePicture: '',
    jobTitle: '',
    employmentType: '',
    emergencyContacts: [{ firstName: '', lastName: '', relationship: '', phoneNumber: '' }]
  });

  // UI State
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  // Use environment variable for API base URL
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // Permissions state
  const hasViewPermission = permissions.includes('view_user-profile');
  const hasEditPermission = permissions.includes('edit_user-profile');

  // Fetch user data
  const fetchUserData = useCallback(async () => {
    if (!hasViewPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to view this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      navigate('/home'); // Redirect to home
      return;
    }

    try {
      const token = getUserToken();
      const response = await axios.get(`${API_BASE_URL}/api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data) {
        setUser(response.data);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch user data.',
          icon: 'error',
          ...customAlertStyles.sweetAlert
        });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while fetching user data.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    } finally {
      setLoading(false);
    }
  }, [hasViewPermission, navigate, userId, API_BASE_URL]);

  // Fetch user data on component mount
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Handle input change with functional updates
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({ ...prevState, [name]: value }));
  };

  // Save user data
  const handleSave = async () => {
    if (!hasEditPermission) {
      Swal.fire({
        title: 'Access Denied',
        text: 'You do not have permission to edit this profile.',
        icon: 'error',
        ...customAlertStyles.sweetAlert,
      });
      return;
    }

    setSaving(true);
    try {
      const token = getUserToken();
      const { _id, ...userData } = user;

      const response = await axios.put(`${API_BASE_URL}/api/users/${_id}`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data) {
        setUser(response.data);
        setEditMode(false);
        Swal.fire({
          title: 'Success',
          text: 'Profile updated successfully.',
          icon: 'success',
          ...customAlertStyles.sweetAlert
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Failed to update user data.',
          icon: 'error',
          ...customAlertStyles.sweetAlert
        });
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      Swal.fire({
        title: 'Error',
        text: 'An unknown error occurred. Please try again later.',
        icon: 'error',
        ...customAlertStyles.sweetAlert
      });
    } finally {
      setSaving(false);
    }
  };

  // Handle profile image change
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle emergency contact change with functional updates
  const handleEmergencyContactChange = (index, field, value) => {
    setUser((prevUser) => {
      const updatedContacts = prevUser.emergencyContacts.map((contact, idx) =>
        idx === index ? { ...contact, [field]: value } : contact
      );
      return { ...prevUser, emergencyContacts: updatedContacts };
    });
  };

  // Render loading state
  if (loading || authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Debugging: Log permissions
  console.log('Permissions:', permissions);

  return (
    <>
      <Navbar />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box flexGrow={1} sx={{ padding: '24px', marginTop: '64px' }}>
          <Box sx={{
            marginBottom: 2,
            border: '1px solid #549F93',
            padding: '8px',
            borderRadius: '4px',
            background: 'linear-gradient(to right, #258EA6, #549F93)',
            position: 'relative'
          }}>
            <Avatar
              alt={`${user.firstName} ${user.lastName}`}
              src={previewImage || user.profilePicture || undefined}
              sx={{
                width: 160,
                height: 160,
                position: 'absolute',
                top: '50%',
                right: '16px',
                transform: 'translateY(-50%)',
                border: '4px solid #FFFFFF',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                fontSize: '36px',
                backgroundColor: previewImage || user.profilePicture ? 'transparent' : '#BDBDBD',
                color: '#FFFFFF',
              }}
            >
              {!user.profilePicture && !previewImage && `${user.firstName[0] || ''}${user.lastName[0] || ''}`}
            </Avatar>
            <Box sx={{ marginRight: '180px' }}>
              <Typography variant="h6" sx={{ color: '#FFFFFF' }}>
                {user.firstName} {user.lastName}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }}>
                Phone: {user.phoneNumber || 'N/A'}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: '#EAEAEA' }}>
                Address: {user.address || 'N/A'}
              </Typography>
            </Box>

            {/* Edit and Save buttons, visible only with edit_user-profile permission */}
            {hasEditPermission && (
              <>
                <IconButton onClick={() => setEditMode(true)} sx={{ color: '#FFFFFF' }}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={handleSave} sx={{ color: '#FFFFFF' }} disabled={saving}>
                  {saving ? <CircularProgress size={24} /> : <SaveIcon />}
                </IconButton>
              </>
            )}

            <IconButton onClick={() => setExpanded(!expanded)} sx={{ color: '#FFFFFF' }}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          <Collapse in={expanded}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="Profile Tabs">
              <Tab label="Personal Information" />
              <Tab label="Job Information" />
              <Tab label="Emergency Contacts" />
              <Tab label="Profile Picture" />
            </Tabs>
            <Box sx={{ marginTop: 2 }}>
              {activeTab === 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="First Name"
                      name="firstName"
                      value={user.firstName || ''}
                      onChange={handleInputChange}
                      fullWidth
                      sx={styles.input}
                      aria-label="First Name"
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={user.lastName || ''}
                      onChange={handleInputChange}
                      fullWidth
                      sx={styles.input}
                      aria-label="Last Name"
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Email"
                      name="email"
                      value={user.email || ''}
                      onChange={handleInputChange}
                      fullWidth
                      sx={styles.input}
                      aria-label="Email"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      value={user.phoneNumber || ''}
                      onChange={handleInputChange}
                      fullWidth
                      sx={styles.input}
                      aria-label="Phone Number"
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Address"
                      name="address"
                      value={user.address || ''}
                      onChange={handleInputChange}
                      fullWidth
                      sx={styles.input}
                      aria-label="Address"
                      disabled={!editMode}
                    />
                  </Grid>
                </Grid>
              )}

              {activeTab === 1 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Job Title"
                      name="jobTitle"
                      value={user.jobTitle || ''}
                      onChange={handleInputChange}
                      fullWidth
                      sx={styles.input}
                      aria-label="Job Title"
                      disabled={!editMode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Employment Type"
                      name="employmentType"
                      value={user.employmentType || ''}
                      onChange={handleInputChange}
                      fullWidth
                      sx={styles.input}
                      aria-label="Employment Type"
                      disabled={!editMode}
                    />
                  </Grid>
                </Grid>
              )}

              {activeTab === 2 && (
                <Box>
                  <Typography component="h3" variant="h6" sx={styles.subHeader} aria-label="Emergency Contacts Header">
                    Emergency Contacts
                  </Typography>
                  {user.emergencyContacts.map((contact, index) => (
                    <React.Fragment key={index}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Contact First Name"
                            name="firstName"
                            value={contact.firstName}
                            onChange={(e) => handleEmergencyContactChange(index, 'firstName', e.target.value)}
                            fullWidth
                            sx={styles.input}
                            aria-label="Contact First Name"
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Contact Last Name"
                            name="lastName"
                            value={contact.lastName}
                            onChange={(e) => handleEmergencyContactChange(index, 'lastName', e.target.value)}
                            fullWidth
                            sx={styles.input}
                            aria-label="Contact Last Name"
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Relationship"
                            name="relationship"
                            value={contact.relationship}
                            onChange={(e) => handleEmergencyContactChange(index, 'relationship', e.target.value)}
                            fullWidth
                            sx={styles.input}
                            aria-label="Relationship"
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Contact Phone Number"
                            name="phoneNumber"
                            value={contact.phoneNumber}
                            onChange={(e) => handleEmergencyContactChange(index, 'phoneNumber', e.target.value)}
                            fullWidth
                            sx={styles.input}
                            aria-label="Contact Phone Number"
                            disabled={!editMode}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Box>
              )}

              {activeTab === 3 && (
                <Box>
                  <Typography component="h3" variant="h6" sx={styles.subHeader} aria-label="Profile Picture Header">
                    Profile Picture
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}>
                    <Avatar
                      alt={`${user.firstName} ${user.lastName}`}
                      src={previewImage || user.profilePicture || '/default-avatar.png'}
                      sx={{ width: 140, height: 140, marginBottom: 2 }}
                    />
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<PhotoCameraIcon />}
                      sx={{ marginBottom: 2 }}
                      disabled={!editMode}
                    >
                      Upload New Picture
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleProfileImageChange}
                      />
                    </Button>
                    {profileImage && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        sx={{ marginBottom: 2 }}
                        disabled={saving}
                      >
                        {saving ? <CircularProgress size={24} /> : 'Save Picture'}
                      </Button>
                    )}
                    {user.profilePicture && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setUser((prevUser) => ({ ...prevUser, profilePicture: '' }))}
                        startIcon={<DeleteIcon />}
                        disabled={!editMode}
                      >
                        Remove Current Picture
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

// Wrap the component with ErrorBoundary
const WrappedUserProfileScreen = () => (
  <ErrorBoundary>
    <UserProfileScreen />
  </ErrorBoundary>
);

export default WrappedUserProfileScreen;