// src/screens/StaticCarePlanScreen.jsx

import React from 'react';
import { Box } from '@mui/material';
import Sidebar from '../components/Sidebar';
import StaticCarePlans from '../components/StaticCarePlans'; // Adjusted import path
import { useParams } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';

const StaticCarePlanScreen = () => {
  const { templateID, clientID } = useParams();

  console.log('CarePlan ID from URL:', templateID); // Log the care plan ID
  console.log('Client ID from URL:', clientID); // Log the client ID

  return (
    <ErrorBoundary>
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box sx={{ flexGrow: 1, padding: 3, marginTop: '64px' }}>
          <StaticCarePlans templateID={templateID} clientID={clientID} />
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default StaticCarePlanScreen;