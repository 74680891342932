// src/context/AuthContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);  // User state
  const [permissions, setPermissions] = useState([]);  // Permissions state
  const [loading, setLoading] = useState(true);  // Loading state for async fetch
  const navigate = useNavigate();

  // Set the API base URL
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  const fetchPermissions = useCallback(async () => {
    try {
      const response = await axios.get('/api/roles/user/permissions');
      setPermissions(response.data.permissions || []);
    } catch (error) {
      console.error('Error fetching permissions:', error);
      setPermissions([]); // Ensure permissions is set to an empty array on error
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get('/api/users/me');  // Assuming this is your /me endpoint
      setUser(response.data);
      
      // Fetch permissions after fetching user data
      fetchPermissions();
    } catch (error) {
      console.error('Error fetching user data:', error);
      logout();  // Log out if there's an error fetching user data
    }
  }, [fetchPermissions]);

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      setIsAuthenticated(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Fetch user data after setting token
      fetchUserData();
    } else {
      setLoading(false);  // No need to fetch user data
    }
  }, [fetchUserData]);

  const login = (token) => {
    localStorage.setItem('userToken', token);
    setIsAuthenticated(true);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    // Fetch user data after login
    fetchUserData();
    navigate('/home'); // Redirect to home page upon successful login
  };

  const logout = () => {
    localStorage.removeItem('userToken');
    setIsAuthenticated(false);
    setUser(null);  // Clear user data on logout
    setPermissions([]);  // Clear permissions on logout
    delete axios.defaults.headers.common['Authorization'];
    navigate('/login'); // Redirect to login page upon logout
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, permissions, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};