// src/screens/DefineScreen.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
  InputLabel,
  Tooltip,
  IconButton,
  Grid,
  Checkbox,
  ListItemText,
  OutlinedInput,
  TextField,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Swal from 'sweetalert2';
import customAlertStyles from '../styles/CustomAlertStyles';
import { useAuth } from '../context/AuthContext';
import ErrorBoundary from '../components/ErrorBoundary';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const DefineScreen = ({ templateDetails, setTemplateDetails }) => {
  const { permissions = [], loading: authLoading } = useAuth();

  // Permission checks
  const hasViewPermission = permissions.includes('view_template-management');

  const [processLabels, setProcessLabels] = useState([]);
  const [selectedProcessLabels, setSelectedProcessLabels] = useState([]);

  // Fetch process labels
  useEffect(() => {
    const fetchProcessLabels = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get(`${API_BASE_URL}/api/processlabels`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProcessLabels(response.data);
      } catch (error) {
        console.error('Error fetching process labels:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch process labels.',
          ...customAlertStyles.sweetAlert,
        });
      }
    };

    if (hasViewPermission && !authLoading) {
      fetchProcessLabels();
    }
  }, [hasViewPermission, authLoading]);

  // Initialize states with values from templateDetails or defaults
  const [updateFrequency, setUpdateFrequency] = useState(
    templateDetails.updateFrequency || 'none'
  );
  const [customFrequency, setCustomFrequency] = useState(
    templateDetails.customFrequency || { interval: '', unit: 'days', specificDays: [] }
  );

  // Set initial values for activePeriod, startDate, and endDate based on templateDetails
  const [activePeriod, setActivePeriod] = useState(
    templateDetails.activePeriod || 'permanent'
  );
  const [startDate, setStartDate] = useState(
    templateDetails.startDate ? new Date(templateDetails.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    templateDetails.endDate ? new Date(templateDetails.endDate) : null
  );

  // Update selectedProcessLabels when templateDetails.assignedProcessLabels changes
  useEffect(() => {
    if (templateDetails.assignedProcessLabels) {
      const processLabelIds = templateDetails.assignedProcessLabels.map((label) =>
        typeof label === 'object' && label !== null ? label._id : label
      );
      // Only update if different to prevent infinite loop
      if (
        selectedProcessLabels.length !== processLabelIds.length ||
        !selectedProcessLabels.every((id, index) => id === processLabelIds[index])
      ) {
        setSelectedProcessLabels(processLabelIds);
      }
    } else {
      if (selectedProcessLabels.length !== 0) {
        setSelectedProcessLabels([]);
      }
    }
  }, [templateDetails.assignedProcessLabels]);

  // Update templateDetails directly in state change handlers

  const handleCustomFrequencyChange = (e) => {
    const { name, value } = e.target;
    setCustomFrequency((prev) => ({ ...prev, [name]: value }));
    setTemplateDetails((prevDetails) => ({
      ...prevDetails,
      customFrequency: { ...prevDetails.customFrequency, [name]: value },
    }));
  };

  const handleProcessLabelChange = (event) => {
    const {
      target: { value },
    } = event;
    const newSelectedProcessLabels = typeof value === 'string' ? value.split(',') : value;
    setSelectedProcessLabels(newSelectedProcessLabels);
    setTemplateDetails((prevDetails) => ({
      ...prevDetails,
      assignedProcessLabels: newSelectedProcessLabels,
    }));
  };

  const handleTemplateTypeChange = (e) => {
    const newTemplateType = e.target.value;
    setTemplateDetails((prevDetails) => ({
      ...prevDetails,
      isStatic: newTemplateType === 'static',
    }));
  };

  const handleUpdateFrequencyChange = (e) => {
    const newUpdateFrequency = e.target.value;
    setUpdateFrequency(newUpdateFrequency);
    setTemplateDetails((prevDetails) => ({
      ...prevDetails,
      updateFrequency: newUpdateFrequency,
    }));
  };

  const handleActivePeriodChange = (e) => {
    const newActivePeriod = e.target.value;
    setActivePeriod(newActivePeriod);
    setTemplateDetails((prevDetails) => ({
      ...prevDetails,
      activePeriod: newActivePeriod,
      startDate: newActivePeriod === 'temporary' ? startDate : null,
      endDate: newActivePeriod === 'temporary' ? endDate : null,
    }));
  };

  const handleStartDateChange = (newDate) => {
    setStartDate(newDate);
    setTemplateDetails((prevDetails) => ({
      ...prevDetails,
      startDate: newDate,
    }));
  };

  const handleEndDateChange = (newDate) => {
    setEndDate(newDate);
    setTemplateDetails((prevDetails) => ({
      ...prevDetails,
      endDate: newDate,
    }));
  };

  if (authLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ padding: '24px' }} aria-label="Define CarePlan Template Screen">
        <Typography
          variant="h5"
          sx={{ marginBottom: '24px' }}
          aria-label="Define CarePlan Template Title"
        >
          Define CarePlan Template
        </Typography>

        {/* Grid container for the entire form */}
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {/* Template Type */}
              <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    Template Type
                    <Tooltip title="Choose whether the template is static or dynamic.">
                      <IconButton aria-label="Help Icon" size="small">
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <RadioGroup
                    row
                    value={templateDetails.isStatic ? 'static' : 'dynamic'}
                    onChange={handleTemplateTypeChange}
                    aria-label="Template Type Options"
                  >
                    <FormControlLabel value="static" control={<Radio />} label="Static" />
                    <FormControlLabel value="dynamic" control={<Radio />} label="Dynamic" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Update Frequency */}
              <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    Update Frequency
                    <Tooltip title="Specify how often the template should be updated.">
                      <IconButton aria-label="Help Icon" size="small">
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <RadioGroup
                    value={updateFrequency}
                    onChange={handleUpdateFrequencyChange}
                    aria-label="Update Frequency Options"
                  >
                    {[
                      'none',
                      'ad-hoc',
                      'daily',
                      'weekly',
                      'monthly',
                      'quarterly',
                      'annually',
                      'custom',
                    ].map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={option.charAt(0).toUpperCase() + option.slice(1)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Custom Frequency */}
              {updateFrequency === 'custom' && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Interval"
                        name="interval"
                        type="number"
                        value={customFrequency.interval}
                        onChange={handleCustomFrequencyChange}
                        fullWidth
                        aria-label="Custom Frequency Interval Input"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="unit-label">Unit</InputLabel>
                        <Select
                          labelId="unit-label"
                          name="unit"
                          value={customFrequency.unit}
                          onChange={handleCustomFrequencyChange}
                          label="Unit"
                          aria-label="Custom Frequency Unit Select"
                        >
                          <MenuItem value="days">Days</MenuItem>
                          <MenuItem value="weeks">Weeks</MenuItem>
                          <MenuItem value="months">Months</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {(customFrequency.unit === 'weeks' || customFrequency.unit === 'months') && (
                      <Grid item xs={12}>
                        <TextField
                          label="Specific Days (comma-separated)"
                          name="specificDays"
                          value={customFrequency.specificDays.join(',')}
                          onChange={(e) => {
                            const newSpecificDays = e.target.value
                              .split(',')
                              .map((day) => day.trim())
                              .filter((day) => day !== '');
                            handleCustomFrequencyChange({
                              target: { name: 'specificDays', value: newSpecificDays },
                            });
                          }}
                          fullWidth
                          aria-label="Specific Days Input"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {/* Active Period */}
              <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    Active Period
                    <Tooltip title="Specify whether the template is permanent or temporary.">
                      <IconButton aria-label="Help Icon" size="small">
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <RadioGroup
                    row
                    value={activePeriod}
                    onChange={handleActivePeriodChange}
                    aria-label="Active Period Options"
                  >
                    <FormControlLabel value="permanent" control={<Radio />} label="Permanent" />
                    <FormControlLabel value="temporary" control={<Radio />} label="Temporary" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Date Pickers */}
              {activePeriod === 'temporary' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                      aria-label="Start Date Picker"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                      aria-label="End Date Picker"
                    />
                  </Grid>
                </>
              )}

              {/* Process Labels */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="process-labels-label">Process</InputLabel>
                  <Select
                    labelId="process-labels-label"
                    multiple
                    value={selectedProcessLabels}
                    onChange={handleProcessLabelChange}
                    input={<OutlinedInput label="Process" />}
                    renderValue={(selected) =>
                      processLabels
                        .filter((label) => selected.includes(label._id))
                        .map((label) => label.name)
                        .join(', ')
                    }
                    aria-label="Select Process Labels"
                  >
                    {processLabels.map((label) => (
                      <MenuItem key={label._id} value={label._id}>
                        <Checkbox checked={selectedProcessLabels.includes(label._id)} />
                        <ListItemText primary={label.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

// Wrap the component with ErrorBoundary
const WrappedDefineScreen = (props) => (
  <ErrorBoundary>
    <DefineScreen {...props} />
  </ErrorBoundary>
);

export default WrappedDefineScreen;